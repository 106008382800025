
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function PendingCoaching() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/boost/coaching-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                order_status: ORDER_STATUS.PENDING
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Order not found.',
            }).then(() => {
                navigate('/boost-panel/coaching/pending');
                setLoading(false);
            });
        });
    }, [order_id]);

    function acceptOrder() {
        MySwal.fire({
            title: 'Do you want to accept this order?',
            showCancelButton: true,
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/boost/accept-coaching-order', { order_id: order.order_id }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Order Accepted!',
                        text: 'Good luck.',
                    }).then(() => {
                        navigate('/boost-panel/coaching/active/' + order_id);
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.error??'Make sure you only have two active orders at a time.',
                    })
                    console.error(error);
                });
            }
        })
    }

    if(!order) return(
        <>
            <div className='pending-order coaching'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='pending-order coaching'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Coaching</h1>
                        <div className='container'>
                            <div className='divider'>
                                <div className='rank-card'>
                                    <h2>Coach Rank</h2>
                                    <img id='emblem' src={require(`../../../../assets/Emblems/${order.coach_rank}_Emblem.png`)}/>
                                    <h3>{order.coach_rank}</h3>
                                </div>
                                <div className='rank-card'>
                                    <h2>Users Rank</h2>
                                    <img id='emblem' src={require(`../../../../assets/Emblems/${order.users_rank}_Emblem.png`)}/>
                                    <h3>{order.users_rank}</h3>
                                </div>
                            </div>
                            <div className='divider'>
                                <div className='container'>
                                    <h2>Region</h2>
                                    <h3>{order.server}</h3>
                                </div>
                                <div className='container'>
                                    <h2>Language</h2>
                                    <h3>{order.language}</h3>
                                </div>
                            </div>
                        </div>
                        <button id='pending-accept' onClick={() => acceptOrder()}>Accept</button>
                    </div>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>Earnings</h2>
                            <h3>${order.earnings.toFixed(2)} USD</h3>
                        </div>
                        <div className='card'>
                            <h2>Co-Pilot Hours</h2>
                            <h3>{order.copilot_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Vod-Review Hours</h2>
                            <h3>{order.vod_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>1v1 Hours</h2>
                            <h3>{order.battle_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Roles</h2>
                            <Roles elements={order.roles}/>
                        </div>
                        <div className='card'>
                            <h2>Champions</h2>
                            <Champions elements={order.champions}/>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}