
import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { useAuth } from '../../../../../contexts/AuthContext';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


export default function AccountInformation({order_id, setLoading}) {

    const { getAccessToken } = useAuth();

    const MySwal = withReactContent(Swal);
    

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/account-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            }
        }).then(response => {
            MySwal.fire({
                title: 'Account Information',
                icon: 'info',
                html: `
                <div style="text-align: center;">
                    <table style="margin: 0 auto;">
                        <tr style="text-align: left">
                            <td style="padding-right: 10px;"><b>IGN</b></td>
                            <td>${response.data.ign??'Not Provided'}</td>
                        </tr>
                        <tr style="text-align: left">
                            <td style="padding-right: 10px;"><b>#TAG</b></td>
                            <td>${response.data.tag??'Not Provided'}</td>
                        </tr>
                        <tr style="text-align: left">
                            <td style="padding-right: 10px;"><b>Server</b></td>
                            <td>${response.data.server??'Not Provided'}</td>
                        </tr>
                    </table>
                </div>
                `,
                confirmButtonText: 'Got it!'
            });
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, []);
}