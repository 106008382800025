
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import Popup from 'reactjs-popup';
import Slider from '@mui/material/Slider';

import CoachSelector from './Components/CoachSelector.js';
import ChangeStatus from './Components/ChangeStatus.js';
import CurrentPrice from './Components/CurrentPrice.js';

export default function PendingCoaching() {

    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = useState(null);
    
    const [update, setUpdate] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [coach, setCoach] = useState(false);
    const [status, setStatus] = useState(false);

    const [copilotHours, setCopilotHours] = React.useState(0);
    const [vodHours, setVodHours] = React.useState(0);
    const [battleHours, setBattleHours] = React.useState(0);

    const { boost_type: order_id } = useParams();
    const { getAccessToken } = useAuth();

    const navigate = useNavigate();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/coaching-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                order_status: ORDER_STATUS.PENDING
            }
        }).then(response => {
            let order = response.data.userData;
            setOrder(order);
            setCopilotHours(order?.current_copilot_hours??0);
            setVodHours(order?.current_vod_hours??0);
            setBattleHours(order?.current_battle_hours??0);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.current_copilot_hours === copilotHours && order.current_vod_hours === vodHours && order.current_battle_hours === battleHours) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-coaching-progress', { 
            order_id: order.order_id,
            current_copilot_hours: copilotHours,
            current_vod_hours: vodHours,
            current_battle_hours: battleHours
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(!update){
            setCopilotHours(order?.current_copilot_hours??0);
            setVodHours(order?.current_vod_hours??0);
            setBattleHours(order?.current_battle_hours??0);
            document.getElementById('popup-root').classList.remove('popup-login');
            return;
        }
        document.getElementById('popup-root').classList.add('popup-login')
    }, [update]);

    if(!order) return(
        <>
            <div className='pending-order coaching'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='pending-order coaching'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Coaching</h1>
                        <div className='container'>
                            <div className='divider'>
                                <div className='rank-card'>
                                    <h2>Coach Rank</h2>
                                    <img id='emblem' src={require(`../../../../assets/Emblems/${order.coach_rank}_Emblem.png`)}/>
                                    <h3>{order.coach_rank}</h3>
                                </div>
                                <div className='rank-card'>
                                    <h2>Users Rank</h2>
                                    <img id='emblem' src={require(`../../../../assets/Emblems/${order.users_rank}_Emblem.png`)}/>
                                    <h3>{order.users_rank}</h3>
                                </div>
                            </div>
                            <div className='divider'>
                                <div className='container'>
                                    <h2>Region</h2>
                                    <h3>{order.server}</h3>
                                </div>
                                <div className='container'>
                                    <h2>Language</h2>
                                    <h3>{order.language}</h3>
                                </div>
                            </div>
                        </div>
                        <div className='buttons container'>
                            <button className='massive-btn' onClick={() => setCurrentPrice(true)}>Price</button>
                            <button className='massive-btn' onClick={() => setStatus(true)}>Status</button>
                            <button className='massive-btn' onClick={() => setCoach(true)}>Coach</button>
                        </div>
                        <div className='buttons container'>
                            <button className='massive-btn' onClick={() => setUpdate(true)}>Progress</button>
                            <button className='massive-btn' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                        </div>
                        <Popup open={currentPrice} position="right center" closeOnEscape closeOnDocumentClick>
                            <CurrentPrice close={() => setCurrentPrice(false)} 
                                currentPrice={order.current_price} 
                                setLoading={setLoading} 
                                order_id={order.order_id}
                            />
                        </Popup>
                        <Popup open={status} position="right center" closeOnEscape closeOnDocumentClick>
                            <ChangeStatus close={() => setStatus(false)} 
                                currentStatus={ORDER_STATUS.PENDING} 
                                setLoading={setLoading} 
                                order_id={order.order_id}
                            />
                        </Popup>
                        <Popup open={coach} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setCoach(false)}>
                            <div className='update-order net-wins'>
                                <CoachSelector close={() => setCoach(false)} 
                                    order_id={order.order_id} 
                                    setLoading={setLoading}
                                />
                            </div>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order coaching'>
                                {(order.copilot_hours > 0)&&<div>
                                    <h1>Copilot Hours {copilotHours}</h1>
                                    <Slider 
                                        defaultValue={order.current_copilot_hours} 
                                        step={1} 
                                        min={0} 
                                        max={order.copilot_hours} 
                                        onChange={(event, newValue) => {
                                            setCopilotHours(newValue);
                                        }}
                                    />
                                </div>}
                                {(order.vod_hours > 0)&&<div>
                                    <h1>Vod Hours {vodHours}</h1>
                                    <Slider 
                                        defaultValue={order.current_vod_hours} 
                                        step={1} 
                                        min={0} 
                                        max={order.vod_hours} 
                                        onChange={(event, newValue) => {
                                            setVodHours(newValue);
                                        }}
                                    />
                                </div>}
                                {(order.battle_hours > 0)&&<div>
                                    <h1>1v1 Hours {battleHours}</h1>
                                    <Slider 
                                        defaultValue={order.current_battle_hours} 
                                        step={1} 
                                        min={0} 
                                        max={order.battle_hours} 
                                        onChange={(event, newValue) => {
                                            setBattleHours(newValue);
                                        }}
                                    />
                                </div>}
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>Customer</h2>
                            <p>{order.username}</p>
                        </div>
                        <div className='card'>
                            <h2>Total Price</h2>
                            <p>${order.total_price} USD</p>
                        </div>
                        <div className='card'>
                            <h2>Current Price</h2>
                            <p>${order.current_price} USD</p>
                        </div>
                        <div className='card'>
                            <h2>Co-Pilot Hours</h2>
                            <h3>{order.copilot_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Vod-Review Hours</h2>
                            <h3>{order.vod_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>1v1 Hours</h2>
                            <h3>{order.battle_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Roles</h2>
                            <Roles elements={order.roles}/>
                        </div>
                        <div className='card'>
                            <h2>Champions</h2>
                            <Champions elements={order.champions}/>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}