
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Addons, Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ProgressBar from "@ramonak/react-progress-bar";
import Conclude from './Components/Conclude.js';

import AccountInformation from './Components/AccountInformation.js';

import Slider from '@mui/material/Slider';

export default function ActivePlacementBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [update, setUpdate] = useState(false);
    const [conclude, setConclude] = useState(false);
    const [numGames, setNumGames] = useState(0);
    const [accountInfo, setAccountInfo] = useState(false);

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/boost/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.PLACEMENT_GAMES,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setNumGames(response.data?.games_played??0)
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function concludeOrder() {
        axios.put(process.env.REACT_APP_API_URL + `/boost/conclude-boost-order/${BOOST_TYPE.PLACEMENT_GAMES}/${order.order_id}`, {  }, { 
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            navigate('/boost-panel/boosting/completed');
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            alert('Error concluding order');
        })
    }

    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.games_played === numGames) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/boost/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.PLACEMENT_GAMES,
            games_played: numGames
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(!update){
            setNumGames(order?.games_played??0);
            document.getElementById('popup-root').classList.remove('popup-login');
            return;
        }
        
        document.getElementById('popup-root').classList.add('popup-login')
    }, [update]);

    
    if(!order) return(
        <>
            <div className='active-order placement-games'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order placement-games'>
                <div className='header'>
                    <h1>Placement Games</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Previous Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.previous_rank}_Emblem.png`)}/>
                            <h3>{order.previous_rank}</h3>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container'>
                            <h2>Number of Games</h2>
                            <h3>{order.num_games}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setUpdate(true)}>Update Order</button>
                        <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account Information</button>
                        <button className='massive-btn' onClick={() => setConclude(true)}>Conclude Order</button>
                        <Popup open={conclude} position="right center" closeOnEscape closeOnDocumentClick>
                            <Conclude close={() => setConclude(false)} confirm={() => concludeOrder()}/>
                        </Popup>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order placement-games'>
                                <h1>Current Games {numGames}</h1>
                                <Slider 
                                    defaultValue={order.games_played} 
                                    step={1} 
                                    min={0} 
                                    max={order.num_games} 
                                    onChange={(event, newValue) => {
                                        setNumGames(newValue);
                                    }}
                                />
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className='body'>
                    <div className='card'>
                        <h2>Earnings</h2>
                        <h3>${order.earnings.toFixed(2)} USD</h3>
                    </div>
                    <div className='card'>
                        <h2>Addons</h2>
                        <Addons elements={order.addons}/>
                    </div>
                    <div className='card'>
                        <h2>Roles</h2>
                        <Roles elements={order.roles}/>
                    </div>
                    <div className='card'>
                        <h2>Champions</h2>
                        <Champions elements={order.champions}/>
                    </div>
                    <div className='card break-word'>
                        <h2>Notes</h2>
                        <p>{order.notes}</p>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}