
export default {
    "Iron": 0,
    "Bronze": 1,
    "Silver": 2,
    "Gold": 3,
    "Platinum": 4,
    "Emerald": 5,
    "Diamond": 6,
    "Master": 7,
    "Grandmaster": 8,

    0: "Iron",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Platinum",
    5: "Emerald",
    6: "Diamond",
    7: "Master",
    8: "Grandmaster",
}