
import React from 'react';
import { useAuth } from '../contexts/AuthContext';

import './CSS/TOS.css';

export default function Contract() {

    const [language, setLanguage] = React.useState('en');

    const { cut } = useAuth();
    
    if(language === 'es') {
        return(
            <div className='terms-of-service'>
                <h1>Términos y Condiciones para Entrenadores</h1>
                <p>Última actualización: 28 de agosto del 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                        1.0 INTRODUCCIÓN
                    </h2>
                    <p>
                    Los presentes términos y condiciones establecen los términos bajo los cuales se regirán las personas que ofrecerán los servicios de Coaching y/o Boosting a través de SMURF GG (en lo sucesivo, los “Entrenadores”).
                    </p>
                    <br />
                    <p>
                    SMURF GG es la entidad responsable de administrar y operar la página web mediante la cual se ofrecen a los Usuarios los Servicios de Boosting y Coaching (según se define más adelante) para videojuegos multijugador como League of Legends (en adelante, “LOL”) Valorant, Apex Legends, TFT, entre otros (colectivamente, los “Videojuegos”).
                    </p>
                    <br />
                    <p>
                    Los Entrenadores que decidan otorgar los Servicios a los Usuarios a través de la plataforma de SMURF GG tendrán la posibilidad de entablar contacto directamente con los Usuarios, cuando así sea requerido para la prestación adecuada de dichos servicios.
                    </p>
                    <br />
                    <p>
                    SMURF GG es una marca filial de Techstride, S.A. de C.V.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        2.0 DEFINICIONES APLICABLES
                    </h2>
                    <ul>
                        <li>
                            <p>
                                <strong>Usuario</strong>: Persona física que ingresa al sistema de SMURF GG y adquiere cualquiera de los Servicios ofrecidos en la Página.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Entrenador(es)</strong>: Personas relacionadas a SMURF GG que brindarán los Servicios de Boosting y Coaching a los Usuarios.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Boosting</strong>: Servicio que consiste en la intervención del Entrenador para elevar el nivel y/o rango de la cuenta de un Usuario de cualquier videojuego ofrecido en la plataforma a un nivel y/o rango determinado, según lo haya solicitado el Usuario.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coaching</strong>: Servicio que consiste en la orientación, preparación, enseñanza y asesoría de un Entrenador directamente con el Usuario con el objetivo de mejorar su desempeño y experiencia de juego en LOL o cualquiera de los videojuegos que ofrece la Página, según lo haya solicitado el Usuario.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Cuenta SMURF GG</strong>: Se refiere particularmente al registro que realizará el Usuario en la Página de SMURF GG y mediante la cual el Usuario podrá adquirir los servicios de Coaching y Boosting ofrecidos.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Summoner Name</strong>: Se refiere al nombre visible y perfil individual de LOL que utiliza cada uno de los Usuarios en LOL y/o demás videojuegos.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Username</strong>: Se refiere al nombre de usuario para acceder a la cuenta de LOL y/o cualquier otro Videojuego, el cual puede ser distinto al Summoner Name.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Oferta</strong>: Se refiere a la solicitud que realiza un Usuario para que le sean proveídos los Servicios adquiridos. Las Ofertas serán publicadas por SMURF GG y será decisión del Entrenador aceptarla(s).
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Servicios</strong>: Se refiere a uno o varios de los servicios ofrecidos en la Página, ya sea de Coaching, Boosting o cualquier otro.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Addons (servicios adicionales)</strong>: Se refiere a cualquiera de los siguientes servicios que estarán disponibles para el Servicio de Boosting:
                                <ul>
                                    <li>
                                        <p>
                                            i) <strong>Duo</strong>: El Usuario juega simultáneamente con el Entrenador.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ii) <strong>VPN</strong>: El Entrenador utiliza un VPN para asimilar la ubicación geográfica por IP del Usuario.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iii) <strong>Livestream</strong>: Vía Discord.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iv) <strong>Specific Roles</strong>: El Entrenador utiliza los roles que el Usuario le solicite. En ciertos casos, el Entrenador estará forzado a utilizar otro rol, debido a falta de jugadores en el Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            v) <strong>Bonus Win</strong>: Una vez alcanzado el objeto solicitado por el Usuario, el Entrenador juega y gana una partida adicional para asegurar que el Usuario no esté en riesgo de bajar de nivel.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vi) <strong>Specific Champions</strong>: El Entrenador utiliza los campeones que solicite el Usuario. Existen casos en los que no es posible utilizar a un determinado campeón por (i) el campeón ya fue elegido por algún jugador dentro de la partida, (ii) el campeón fue prohibido por los integrantes del equipo y/o los contrarios y (iii) el campeón está desactivado por fallas del Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vii) <strong>Appear Offline</strong>: El Entrenador utiliza aplicaciones como “Deceive”, con el objetivo de que no aparezca en línea dentro del Videojuego.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            viii) <strong>Solo Play</strong>: El Entrenador juega las partidas que le corresponden solo, es decir, sin la intervención del Usuario o de otro Entrenador.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ix) <strong>Priority</strong>: Por un precio adicional, se amplía la búsqueda de Entrenadores y la posibilidad de se acepte la oferta.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        3.0 APLICACIÓN DE LOS TÉRMINOS Y CONDICIONES
                    </h2>
                    <p>
                    Los presentes términos y condiciones serán aplicables para todos los Entrenadores que acepten una Oferta publicada por SMURF GG para ofrecer alguno de los Servicios descritos en la cláusula anterior.                    
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        4.0 INSCRIPCIÓN DE ENTRENADORES  
                    </h2>
                    <p>
                    Cualquier Entrenador que pretenda aceptar alguna Oferta publicada por SMURF GG para prestar los Servicios a uno o varios Usuarios, deberá presentar la siguiente información:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Nombre y Apellido del Entrenador
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Nombre de Usuario de Discord
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Correo Electrónico
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) País de Residencia
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Nivel de Expertise
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Breve descripción de su experiencia con los Videojuegos 
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Validar su Username y Summoner Name (en su caso) para el o los Videojuegos en los que busque ofrecer sus servicios. 
                            </p>
                        </li>
                    </ul>
                    <br />
                    <p>
                    Toda la información proporcionada por el Entrenador deberá ser verídica y correcta para cada campo solicitado. En caso de que la información proporcionada sea incorrecta o fraudulenta, SMURF GG tendrá el derecho de limitar y/o negar el acceso del Entrenador a la página web de SMURF GG y a la información de(los) Usuario(s) inscrito(s).
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        5.0 ACEPTACIÓN DE LA OFERTA PARA OFRECER SERVICIOS
                    </h2>
                    <p>
                    Para conocer y, en su caso, aceptar una Oferta, los Entrenadores tendrán a su disposición un listado con las Ofertas existentes, el cual será publicado en la Página. De igual forma, se les dará aviso sobre Ofertas existentes mediante la plataforma “Discord”.  El Entrenador decidirá si acepta una determinada Oferta, bajo responsabilidad de que cuenta con las capacidades, tiempo y recursos para cumplirla.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        6.0 DERECHOS Y OBLIGACIONES DEL ENTRENADOR
                    </h2>
                    <p>
                    Una vez que el Entrenador haya aceptado una Oferta para ofrecer alguno de los Servicios, deberá establecer contacto con el o los Usuarios a quien(es) se le(s) vaya a proporcionar los Servicios, ya sea por medio de Discord o cualquier otro medio autorizado por SMURF GG.
                    </p>
                    <br />
                    <p>
                    Los Entrenadores prestarán sus servicios con diligencia y eficacia, debiendo comportarse con respeto al dirigirse a los Usuarios. Cualquier incumplimiento a lo anterior podría resultar en sanción para el Entrenador, incluyendo la retención del dinero en su cuenta y/o la suspensión permanente de la cuenta del Entrenador en SMURF GG. De igual forma, los Entrenadores tendrán la opción de solicitar la cancelación de los Servicios aceptados ante cualquier trato inadecuado, para lo cual deberán notificarlo al equipo de soporte de SMURF GG inmediatamente.
                    </p>
                    <br />
                    <p>
                    Los Entrenadores, por medio de las plataformas precisadas con anterioridad o en la página web de SMURF GG, indicarán el día y hora en la cual estén disponibles para ofrecer los Servicios a los Usuarios. 
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        7.0 REGLAS DE USO DE LA PLATAFORMA
                    </h2>
                    <p>
                    Los Entrenadores que colaboren en la prestación de Servicios por medio de SMURF GG deberán observar las siguientes reglas, por lo cual se comprometen a:
                    </p>
                    <br />
                    <ul>
                        <li>
                            <p>
                                i) Contactar a los Usuarios únicamente a través de las plataformas reconocidas por  SMURF GG, tales como “Discord”, correo electrónico y/o el Chat de SMURF GG. 
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Dirigirse a los Usuarios y/o demás jugadores de los Videojuegos con respeto.
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Colaborar, apoyar y aconsejar a los Usuarios según los Servicios que prestarán en el periodo de tiempo necesario. 
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Abstenerse en todo momento a divulgar la información del Usuario proporcionada por SMURF GG para el solo efecto de la prestación de los Servicios.
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Observar y seguir las reglas establecidas por los Videojuegos en los cuales intervengan para la prestación de los Servicios.
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Procurar en todo momento la integridad de las cuentas de los Usuarios en LOL o cualquier Videojuego que se trate.
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Completar cualquier Orden aceptada dentro de un periodo máximo de 14 (catorce) días naturales, contados a partir de la aceptación de la Oferta, salvo en casos de excepcional complejidad y/o cuando el Usuario restringe el uso de su Cuenta y/o cuando así lo decidan conjuntamente el Entrenador y el Usuario.
                            </p>
                        </li>
                        <li>
                            <p>
                                viii) Comenzar la prestación de los Servicios en un margen que no exceda de dos horas posteriores a la aceptación de la Orden.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        8.0 PROTECCIÓN DE LA INFORMACIÓN 
                    </h2>
                    <p>
                    Estrictamente para el desempeño de los Servicios, SMURF GG proporcionará a los Entrenadores la siguiente información de los Usuarios:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Username en el Videojuego (únicamente para Boosting sin la modalidad “Duo”)
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Summoner Name (LOL)
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Región geográfica del Usuario en el Videojuego
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Servicio(s) adquirido por el Usuario, incluyendo servicios adicionales
                            </p>
                        </li>
                    </ul>
                    <p>
                    Únicamente para los Servicios de Boosting sin el Addon “Duo”, SMURF GG también proporcionará al Entrenador la contraseña del Usuario para ingresar a su cuenta en un determinado Videojuego. 
                    </p>
                    <br />
                    <p>
                    Los Entrenadores están obligados en todo momento a NO divulgar la información proporcionada por SMURF GG para el desempeño de los Servicios. Incumplimiento sobre este rubro podría resultar en sanciones para el Entrenador e inclusive acción legal.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        9.0 MÉTODO Y FORMA DE PAGO
                    </h2>
                    <p>
                    Como se estableció en la Cláusula Séptima, los Entrenadores contarán con un plazo de 2 (dos) horas para comenzar a prestar los Servicios solicitados mediante la(s) Oferta(s) de los Usuarios que hayan aceptado.
                    </p>
                    <br />
                    <p>
                    Una vez aceptada(s) la(s) Oferta(s), se le informará al Usuario que un Entrenador ha decidido prestar los Servicios adquiridos. El Entrenador deberá prestar los Servicios, ya sea de Boosting o Coaching, en su totalidad para estar en condiciones de recibir el pago por parte de SMURF GG. Una vez concluida la prestación de los Servicios (según sea el objetivo de cada Usuario) satisfactoriamente, los Entrenadores verán reflejado el pago en su(s) respectivos estado(s) de cuenta(s) en su cuenta SMURF GG, inmediatamente después de haber sido autorizado por un administrador de SMURF GG. 
                    </p>
                    <br />
                    <p>
                    SMURF GG percibirá en primer lugar la totalidad del pago realizado por el Usuario. A la totalidad del pago, se le restará una comisión para gastos operativos del 12% (doce por Del 88% (ochenta y ocho por ciento) restante del monto total recibido por SMURF GG, el Entrenador percibirá un monto variable del {cut}% del restante, y cuyo porcentaje podrá incrementarse dependiendo directamente de su rango de nivel en el Videojuego y el número de órdenes completadas a través de la página SMURF GG.
                    </p>
                    <br />
                    <p>
                    Posteriormente, los ingresos percibidos por la prestación de los Servicios se verán reflejados en la cuenta SMURF GG de los Entrenadores. Los Entrenadores podrán retirar los montos visibles en su cuenta SMURF GG, siempre y cuando la cantidad acumulada por la prestación de servicios sea de al menos USD$50.00 (cincuenta dólares de los Estados Unidos de América 00/100). Para retirar el monto, el Entrenador realizará una solicitud por el monto que desea retirar de su cuenta SMURF GG. El pago se verá reflejado dentro de un plazo máximo de 14 (catorce) días.
                    </p>
                    <br />
                    <p>
                    En caso de que para el mes de diciembre la cuenta SMURF GG del Entrenador refleje un saldo positivo, indistintamente del monto, se entregará la totalidad del saldo al Entrenador durante ese mes, a fin de comenzar el ejercicio fiscal siguiente con saldos en cero. Los pagos a los Entrenadores se realizarán por medio de PayPal.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        10.0 CANCELACIONES 
                    </h2>
                    <p>
                    Los Entrenadores podrán cancelar los Servicios que hubieren aceptado realizar en cualquier momento. En tal supuesto, no serán acreedores del pago que les hubiere correspondido por completar la Orden, pues los Entrenadores únicamente percibirán el pago señalado en esta Cláusula al momento de completar una Orden en su totalidad.
                    </p>
                    <br />
                    <p>
                    De igual forma, SMURF GG podrá cancelar el Servicio en el cual intervenga un Entrenador en caso de que (i) el Usuario lo solicite por tratarse de un Servicio deficiente o incompleto, (ii) el Usuario no está conforme con el trato brindado por el Entrenador, (iii) el Usuario y/o SMURF GG consideren que el Servicio prestado por el Entrenador ha demorado más del tiempo razonable que se espera para su conclusión o que haya acordado con el Usuario.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        11.0 NO RELACIÓN LABORAL
                    </h2>
                    <p>
                    SMURF GG y el(los) Entrenador(es) manifiestan que entre ellos no existe relación laboral que pueda ser considerada como relación patrón-empleado. SMURF GG no estará obligado al pago de conceptos y/o prestaciones laborales adicionales ni al pago de indemnizaciones por motivos de cancelación de los Servicios y/o cancelación de la cuenta SMURF GG del Entrenador.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        12.0 JURISDICCIÓN Y COMPETENCIA 
                    </h2>
                    <p>
                    La jurisdicción aplicable para cualquier especie de disputa, controversia, resolución jurisdiccional será exclusivamente la de los Estados Unidos Mexicanos (México). SMURF GG y sus empresas filiales son entidades de nacionalidad mexicana y, por consecuencia, observan las leyes nacionales con relación a prestación de servicios, derechos de autor, resolución de controversias y obligaciones para proteger datos personales de los Usuarios. 
                    </p>
                    <br />
                    <p>
                    Al aceptar estos términos y condiciones, el Entrenador acepta su conformidad con todo lo dispuesto y renuncia a cualquier otra jurisdicción que le pueda corresponder por virtud de su domicilio presente o futuro y/o nacionalidad.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        13.0 FECHA DE INICIO Y VIGENCIA DE LOS TÉRMINOS Y CONDICIONES
                    </h2>
                    <p>
                    Estos Términos y Condiciones serán aplicables para cualquier Entrenador a partir de que se inscriba y/o sea dado de alta como Entrenador en la Página de SMURF GG.
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Idioma</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>Inglés</option>
                        <option value='es'>Español</option>
                    </select>
                </div>
            </div>
        );
    } else if(language === 'en') {
        return(
            <div className='terms-of-service'>
                <h1>Terms & Conditions for SMURF GG Coaches</h1>
                <p>Last updated: August 28th, 2024</p>
                <div className='terms-of-service-container'>
                    <h2>
                        1.0 FOREWORD
                    </h2>
                    <p>
                    These terms and conditions lay out the guidelines by which the persons offering Coaching and/or Boosting Services through SMURF GG must abide by (henceforth, the “Coaches”).
                    </p>
                    <br />
                    <p>
                    SMURF GG manages and operates this Website, by which Boosting and Coaching services (as defined later on) for multiplayer videogames such as League of Legends (henceforth, “LOL”), Valorant, Apex Legends, TFT, and others (collectively referred to as the “Videogames”) are offered to the public.
                    </p>
                    <br />
                    <p>
                    Should Coaches decide to provide the Services to the Users through SMURF GG, they shall have the possibility of contacting the Users directly when required for the adequate provision of the Services. 
                    </p>
                    <br />
                    <p>
                    SMURF GG is a subsidiary brand of Techstride, S.A. de C.V.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        2.0 DEFINITIONS
                    </h2>
                    <ul>
                        <li>
                            <p>
                                <strong>User</strong>: Any person that signs up to SMURF GG and purchases any of the Services offered on the Website.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coach</strong>: Any person related to SMURF GG that provides Boosting and/or Coaching Services to the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Boosting</strong>: Service that consists in the intervention of a Coach to increase the level and/or rank of a User’s account for any of the Videogames offered on the Website to a given level and/or rank, as requested by the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Coaching</strong>: Service that consists in guidance, preparation, teaching, and/or counseling offered by a Coach directly to a User, in order to improve the User’s performance and in-game experience for LOL or any of the Videogames offered on the Website, as requested by the User.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>SMURF GG Account</strong>: Refers to the account created by the User when signing up to SMURF GG through the Website, in order to purchase any of the Services that are offered.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Summoner Name</strong>: Refers to the visible name and individual gaming profile that is used by the User for LOL or any of the Videogames.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Username</strong>: Refers to the username used to access an individual LOL account or an account for any of the Videogames, which may differ from Summoner Name.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Offer</strong>: Refers to the request(s) submitted by a User for specific Services. Offers will be published by SMURF GG and accepting them will be the Coach’s decision.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Services</strong>: Refers to any of the services offered on the Website, be it Coaching, Boosting, or Addons.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Addons (additional services)</strong>: Refers to any of the following, available as complements to the Boosting Service:
                                <ul>
                                    <li>
                                        <p>
                                            i) <strong>Duo</strong>: User plays simultaneously with a Coach.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ii) <strong>VPN</strong>: Coach uses a VPN to match User’s geographical location by IP Address.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iii) <strong>Livestream</strong>: Via Discord.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            iv) <strong>Specific Roles</strong>: Coach uses the roles during gameplay requested by User. In certain cases, the Coach will be required to use a different role, due to insufficient players in a match or Videogame.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            v) <strong>Bonus Win</strong>: Once the objective requested by User is fulfilled, Coach plays an additional match to ensure that User is out of risk of decreasing level.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vi) <strong>Specific Champions</strong>: Coach plays with the champion(s) requested by User. There are cases in which it will not be possible to use a specific champion, due to (i) the unavailability of the champion because it has already been chosen by another player in a match, (ii) the champion has been banned by the team members and/or opponents, and (iii) the champion has been deactivated due to Videogame malfunction.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            vii) <strong>Appear Offline</strong>: Coach employs apps such as “Deceive” to avoid appearing online in the Videogame.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            viii) <strong>Solo Play</strong>: Coach plays the required matches completely solo, without the intervention of the User or another Coach.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            ix) <strong>Priority</strong>: For an additional price, the search for a Coach is widened and with it the possibility of an offer being accepted.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        3.0 APPLICATION OF TERMS AND CONDITIONS
                    </h2>
                    <p>
                    Coaches that sign up for a SMURF GG account and/or accept any of the Offers published by SMURF GG offered on the Website shall be bound to these Terms and Conditions.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        4.0 REGISTRATION AND SIGN UP
                    </h2>
                    <p>
                    Coaches intending to accept an Offer published by SMURF GG with the purpose of providing Services to one or more Users must submit the following information:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Name and Last Name
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Discord Username
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Email Address
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Country of Residence
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Level of Expertise
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Brief description of experience with the Videogames
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Validate Username and Summoner Name (if applicable) for the Videogame(s) for which the Services will be provided.
                            </p>
                        </li>
                    </ul>
                    <br />
                    <p>
                    All information submitted by the Coaches must be accurate for every entry. Failure to submit the required information truthfully may grant SMURF GG the option to limit and/or deny the Coaches’ access to the Website and to the Users’ information.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        5.0 ACCEPTING AN OFFER
                    </h2>
                    <p>
                    To learn about and accept an Offer, Coaches will have at their disposal a list with existing Offers, which will be published on the Website. Likewise, Coaches will be notified of existing Offers through “Discord”. Coaches will decide whether or not to accept a particular Offer, in the understanding that they have the capacity, time, and resources to fulfill it.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        6.0 RIGHTS AND OBLIGATIONS OF THE COACH
                    </h2>
                    <p>
                    Having accepted an Offer to provide any of the Services, Coaches must contact the User(s) to whom the Services will be provided through Discord or any other medium authorized by SMURF GG.
                    </p>
                    <br />
                    <p>
                    Coaches will provide their Services diligently and efficiently, by which they must conduct themselves respectfully when contacting Users. Any breach to the foregoing may result in penalties for the Coach, which include withholding of the Coach’s funds in the SMURG GG account and/or permanent suspension of Coach’s SMURF GG account. Likewise, Coaches will have the option to request the cancellation of the accepted Services due to inappropriate treatment by the User, for which they must notify SMURF GG’s support team as soon as possible.
                    </p>
                    <br />
                    <p>
                    Coaches must indicate the date and time in which they are available to offer the Services to the Users through the aforementioned platforms and/or SMURF GG’s Website.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        7.0 RULES FOR WEBSITE USE
                    </h2>
                    <p>
                    Coaches who participate in the provision of Services through SMURF GG will observe the following rules, by which they hereby undertake to:
                    </p>
                    <br />
                    <ul>
                        <li>
                            <p>
                                i) Contact the User(s) directly through any of the authorized platforms, such as “Discord”, email, and/or SMURF GG chat.
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Address the User(s) and other players in the Videogame respectfully.
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) Collaborate with, support, and advise the User(s) in accordance with the requested Services within the required time period.
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Refrain at all times from disclosing any information provided by SMURF GG and pertaining to the User(s).
                            </p>
                        </li>
                        <li>
                            <p>
                                v) Observe and follow the rules set forth by the Videogames in which they participate.
                            </p>
                        </li>
                        <li>
                            <p>
                                vi) Ensure the integrity of the User’s LOL account and/or User’s account for any of the other Videogames.
                            </p>
                        </li>
                        <li>
                            <p>
                                vii) Complete all accepted Offers within a 14 (fourteen) day period starting on the day from which the Offers were accepted, except for cases of exceptional complexity, if User restricts access to User’s account, and/or when jointly decided by Coach and User.
                            </p>
                        </li>
                        <li>
                            <p>
                                viii) Begin providing the Services within a time frame not exceeding 2 (two) hours after acceptance of the Offer.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        8.0 PROTECTION OF INFORMATION
                    </h2>
                    <p>
                    SMURF GG will provide the following information pertaining to the Users, strictly for the provision of the Services:
                    </p>
                    <ul>
                        <li>
                            <p>
                                i) Videogame Username (for Boosting without “Duo” addon only)
                            </p>
                        </li>
                        <li>
                            <p>
                                ii) Summoner Name (LOL)
                            </p>
                        </li>
                        <li>
                            <p>
                                iii) User’s geographical region in the Videogame
                            </p>
                        </li>
                        <li>
                            <p>
                                iv) Service(s) requested by the User, including any additional service
                            </p>
                        </li>
                    </ul>
                    <p>
                    SMURF GG will also provide the Coach with User’s password for access to User’s Videogame account, exclusively for Boosting Services without the “Duo” addon.
                    </p>
                    <br />
                    <p>
                    Coaches must refrain at all times from disclosing the information provided by SMURF GG. Failure to comply with the aforementioned obligation may result in penalties and legal action.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        9.0 PAYMENT
                    </h2>
                    <p>
                    As set forth in Clause Seventh, Coaches will have a 2 (two) hour period from the moment an Offer is accepted to begin providing the Services requested by User(s).
                    </p>
                    <br />
                    <p>
                    Upon the acceptance of the Offer, Users will receive notice that a Coach has agreed to provide the requested Services. Coaches must provide the Services, whether Boosting or Coaching, completely in order to receive payment from SMURF GG. Once the provision of Services has been successfully completed (depending on Users’ objectives), payment will be reflected in Coaches’ SMURF GG account, immediately after being approved by a member of SMURF GG’s management.
                    </p>
                    <br />
                    <p>
                    SMURF GG will receive the total amount paid by the User. A 12% (twelve per cent) operative costs fee will be deducted from the total amount. From the remaining 88% (eighty-eight per cent) of the total amount received by SMURF GG, the Coach will receive a variable amount of {cut}%. The percentage received by the Coach may be increased depending on the Coach’s ranking level in the Videogame and the number of orders completed through the SMURF GG Website.
                    </p>
                    <br />
                    <p>
                    Subsequently, Coaches’ account statements in their SMURF GG accounts will reflect the income received for the provision of Services. Coaches may withdraw the available funds from their SMURF GG account, provided that the accrued amount of funds is at least US$50.00 (fifty U.S. Dollars 00/100). To withdraw funds, Coaches must submit a request detailing the desired amount for withdrawal. Payment will be completed within a maximum period of 14 (fourteen) days.
                    </p>
                    <br />
                    <p>
                    If by the month of December a Coach’s SMURF GG account reflects a positive balance, regardless of the amount, that amount will be transferred to the Coach within that same month, to ensure that the accounts reflect zero at the beginning of the following fiscal year. All payments to Coaches will be carried our through PayPal.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        10.0 CANCELLATIONS 
                    </h2>
                    <p>
                    Coaches may cancel previously-accepted Services at any time. In such a case, Coaches will not be entitled to receive the payment for the completion of an Offer. Coaches will only receive the payment set forth in the previous clause upon the total completion of an Offer.
                    </p>
                    <br />
                    <p>
                    Likewise, SMURF GG may cancel a Service in which a Coach is involved, in any of the following cases: (i) User requests cancellation for deficient or incomplete Services provided by the Coach, (ii) User is discontent with Coach’s treatment and demeanor, (iii) User and/or SMURF GG consider that the Service provided by the Coach has been delayed beyond the expected timeframe or the User’s requests.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        11.0 LABOR INDEPENDENCE
                    </h2>
                    <p>
                    SMURF GG and Coaches expressly declare that there is no employment relation whatsoever between the two Parties. SMURF GG may not be held liable or responsible for the payment of employee benefits, a periodic wage, nor the payment of compensation and/or indemnity for the cancellation of Services and/or the cancellation of Coach’s SMURF GG account.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        12.0 JURISDICTION AND COMPETENCE
                    </h2>
                    <p>
                    The applicable jurisdiction in case of dispute, controversy, and/or lawsuit, will be the United Mexican States (Mexico). SMURF GG and affiliates are entities of Mexican nationality and, as such, as construed to follow Mexican Laws applicable to the provision of services, copyrights, controversy resolution, and obligations to protect User’s personal information.
                    </p>
                    <br />
                    <p>
                    By accepting these Terms and Conditions, Coaches manifest conformity with all provisions set forth herein and waive any other jurisdiction that may correspond by virtue of citizenship and/or address.
                    </p>
                </div>
                <div className='terms-of-service-container'>
                    <h2>
                        13.0 TERMS & CONDITIONS TERM AND VALIDITY
                    </h2>
                    <p>
                    These Terms and Conditions will come in force upon the registration and sign up as a Coach through the SMURF GG Website.
                    </p>
                </div>
                <div className='terms-of-service-footer'>
                    <p>Language</p>
                    <select value={language} onChange={(e) => setLanguage(e.target.value)}>
                        <option value='en'>English</option>
                        <option value='es'>Spanish</option>
                    </select>
                </div>
            </div>
        );
    }
}