
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute.js"
import BoosterRoute from "./utils/BoosterRoute.js"
import AdminRoute from "./utils/AdminRoute.js"
import NavBar from './content/NavBar';
import Home from './content/Home';
import OrdersPanel from './content/OrdersPanel.js';
import BoostPanel from './content/BoostPanel.js';
import AdminPanel from './content/AdminPanel.js';
import GetBoosting from './content/GetBoosting';
import Footer from './content/Footer';

import './App.css';
import './components/components.css';
import './content/CSS/OrderNow.css';
import './content/CSS/BoostInformation.css';
import './content/GetBoosting/CSS/Boosting.css';
import './content/GetCoaching/CSS/Coaching.css';
import GetCoaching from './content/GetCoaching.js';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import TermsOfService from './content/TermsOfService.js';
import PrivacyPolicy from './content/PrivacyPolicy.js';
import Blog from './content/Blog.js';
import NotFound from './content/NotFound.js';
import Contract from './content/Contract.js';
import axios from 'axios';


function App() {

  const MySwal = withReactContent(Swal);
  const location = useLocation();

  const contentRef = React.createRef();

  
  useEffect(() => {
    const fetchCurrency = async () => {
        try {
          //console.log('Fetching currency');
          if(localStorage.getItem('currency'))  {
            //console.log('Currency already set: ', localStorage.getItem('currency'));
            return;
          }
          const response = await axios.get('https://ipapi.co/json/');
          if(response.data.currency === 'MXN'){
            //console.log('Setting currency to MXN');
            localStorage.setItem('currency', 'MXN');
          } else {
            //console.log('Setting currency to USD');
            localStorage.setItem('currency', 'USD');
          }
        } catch (error) {
          //console.log('ERROR , setting currency to USD');
          localStorage.setItem('currency', 'USD');
          console.error('Error fetching currency:', error);
        }
    };
    fetchCurrency();
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if(!currentUrl.includes('boost-panel')) {
      /*
      MySwal.fire({
        title: 'Website is currently under construction!',
        text: 'Website is currently in testing phase. Please come back later! Thank you.',
        icon: 'warning',
        confirmButtonText: 'Ok'
      });
      */
    }
    return () => {
      window.removeEventListener('scroll', onScrollListener);
    }
  }, []);

  useEffect(() => {
    if(!contentRef.current) return;
    window.addEventListener('scroll', onScrollListener);
    return () => {
      window.removeEventListener('scroll', onScrollListener);
    }
  }, [contentRef]);

  const onScrollListener = () => {
    if(!contentRef.current) return;
    if(window.scrollY < 0) return;
    if(location.pathname === '/') {
      contentRef.current.style.backgroundPosition = `${-window.scrollY / 100}px ${window.scrollY / 20}px, ${window.scrollY / 10}px ${-window.scrollY / 80}px`;
    }
  }

  return (
    <>
      <div ref={contentRef} className={`content${location.pathname === '/' ? ' home' : ''}`}>
        <NavBar id='navbar' />
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='/my-orders/:panel' element={<OrdersPanel />} />
            <Route path='/my-orders/:panel/:status' element={<OrdersPanel />} />
            <Route path='/my-orders/:panel/:status/:boost_type/:order_id' element={<OrdersPanel />} />
            <Route path='/my-orders/:panel/:status/:order_id' element={<OrdersPanel />} />
            <Route path='/reset-password' element={<Home />} />
            <Route path='/job-application/:random_id' element={<Home />} />
          </Route>
          <Route element={<BoosterRoute />}>
            <Route path='/boost-panel/:panel' element={<BoostPanel />} />
            <Route path='/boost-panel/:panel/:status' element={<BoostPanel />} />
            <Route path='/boost-panel/:panel/:status/:boost_type/:order_id' element={<BoostPanel />} />
            <Route path='/boost-panel/:panel/:status/:order_id' element={<BoostPanel />} />
            <Route path='/terms-of-service/boosters' element={<Contract />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path='/admin-panel/:panel' element={<AdminPanel />} />
            <Route path='/admin-panel/:panel/:status' element={<AdminPanel />} />
            <Route path='/admin-panel/:panel/:status/:boost_type/:order_id' element={<AdminPanel />} />
            <Route path='/admin-panel/:panel/:status/:boost_type' element={<AdminPanel />} />
            <Route path='/admin-panel/:panel/:status/:order_id' element={<AdminPanel />} />
          </Route>
          <Route path='/get-boosting/:boost_type' element={<GetBoosting />} />
          <Route path='/get-coaching/:coach_type' element={<GetCoaching />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

/*

          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:post' element={<Blog />} />

*/

export default App;