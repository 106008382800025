import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

export default function DropDown({ elements, onChange, defaultValue, disabled }) {
  const [active, setActive] = useState(defaultValue || elements[0]);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    onChange(active);
  }, [active, onChange]);

  useEffect(() => {
    setActive(defaultValue || elements[0]);
  }, [elements, defaultValue]);

  // When clicked outside of the dropdown, close it
  useEffect(() => {
    const close = () => {
      setClicked(false);
    };
    window.addEventListener('click', close);
    return () => window.removeEventListener('click', close);
  }, []);

  // Prevent the click event on the button from propagating to the window click listener
  const handleButtonClick = (e) => {
    e.stopPropagation();
    setClicked((current) => !current);
  };

  if(elements.includes('Grandmaster')){
    return (
      <div className={`dropdown${clicked ? ' clicked' : ' inactive'} wide`} onClick={handleButtonClick}>
        <FontAwesomeIcon icon={faCaretDown} />
        <button>{active}</button>
        <div className={`dropdown-content${clicked ? ' clicked' : ''} wide`}>
          {elements.map((element) => (
            <a
              key={element}
              className={`dropdown-element${element === active ? ' active' : ' inactive'}${
                disabled && disabled.includes(element) ? ' disabled' : ''
              }`}
              onClick={() => (disabled && disabled.includes(element) ? null : setActive(element))}
            >
              {element}
            </a>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`dropdown${clicked ? ' clicked' : ' inactive'}`} onClick={handleButtonClick}>
      <FontAwesomeIcon icon={faCaretDown} />
      <button>{active}</button>
      <div className={`dropdown-content${clicked ? ' clicked' : ''}`}>
        {elements.map((element) => (
          <a
            key={element}
            className={`dropdown-element${element === active ? ' active' : ' inactive'}${
              disabled && disabled.includes(element) ? ' disabled' : ''
            }`}
            onClick={() => (disabled && disabled.includes(element) ? null : setActive(element))}
          >
            {element}
          </a>
        ))}
      </div>
    </div>
  );
}
