
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext.js';

import axios from 'axios';

import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import AccountInformation from '../Components/AccountInformation.js';

import Popup from 'reactjs-popup';
import DropDown from '../../../../../components/DropDown.js';
import { SelectorContainer, SelectorButton } from '../../../../../components/Selector.js';

import Slider from '@mui/material/Slider';

import VerifyBody from '../Components/VerifyBody.js';


export default function VerifyNetWinBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [info, setInfo] = useState(null);

    const [update, setUpdate] = useState(false);
    const [numGames, setNumGames] = useState(0);
    const [rank, setRank] = useState('Iron');
    const [division, setDivision] = useState(4);
    const [accountInfo, setAccountInfo] = useState(false);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.NET_WINS,
                order_status: ORDER_STATUS.COMPLETED
            }
        }).then(response => {
            let order = response.data.userData;
            let info = response.data.infoData;
            if(info) {
                setInfo(info);
            }
            setOrder(order);
            setNumGames(order?.current_net_wins??0);
            setRank(order?.current_rank??'Iron');
            setDivision(order?.current_division??4);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function confirmOrder() {
        MySwal.fire({
            title: 'Are you sure the order has been completed?',
            showCancelButton: true,
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/verify-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.NET_WINS 
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Confirmed!',
                        text: 'Order verified.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }

    function rejectOrder() {
        MySwal.fire({
            title: 'Are you sure the order was incomplete?',
            text: 'Make sure you update the order progress before continuing.',
            showCancelButton: true,
            confirmButtonText: 'Accept',
            html: `
                <input 
                    type="number" 
                    id="customPrice" 
                    placeholder="Enter custom price" 
                />
            `,
        }).then((result) => {
            if (result.isConfirmed) {
                if(order.progress >= 100) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Order progress must be less than 100% to reject the order.',
                    })
                    return;
                }
                const customPrice = document.getElementById('customPrice').value;
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/reject-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.NET_WINS,
                    order_price: customPrice === '' ? null : customPrice  // Pass the custom price to the backend
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Order has been moved back to pending.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }
    
    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.current_net_wins === numGames && order.current_rank === rank && order.current_division === division) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress-verify', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.NET_WINS,
            current_net_wins: numGames,
            current_rank: rank,
            current_division: division
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    if(!order) {
        return(
            <>
                <div className='pending-order division-boost'/>
                <Loading loading={loading} panel={true}/>
            </>
        );
    }
    
    return(
        <>
            <div className='pending-order net-wins'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Net Wins</h1>
                        <div className='container'>
                            <div className='rank-card'>
                                <h2>Current Rank</h2>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container'>
                                <h2>{order.num_net_wins} Net Wins</h2>
                            </div>
                            <div className='container'>
                                <h2>LP Gains</h2>
                                <h3>{LP_GAINS.DISPLAY(order.current_lp_gains)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => confirmOrder()}>Confirm</button>
                            <button id='pending-accept' onClick={() => rejectOrder()}>Reject</button>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                            <button id='pending-accept' onClick={() => setAccountInfo(true)}>Account</button>
                        </div>
                        <button id='pending-accept' onClick={() => setUpdate(true)}>Edit</button>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order net-wins'>
                                <h1>Current Rank</h1>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${rank}_Emblem.png`)} className='rank-updater-emblem'/>
                                <DropDown elements={ranks} defaultValue={rank} onChange={setRank}/>
                                <SelectorContainer currentValue={division} setCurrentValue={(value) => setDivision(value)}>
                                    <SelectorButton value={4}>IV</SelectorButton>
                                    <SelectorButton value={3}>III</SelectorButton>
                                    <SelectorButton value={2}>II</SelectorButton>
                                    <SelectorButton value={1}>I</SelectorButton>
                                </SelectorContainer>
                                <h1>Current Net Wins {numGames}</h1>
                                <Slider 
                                    defaultValue={order.current_net_wins} 
                                    step={1} 
                                    min={-order.num_net_wins*2} 
                                    max={order.num_net_wins} 
                                    onChange={(event, newValue) => {
                                        setNumGames(newValue);
                                    }}
                                />
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <VerifyBody order={order} info={info}/>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}