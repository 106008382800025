
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext.js';

import axios from 'axios';

import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import AccountInformation from '../Components/AccountInformation.js';
import VerifyBody from '../Components/VerifyBody.js';
import Slider from '@mui/material/Slider';
import Popup from 'reactjs-popup';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function VerifyPlacementBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [info, setInfo] = useState(null);
    
    const [update, setUpdate] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);

    const [numGames, setNumGames] = useState(0);

    const navigate = useNavigate();

    const MySwal = withReactContent(Swal);

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.PLACEMENT_GAMES,
                order_status: ORDER_STATUS.COMPLETED
            }
        }).then(response => {
            let order = response.data.userData;
            let info = response.data.infoData;
            if(info) {
                setInfo(info);
            }
            setOrder(order);
            setNumGames(order?.games_played??0)
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);
    
    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.games_played === numGames) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress-verify', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.PLACEMENT_GAMES,
            games_played: numGames
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }
    
    function confirmOrder() {
        MySwal.fire({
            title: 'Are you sure the order has been completed?',
            showCancelButton: true,
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/verify-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.PLACEMENT_GAMES 
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Confirmed!',
                        text: 'Order verified.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }

    function rejectOrder() {
        MySwal.fire({
            title: 'Are you sure the order was incomplete?',
            text: 'Make sure you update the order progress before continuing.',
            showCancelButton: true,
            confirmButtonText: 'Accept',
            html: `
                <input 
                    type="number" 
                    id="customPrice" 
                    placeholder="Enter custom price" 
                />
            `,
        }).then((result) => {
            if (result.isConfirmed) {
                if(order.progress >= 100) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Order progress must be less than 100% to reject the order.',
                    })
                    return;
                }
                const customPrice = document.getElementById('customPrice').value;
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/reject-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.PLACEMENT_GAMES,
                    order_price: customPrice === '' ? null : customPrice  // Pass the custom price to the backend
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Order has been moved back to pending.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }
    
    if(!order) return(
        <>
            <div className='pending-order placement-games'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='pending-order placement-games'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Placement Games</h1>
                        <div className='container'>
                            <div className='rank-card'>
                                <h2>Previous Rank</h2>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${order.previous_rank}_Emblem.png`)}/>
                                <h3>{order.previous_rank}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container'>
                                <h2>Number of Games</h2>
                                <h3>{order.num_games}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => confirmOrder()}>Confirm</button>
                            <button id='pending-accept' onClick={() => rejectOrder()}>Reject</button>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                            <button id='pending-accept' onClick={() => setAccountInfo(true)}>Account</button>
                        </div>
                        <button id='pending-accept' onClick={() => setUpdate(true)}>Edit</button>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order placement-games'>
                                <h1>Current Games {numGames}</h1>
                                <Slider 
                                    defaultValue={order.games_played} 
                                    step={1} 
                                    min={0} 
                                    max={order.num_games} 
                                    onChange={(event, newValue) => {
                                        setNumGames(newValue);
                                    }}
                                />
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <VerifyBody order={order} info={info}/>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}