
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext.js';

import axios from 'axios';

import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import DropDown from '../../../../../components/DropDown.js';
import { SelectorContainer, SelectorButton } from '../../../../../components/Selector.js';

import BoosterSelector from '../Components/BoosterSelector.js';
import CurrentPrice from '../Components/CurrentPrice.js';
import ChangeStatus from '../Components/ChangeStatus.js';
import AccountInformation from '../Components/AccountInformation.js';
import PendingBody from '../Components/PendingBody.js';
import Slider from '@mui/material/Slider';
import Popup from 'reactjs-popup';

export default function PendingNetWinBoost() {

    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = useState(null);
    
    const [update, setUpdate] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [booster, setBooster] = useState(false);
    const [status, setStatus] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);

    const [numGames, setNumGames] = useState(0);
    const [rank, setRank] = useState(0);
    const [division, setDivision] = useState(0);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.NET_WINS,
                order_status: ORDER_STATUS.PENDING
            }
        }).then(response => {
            let order = response.data.userData;
            setOrder(order);
            setNumGames(order?.current_net_wins??0);
            setRank(order?.current_rank??'Iron');
            setDivision(order?.current_division??4);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }
    
    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.current_net_wins === numGames && order.current_rank === rank && order.current_division === division) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }   
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.NET_WINS,
            current_net_wins: numGames,
            current_rank: rank,
            current_division: division
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }


    if(!order) return(
        <>
            <div className='pending-order net-wins'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='pending-order net-wins'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Net Wins Boost</h1>
                        <div className='container'>
                            <div className='rank-card'>
                                <h2>Current Rank</h2>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container'>
                                <h2>{order.num_net_wins} Net Wins</h2>
                            </div>
                            <div className='container'>
                                <h2>LP Gains</h2>
                                <h3>{LP_GAINS.DISPLAY(order.current_LP_gains)}</h3>
                            </div>
                        </div>
                        <div className='buttons container'>
                            <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account</button>
                        </div>
                        <div className='buttons container'>
                            <button className='massive-btn' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                        </div>
                        <Popup open={currentPrice} position="right center" closeOnEscape closeOnDocumentClick>
                            <CurrentPrice close={() => setCurrentPrice(false)} 
                                currentPrice={order.current_price} 
                                setLoading={setLoading} 
                                order_id={order.order_id}
                                boost_type={BOOST_TYPE.NET_WINS}
                            />
                        </Popup>
                        <Popup open={status} position="right center" closeOnEscape closeOnDocumentClick>
                            <ChangeStatus close={() => setStatus(false)} 
                                currentStatus={ORDER_STATUS.PENDING} 
                                setLoading={setLoading} 
                                order_id={order.order_id}
                                boost_type={BOOST_TYPE.NET_WINS}
                            />
                        </Popup>
                        <Popup open={booster} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setBooster(false)}>
                            <div className='update-order net-wins'>
                                <BoosterSelector close={() => setBooster(false)} 
                                    order_id={order.order_id} 
                                    boost_type={BOOST_TYPE.NET_WINS} 
                                    setLoading={setLoading}
                                />
                            </div>
                        </Popup>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order net-wins'>
                                <h1>Current Rank</h1>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${rank}_Emblem.png`)} className='rank-updater-emblem'/>
                                <DropDown elements={ranks} defaultValue={rank} onChange={setRank}/>
                                <SelectorContainer currentValue={division} setCurrentValue={(value) => setDivision(value)}>
                                    <SelectorButton value={4}>IV</SelectorButton>
                                    <SelectorButton value={3}>III</SelectorButton>
                                    <SelectorButton value={2}>II</SelectorButton>
                                    <SelectorButton value={1}>I</SelectorButton>
                                </SelectorContainer>
                                <h1>Current Net Wins {numGames}</h1>
                                <Slider 
                                    defaultValue={order.current_net_wins} 
                                    step={1} 
                                    min={-order.num_net_wins*2} 
                                    max={order.num_net_wins} 
                                    onChange={(event, newValue) => {
                                        setNumGames(newValue);
                                    }}
                                />
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <div className='buttons container'>
                                <button className='massive-btn' onClick={() => setCurrentPrice(true)}>Price</button>
                                <button className='massive-btn' onClick={() => setStatus(true)}>Status</button>
                                <button className='massive-btn' onClick={() => setBooster(true)}>Booster</button>
                                <button className='massive-btn' onClick={() => setUpdate(true)}>Progress</button>
                            </div>
                        </div>
                        <PendingBody order={order}/>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}