
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import InputField from '../../../../../components/InputField.js';

import { useAuth } from '../../../../../contexts/AuthContext.js';

import { useEffect } from 'react';

import axios from 'axios';

export default function CoachEarnings({currentEarnings, close, setLoading, order_id}) {

    const [price, setPrice] = React.useState(currentEarnings);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login'); 
            close();
        }
    }, [])

    function confirmPrice() {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + `/admin/update-booster-earnings/${order_id}`, {
            price: price,
            boost_type: 'coaching'
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            close();
        })
    }

    return(
        <div className={'boost-conclude'}>
            <FontAwesomeIcon icon={faXmark} className='rank-updater-close' onClick={() => close()}/>
            <h1>Coaching Earnings</h1>
            <hr className='separator'/>
            <div className='rank-updater-lp'> 
                <InputField type='number' key={1} placeholder='coaching earnings' onChange={setPrice} value={price} min={0}/>
            </div>
            <hr className='separator'/>
            <div className='rank-updater-btns'>
                <button className='rank-updater-cancel' onClick={() => close()}>Cancel</button>
                <button className='rank-updater-update' onClick={() => confirmPrice()}>Confirm</button>
            </div>
        </div>
    );
}