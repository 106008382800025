
import React, { useState, useEffect } from 'react'
import { NavContainer, NavButton } from '../../components/Navigation.js';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { booster } from '../../components/tables';
import Table from '../../components/Table';
import Loading from "../../components/Loading";

import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import './CSS/Payments.css';

export default function Payments() {

    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    
    const { panel, status } = useParams();
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    const [selected, setSelected] = useState([]);

    const [total, setTotal] = useState(0);

    useEffect(() => {
        if(!status) return;
        setSelected([]);
        setTotal(0);
        setOrders([]);
        setTable(booster.payments[status]);
    }, [status]);

    const { getAccessToken } = useAuth(); 
    
    useEffect(() => {
        if (page === undefined || panel !== 'payments') return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/boost/payment', {
            headers: { 
                Authorization: getAccessToken() 
            },
            params: {
                order_status: status,
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setOrders([]);
        });
        setSelected([]);
        setTotal(0);
    }, [page, status]);

    const calculatePrice = (selected) => {
        let price = 0;
        let select = [];
        selected.forEach((order) => {
            price += order.earnings;
            select.push({order_id: order.order_id, order_type: order.order_type});
        });
        price = price.toFixed(2);
        setTotal(price);
        setSelected(select);
    }

    const requestPayment = () => {
        if(total < 50) {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'You need to have at least $50 USD to request a cashout.',
            })
            return;
        }
        MySwal.fire({
            title: `Cashout Request`,
            html: `
            <div class="swal2-content">
                <p>Enter your paypal username/email to request a cashout.</p>
                <input id="swal-input1" class="swal2-input" placeholder="Paypal Username/Email">
            </div>
                `,
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('swal-input1').value
                ]
            }
        }).then((result) => {
            if(result.value) {
                const paypal = result.value[0];
                MySwal.fire({
                    title: 'Are you sure?',
                    html: `
                    <p>You are about to request a cashout of $${total} USD.</p>
                    <p>Paypal: ${paypal}</p>
                    `,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, request it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post(process.env.REACT_APP_API_URL + '/boost/payment-request', { 
                            orders: selected,
                            paypal: paypal
                        }, {
                            headers: { 
                                Authorization: getAccessToken() 
                            }
                        }).then(response => {
                            MySwal.fire({
                                icon: 'success',
                                title: 'Cashout Requested!',
                                text: 'Your request has been sent.',
                            }).then(() => {
                                window.location.reload();
                            }).catch(error => {
                                MySwal.fire({
                                    icon: 'error',
                                    title: 'Error!',
                                    text: 'There was an error making your cashout request.',
                                })
                            });
                        }).catch(error => {
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error!',
                                text: 'There was an error making your cashout request.',
                            })
                        });
                    }
                });
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'You need to enter a paypal username/email.',
                })
            }
        });
        
    }

    return(
        <> 
            <NavContainer panel={status} onClick={(status) => navigate(`/boost-panel/payments/${status}`)}>
                <NavButton value='available'>Available</NavButton>
                <NavButton value='requested'>Requested</NavButton>
                <NavButton value='paid'>Paid</NavButton>
            </NavContainer>   
            {status === 'available'&&<div className='cash-out'>
                <p>
                    <span>Selected:</span> ${total}
                </p>
                <button className={'normal'} onClick={requestPayment}>Request</button>
            </div>}
            {table&&<Table header={table.header} details={table.details} data={orders} page={page} setPage={setPage} select={status==='available'} selected={calculatePrice} />}
            <Loading loading={loading} panel={true}/>
        </>
    );
}