
import React from 'react'
import { Addons, Roles, Champions } from '../../../../../components/GeneralContainers.js';

export default function CancelledBody({order}) {

    return(
        <div className='body'>
            <div className='container'>
                <div className='card'>
                    <h2>Order ID</h2>
                    <p>{order.order_id}</p>
                </div>
                <div className='card'>
                    <h2>Customer</h2>
                    <p>{order.username}</p>
                </div>
                <div className='card'>
                    <h2>Total Price</h2>
                    <p>${order.total_price} USD</p>
                </div>
                <div className='card'>
                    <h2>Addons</h2>
                    <Addons elements={order.addons}/>
                </div>
                <div className='card'>
                    <h2>Roles</h2>
                    <Roles elements={order.roles}/>
                </div>
                <div className='card'>
                    <h2>Champions</h2>
                    <Champions elements={order.champions}/>
                </div>
                <div className='card break-word'>
                    <h2>Notes</h2>
                    <p>{order.notes}</p>
                </div>
            </div>
        </div>
    );
}