
import React from 'react'
import './CSS/Footer.css';
import logo from '../assets/Logos/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faXTwitter, faInstagram, faDiscord } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';


export default function Footer() {


    const MySwal = require('sweetalert2');
    const [jobApplication, setJobApplication] = React.useState(false);

    const handleDiscordClick = () => {
        axios.get(`${process.env.REACT_APP_DISCORD}`).then(response => {
            const discord_invite = response.data.instant_invite;
            window.open(discord_invite, '_blank');
        })
        .catch(error => {
            console.error('Error fetching Discord invite:', error);
        });
    }

    if(jobApplication) {
        axios.get(`${process.env.REACT_APP_DISCORD}`).then(response => {
            const discord_invite = response.data.instant_invite;
            MySwal.fire({
                title: 'Job Application',
                html: `
                    <div style="font-size: 20px; font-family:Nunito;">
                        <p>Thank you for your interest in joining our team!</p>
                        <ol style="margin-left: 20px;">
                            <li style="text-align:left;">Join our <a href="${discord_invite}" style="text-decoration:none; color:var(--main);" target="_blank">Discord</a> server.</li>
                            <li style="text-align:left;">Go to our <a href="https://discord.com/channels/1124901189104975912/1212056688178372648" style="text-decoration:none; color:var(--main);" target="_blank">#start-application</a> channel.</li>
                            <li style="text-align:left;">Follow the instructions in the channel to proceed with your application.</li>
                        </ol>
                        <p>We look forward to reviewing your application!</p>
                    </div>
                `,
                icon: 'info',
                confirmButtonText: 'Ok',
            }).then(() => {
                setJobApplication(false);
            });
        });
    }

    return(
        <footer> 
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-item logo">
                        <img id='footer-logo' src={logo} alt="EloFactory Logo" width={180} height={180} loading='lazy'/>
                        <div className='social-media'>
                            <a href="https://www.facebook.com/elofactorygg" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                            <a href="https://x.com/elofactorygg" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
                            <a href="https://www.instagram.com/elofactory.gg" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="#" onClick={(e) => { e.preventDefault(); handleDiscordClick(); }}><FontAwesomeIcon icon={faDiscord} /></a>
                        </div>
                    </div>
                    <div className="footer-items">
                        <div className="footer-item">
                            <h3>EloFactory</h3>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/terms-of-service`}>Terms of Service</a>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/privacy-policy`}>Privacy Policy</a>
                            <a onClick={() => setJobApplication(true)}>Looking for a job?</a>
                            <a href="#" onClick={(e) => { e.preventDefault(); handleDiscordClick(); }}>Questions? Join our discord!</a>
                        </div>
                        <div className="footer-item">
                            <h3>League of Legends</h3>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/get-boosting/division-boosting`}>Division Boost</a>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/get-boosting/placement-games`}>Placement Boost</a>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/get-boosting/net-wins`}>Net Boost</a>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/get-boosting/normal-games`}>Normal Boost</a>
                            <a href={`${process.env.REACT_APP_CLIENT_URL}/get-coaching/mixed`}>Coaching</a>
                        </div>
                        <div className="footer-item">
                            <h3>Coming Soon</h3>
                            <a className='disabled'>Valorant</a>
                            <a className='disabled'>Teamfight Tactics</a>
                            <a className='disabled'>Wild Rift</a>
                            <a className='disabled'>Legends of Runeterra</a>
                        </div>
                    </div>
                </div>
                <hr className='footer-divider' />
                <div className="footer-copyright-disclaimer">
                    <p>
                    We are an independent entity and not affiliated with any game publishers or developers. References to their trademarks are for descriptive purposes only, and we do not claim affiliation, endorsement, or sponsorship.
                    </p>
                    <p>© 2023-2024 EloFactory.gg™</p>
                </div>
            </div>
            
        </footer>
    );
}