
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import ProgressBar from "@ramonak/react-progress-bar";

export default function PendingCoaching() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    const MySwal = withReactContent(Swal);

    const navigate = useNavigate();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/user/coaching-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
            const conversionTracked = localStorage.getItem(response.data.order_id);
            if(!conversionTracked){
                window.gtag('event', 'manual_event_PURCHASE', {
                    'send_to': 'G-VDQCZRHSJG',
                    'value': response.data.total_price,
                    'currency': 'USD',
                    'transaction_id': response.data.order_id
                });
                localStorage.setItem(response.data.order_id, true);
            }
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    if(!order) return(
        <>
            <div className='pending-order coaching'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order coaching'>
                <div className='header'>
                    <h1>Coaching</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Coach Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.coach_rank}_Emblem.png`)}/>
                            <h3>{order.coach_rank}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Your Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.users_rank}_Emblem.png`)}/>
                            <h3>{order.users_rank}</h3>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container'>
                            <h2>Region</h2>
                            <h3>{order.server}</h3>
                        </div>
                        <div className='container'>
                            <h2>Language</h2>
                            <h3>{order.language}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>Total Price</h2>
                            <h3>${order.total_price.toFixed(2)} USD</h3>
                        </div>
                        <div className='card'>
                            <h2>Co-Pilot Hours</h2>
                            <h3>{order.copilot_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Vod-Review Hours</h2>
                            <h3>{order.vod_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>1v1 Hours</h2>
                            <h3>{order.battle_hours}</h3>
                        </div>
                        <div className='card'>
                            <h2>Roles</h2>
                            <Roles elements={order.roles}/>
                        </div>
                        <div className='card'>
                            <h2>Champions</h2>
                            <Champions elements={order.champions}/>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}