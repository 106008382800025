
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from '../../../contexts/AuthContext';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Loading from '../../../components/Loading';
import DropDown from '../../../components/DropDown.js';

import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import { BOOST_TYPE } from '../../../constants/database_values.js';

export default function UserInformation() {

    const { boost_type: user_id } = useParams();
    const { getAccessToken } = useAuth();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)

    const [loading, setLoading] = useState(true);
    const [ign, setIgn] = useState('');
    const [rank, setRank] = useState('');
    const [server, setServer] = useState('');
    const [isBooster, setIsBooster] = useState('No');
    const [isCoach, setIsCoach] = useState('No');
    const [discord, setDiscord] = useState('');
    const [cut, setCut] = useState(0);
    const [displayName, setDisplayName] = useState('');
    const [username, setUsername] = useState('');

    const [userInfo, setUserInfo] = useState(null);
    const [orders, setOrders] = useState(null);
    const [table, setTable] = useState(null);
    const [page, setPage] = useState(1);
    const [orderType, setOrderType] = useState(null);

    useEffect(() => {
        if(!user_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/user/' + user_id, {
            headers: {
                Authorization: getAccessToken()
            },
        }).then(response => {
            setUserInfo(response.data);
            setIgn(response.data.ign);
            setRank(response.data.rank);
            setServer(response.data.server);
            setIsBooster(response.data.is_booster ? 'Yes' : 'No');
            setIsCoach(response.data.is_coach ? 'Yes' : 'No');
            setDiscord(response.data.discord);
            setCut(response.data.cut);
            setDisplayName(response.data.display_name);
            setUsername(response.data.username);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, []);

    const handleSave = async function() { 
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-user/' + user_id, {
            ign: ign,
            rank: rank,
            server: server,
            is_booster: isBooster === 'Yes' ? true : false,
            is_coach: isCoach === 'Yes' ? true : false,
            discord: discord,
            cut: cut,
            display_name: displayName,
        }, {
            headers: {
                Authorization: getAccessToken()
            },
        }).then(response => {
            setLoading(false);
            MySwal.fire({
                icon: 'success',
                title: 'Account Information Saved!',
                text: 'Account information has been updated.',
            }).then(() => {
                navigate(`/admin-panel/users/all`);
                window.location.reload();
            });
        }).catch(error => {
            console.error(error);
            setLoading(false);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Unexpected error occured.',
            })
        });
    }

    const handleWorkOrders = async function() { 
        setLoading(true);
        setOrderType('worker-orders');
        axios.get(process.env.REACT_APP_API_URL + '/admin/worker-orders/' + user_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setTable(admin.orders['worker']);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Unexpected error occured.',
            })
        });
    }
      
    const handleOrders = async function() { 
        setLoading(true);
        setOrderType('orders');
        axios.get(process.env.REACT_APP_API_URL + '/admin/orders/' + user_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setTable(admin.orders['customer']);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Unexpected error occured.',
            })
        });
    }

    useEffect(() => {
        if(!orders) return;
        if(!orderType) return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + `/admin/${orderType}/${user_id}`, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Unexpected error occured.',
            })
        });
    }, [page]);

    const handleSelected = async function(object) {
        const { order_id, order_type, status } = object;
        if(order_type === 'coaching') {
            navigate(`/admin-panel/coaching/${status}/${order_id}`);
            window.location.reload();
            return;
        } else {
            if(BOOST_TYPE.IS_VALID(order_type)) {
                navigate(`/admin-panel/boosting/${status}/${BOOST_TYPE.CONVERT_TO_URL(order_type)}/${order_id}`);
                window.location.reload();
                return;
            }
        }
    }
    
    if(loading) return(
        <>
            <div className='account-information'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <Loading loading={loading} panel={true}/>
            {orders&&table&&<Table header={table.header} details={table.details} data={orders} onClick={(object) => handleSelected(object)} page={page} setPage={setPage}/>}
            {!orders &&
            <div className='account-information'>
                <div className='header'>
                    <h1>{username}'s Account Information</h1>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>In Game Name</h2>
                            <input type='text' value={ign??''} placeholder='In Game Name' onChange={(e) => setIgn(e.target.value)}/>
                        </div>
                        <div className='card'>
                            <h2>Rank</h2>
                            <input type='text' value={rank??''} placeholder='Rank' onChange={(e) => setRank(e.target.value)}/>
                        </div>
                        <div className='card'>
                            <h2>Server</h2>
                            <input type='text' value={server??''} placeholder='Server' onChange={(e) => setServer(e.target.value)}/>
                        </div>
                        <div className='card'>
                            <h2>Is Booster</h2>
                            <DropDown elements={isBooster ? ['Yes', 'No'] : ['No', 'Yes']} onChange={setIsBooster} defaultValue={isBooster}/>
                        </div>
                        <div className='card'>
                            <h2>Is Coach</h2>
                            <DropDown elements={['Yes', 'No']} onChange={setIsCoach} defaultValue={isCoach}/>
                        </div>
                        <div className='card'>
                            <h2>Discord</h2>
                            <input type='text' value={discord??''} placeholder='Discord' onChange={(e) => setDiscord(e.target.value)}/>
                        </div>  
                        <div className='card'>
                            <h2>Cut</h2>
                            <input type='number' value={cut??0} placeholder='Cut' onChange={(e) => setCut(e.target.value)}/>
                        </div>
                        <div className='card'>
                            <h2>Display Name</h2>
                            <input type='text' value={displayName??''} placeholder='Display Name' onChange={(e) => setDisplayName(e.target.value)}/>
                        </div>
                    </div>
                    <div className='container'>
                        <button className='big-btn' onClick={() => {navigate(`/admin-panel/users/all`)}}>Back</button>
                        {(userInfo?.is_booster || userInfo?.is_coach) && <button className='big-btn' onClick={() => handleWorkOrders()}>Work Orders</button>}
                        <button className='big-btn' onClick={() => handleOrders()}>Customer Orders</button>
                        <button className='big-btn' onClick={() => handleSave()}>Save</button>
                    </div>
                </div>
            </div>}
        </>
    );
}