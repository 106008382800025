
import React, { useState, useEffect } from 'react'

import axios from 'axios';
import ProgressBar from "@ramonak/react-progress-bar";
import { useAuth } from '../../../../../contexts/AuthContext.js';
import Popup from 'reactjs-popup';
import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP, LP_GAINS } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';

import BoosterEarnings from '../Components/BoosterEarnings.js';
import ChangeStatus from '../Components/ChangeStatus.js';
import AccountInformation from '../Components/AccountInformation.js';
import RankUpdater from '../Components/RankUpdater.js';
import ChangePaidStatus from '../Components/ChangePaidStatus.js';

import CompletedBody from '../Components/CompletedBody.js';

export default function CompletedDivisionBoost() {

    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = useState(null);
    const [info, setInfo] = useState(null);
    
    const [update, setUpdate] = useState(false);
    const [boosterEarnings, setBoosterEarnings] = useState(false);
    const [status, setStatus] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);
    const [paid, setPaid] = useState(false);

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                order_status: ORDER_STATUS.COMPLETED
            }
        }).then(response => {
            let order = response.data.userData;
            let info = response.data.infoData;
            if(info) {
                setInfo(info);
            }
            setOrder(order);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division){
            case 1:
                return rank + ' I';
            case 2:
                return rank + ' II';
            case 3:
                return rank + ' III';
            case 4:
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function updateOrder(current_rank, current_division, current_LP) {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.DIVISION_BOOSTING,
            current_rank, 
            current_division, 
            current_LP
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    if(!order) return(
        <>
            <div className='active-order division-boost'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order division-boost'>
                <div className='header'>
                    <h1>Division Boost</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Start</h2>
                            <img id='emblem' src={require(`../../../../../assets/Emblems/${order.initial_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.initial_rank, order.initial_division)}</h3>
                            <h3>{LP.DISPLAY(order.initial_LP)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Current</h2>
                            <img id='emblem' src={require(`../../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                            <h3>{LP.DISPLAY(order.current_LP)} LP</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Objective</h2>
                            <img id='emblem' src={require(`../../../../../assets/Emblems/${order.desired_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.desired_rank, order.desired_division)}</h3>
                            <h3>{LP_GAINS.DISPLAY(order.LP_gains)} (Gains)</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setBoosterEarnings(true)}>Booster Earnings</button>
                        <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account</button>
                        <button className='massive-btn' onClick={() => setPaid(true)}>Paid</button>
                    </div>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setStatus(true)}>Status</button>
                        <button className='massive-btn' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                        <button className='massive-btn' onClick={() => setUpdate(true)}>Progress</button>
                    </div>
                    <Popup open={paid} position="right center" closeOnEscape closeOnDocumentClick>
                        <ChangePaidStatus close={() => setPaid(false)} 
                            currentStatus={info?.is_paid} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.DIVISION_BOOSTING}
                        />
                    </Popup>
                    <Popup open={boosterEarnings} position="right center" closeOnEscape closeOnDocumentClick>
                        <BoosterEarnings close={() => setBoosterEarnings(false)} 
                            currentEarnings={info?.booster_earnings} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.DIVISION_BOOSTING}
                        />
                    </Popup>
                    <Popup open={status} position="right center" closeOnEscape closeOnDocumentClick>
                        <ChangeStatus close={() => setStatus(false)} 
                            currentStatus={ORDER_STATUS.COMPLETED} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.DIVISION_BOOSTING}
                        />
                    </Popup>
                    <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                        <AccountInformation order_id={order_id} setLoading={setLoading}/>
                    </Popup>
                    <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                        <RankUpdater close={() => setUpdate(false)}
                            current_rank={order.current_rank} 
                            current_division={order.current_division} 
                            current_LP={order.current_LP} 
                            update={updateOrder}
                        />
                    </Popup>
                </div>
                <CompletedBody order={order} info={info}/>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}