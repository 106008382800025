
import React, { useEffect, useState } from 'react'

import { SelectorButton, SelectorContainer } from '../../../../../components/Selector.js';
import { EMBLEMS } from '../../../../../constants/colors.js';
import DropDown from '../../../../../components/DropDown.js';
import { LP as LP_CONSTANT } from '../../../../../constants/database_values.js';

export default function CurrentSelector({update}) {

    const [rank, setRank] = useState('Silver');
    const [LP, setLP] = useState(LP_CONSTANT.LOW.VALUE);
    const [division, setDivision] = useState(1);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    useEffect(() => {
        if(rank === 'Diamond' && division === 1) {
            setDivision(2);
            update({rank, division: 2, LP});
            return;
        }
        update({rank, division, LP});
    }, [LP, division, rank]);

    return(
        <div className={'rank-selector current'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
            <h2>Current Rank</h2>
            <img id='emblem' src={require(`../../../../../assets/Emblems/${rank}_Emblem.png`)}/>
            <DropDown elements={ranks} onChange={setRank} defaultValue={rank}/>
            <SelectorContainer setCurrentValue={setDivision} currentValue={division}>
                <SelectorButton value={4}>IV</SelectorButton>
                <SelectorButton value={3}>III</SelectorButton>
                <SelectorButton value={2}>II</SelectorButton>
                <SelectorButton value={1} disabled={rank === 'Diamond'}>I</SelectorButton>
            </SelectorContainer>
            <SelectorContainer setCurrentValue={setLP} currentValue={LP}>
                <SelectorButton value={LP_CONSTANT.LOW.VALUE}>{LP_CONSTANT.LOW.BUTTON_DISPLAY}</SelectorButton>
                <SelectorButton value={LP_CONSTANT.MEDIUM.VALUE}>{LP_CONSTANT.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                <SelectorButton value={LP_CONSTANT.HIGH.VALUE}>{LP_CONSTANT.HIGH.BUTTON_DISPLAY}</SelectorButton>
            </SelectorContainer>
            <p>Current LP</p>
        </div>
    );
}