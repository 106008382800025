
import React from 'react'

import Coaching from './Generate/Coaching.js';

import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import Checkout from '../../../components/Checkout.js';

export default function Generate() {

    const { status } = useParams();
    const [view, setView] = React.useState(<></>);
    const [data, setData] = React.useState({});

    useEffect(() => {
        if(status !== 'generate') {
            return;
        }
        setView(<Coaching setData={setData}/>);
    }, []);


    return(
        <div className='order-now'>
            <div className='order-details'>
                <div className='order-details-content'>
                    <div className='order-details-nav' style={{margin:0}}>
                        <h1>Generate Coaching</h1>
                    </div>
                    {view}
                </div>
            </div>
            <Checkout panel={'Coaching'} data={data} admin={true}/>
        </div>
    );
}
