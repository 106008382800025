import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../contexts/AuthContext.js';

export default function BoosterSelector({ order_id, close, boost_type, setLoading }) {

    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState(null);
    const [previous, setPrevious] = useState(null); 
    const [filteredBoosters, setFilteredBoosters] = useState([]);
    const [boostersData, setBoostersData] = useState([]);

    const { getAccessToken } = useAuth();


    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/booster-list', {
            headers: {
                Authorization: getAccessToken()
            }
        }).then(response => {
            setBoostersData(response.data);
            setFilteredBoosters(response.data);
            axios.get(process.env.REACT_APP_API_URL + '/admin/current-booster/' + order_id, {
                headers: {
                    Authorization: getAccessToken()
                },
                params: {
                    boost_type
                }
            }).then(response => {
                setSelected(response.data);
                setPrevious(response.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.error(error);
            });
        }).catch(error => {
            setLoading(false);
            console.error(error);
        });
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login'); 
        }
    }, [])

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = boostersData.filter((booster) =>
            booster.username.toLowerCase().includes(term)
        );
        setFilteredBoosters(filtered);
    };

    function save() {
        setLoading(true);
        if(selected === previous){
            setLoading(false);
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/admin/change-booster', {
            order_id: order_id,
            booster_id: selected.user_id,
            boost_type
        }, {
            headers: {
                Authorization: getAccessToken()
            }
        }).then(response => {
            setLoading(false);
            window.location.reload();
        }).catch(error => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <div className='champions-selector-container'>
            <h2>Change Booster</h2>
            <div className='champions-search'>
                <input
                    type='text'
                    placeholder='Search for a booster...'
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <button>Search</button>
            </div>
            <div className='champion-selector'>
                {filteredBoosters.map((booster) => {
                    return (
                        <p
                            key={booster.user_id}
                            className={`${selected?.username === booster.username ? 'selected' : ''}`}
                            onClick={() => setSelected(booster)}
                        >
                            {booster.username}
                        </p>
                    );
                })}
                {filteredBoosters.length === 0 && <h3>No boosters found</h3>}
            </div>
            <div className='rank-updater-btns'>
                <button className='rank-updater-cancel' onClick={() => {close()}}>Cancel</button>
                <button className='rank-updater-update' onClick={() => {save()}}>Save</button>
            </div>
        </div>
    );
}
