
import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function InputField({placeholder, onChange, value, min, type, icon, error}) {

    const [focus, setFocus] = React.useState(false);
    const [currentValue, setCurrentValue] = React.useState(value ? value : '');

    React.useEffect(() => {
        if(value && value !== currentValue){
            setCurrentValue(value);
        }
    }, [value]);

    React.useEffect(() => {
        if(min !== undefined && typeof min === 'number' && !isNaN(parseFloat(min)) && type === 'number') {
            if(currentValue === '') {
                onChange(0);
                return;
            }
            if(min > currentValue || isNaN(parseFloat(currentValue))) {
                setCurrentValue(min);
                onChange(parseFloat(min));
                return;
            }
            onChange(parseFloat(currentValue));
            return;
        }
        onChange(currentValue);
    }, [currentValue]);

    return(
        <div className={`input-field${icon ? ' icon' : ''}${error ? ' error' : ''}${focus ? ' focus' : ''}`}>
            {icon&&<FontAwesomeIcon icon={icon} />}
            <input type={type} placeholder={placeholder} onChange={(value) => setCurrentValue(value.target.value)} 
                    value={currentValue} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}
            />
        </div>
    );
}