
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { ORDER_STATUS } from '../../../constants/database_values';
import { customer } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";

import ActiveCoaching from './CustomerDetails/ActiveCoaching';

export default function Status() {

    const navigate = useNavigate();
    const { panel, status, order_id } = useParams();
    const [orders, setOrders] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    useEffect(() => {
        if(!status) return;
        if(!ORDER_STATUS.IS_VALID(status)) return;
        setTable(customer.coaching[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if (order_id || !status || page === undefined || panel !== 'coaching') return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/user/coaching-orders', {
            headers: { 
                Authorization: getAccessToken() 
            },
            params: {
                order_status: status,
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        if(status === ORDER_STATUS.COMPLETED) return;
        navigate(`/my-orders/coaching/${status}/${selected.order_id}`);
    }, [selected]);

    useEffect(() => {
        if (!order_id) return;
        setLoading(false);
        if(status === ORDER_STATUS.ACTIVE)
            setSelectedView(<ActiveCoaching />);
    }, [order_id, status]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={orders} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}