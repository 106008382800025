
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Addons, Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ProgressBar from "@ramonak/react-progress-bar";
import Conclude from './Components/Conclude.js';

import DropDown from '../../../../components/DropDown.js';
import { SelectorContainer, SelectorButton } from '../../../../components/Selector.js';

import AccountInformation from './Components/AccountInformation.js';

import Slider from '@mui/material/Slider';

export default function PendingNetWinBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [update, setUpdate] = useState(false);
    const [conclude, setConclude] = useState(false);
    const [numGames, setNumGames] = useState(0);
    const [rank, setRank] = useState('Iron');
    const [division, setDivision] = useState(4);
    const [accountInfo, setAccountInfo] = useState(false);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/boost/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.NET_WINS,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setNumGames(response.data?.current_net_wins??0);
            setRank(response.data?.current_rank??'Iron');
            setDivision(response.data?.current_division??4);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function concludeOrder() {
        axios.put(process.env.REACT_APP_API_URL + `/boost/conclude-boost-order/${BOOST_TYPE.NET_WINS}/${order.order_id}`, {  }, { 
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            navigate('/boost-panel/boosting/completed');
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            alert('Error concluding order');
        })
    }

    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.current_net_wins === numGames) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/boost/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.NET_WINS,
            current_net_wins: numGames,
            current_rank: rank,
            current_division: division
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(!update){
            setNumGames(order?.current_net_wins??0);
            setRank(order?.current_rank??'Iron');
            setDivision(order?.current_division??4);
            document.getElementById('popup-root').classList.remove('popup-login');
            return;
        }
        
        document.getElementById('popup-root').classList.add('popup-login')
    }, [update]);

    if(!order) return(
        <>
            <div className='active-order net-wins'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order net-wins'>
                <div className='header'>
                    <h1>Net Wins Boost</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Initial Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.initial_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.initial_rank, order.initial_division)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Current Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container'>
                            <h2>{order.num_net_wins} Net Wins</h2>
                        </div>
                        <div className='container'>
                            <h2>LP Gains</h2>
                            <h3>{LP_GAINS.DISPLAY(order.current_LP_gains)}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setUpdate(true)}>Update Order</button>
                        <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account Information</button>
                        <button className='massive-btn' onClick={() => setConclude(true)}>Conclude Order</button>
                        <Popup open={conclude} position="right center" closeOnEscape closeOnDocumentClick>
                            <Conclude close={() => setConclude(false)} confirm={() => concludeOrder()}/>
                        </Popup>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                            <div className='update-order net-wins'>
                            <h1>Current Rank</h1>
                                <img id='emblem' src={require(`../../../../assets/Emblems/${rank}_Emblem.png`)} className='rank-updater-emblem'/>
                                <DropDown elements={ranks} defaultValue={rank} onChange={setRank}/>
                                <SelectorContainer currentValue={division} setCurrentValue={(value) => setDivision(value)}>
                                    <SelectorButton value={4}>IV</SelectorButton>
                                    <SelectorButton value={3}>III</SelectorButton>
                                    <SelectorButton value={2}>II</SelectorButton>
                                    <SelectorButton value={1}>I</SelectorButton>
                                </SelectorContainer>
                                <h1>Current Net Wins {numGames}</h1>
                                <Slider 
                                    defaultValue={order.current_net_wins} 
                                    step={1} 
                                    min={-order.num_net_wins*2} 
                                    max={order.num_net_wins} 
                                    onChange={(event, newValue) => {
                                        setNumGames(newValue);
                                    }}
                                />
                                <div className='rank-updater-btns'>
                                    <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                    <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className='body'>
                    <div className='card'>
                        <h2>Earnings</h2>
                        <h3>${order.earnings.toFixed(2)} USD</h3>
                    </div>
                    <div className='card'>
                        <h2>Addons</h2>
                        <Addons elements={order.addons}/>
                    </div>
                    <div className='card'>
                        <h2>Roles</h2>
                        <Roles elements={order.roles}/>
                    </div>
                    <div className='card'>
                        <h2>Champions</h2>
                        <Champions elements={order.champions}/>
                    </div>
                    <div className='card break-word'>
                        <h2>Notes</h2>
                        <p>{order.notes}</p>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}