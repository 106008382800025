
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type: user_id } = useParams();
    const [payments, setPayments] = useState([]);
    const [selected, setSelected] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    const MySwal = withReactContent(Swal);


    useEffect(() => {
        if(!status) return;
        setTable(admin.payments[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if (!status || page === undefined || panel !== 'payments') return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/payments', {
            headers: { 
                Authorization: getAccessToken() 
            },          
            params: { 
                status: status, 
                page: page 
            }
        }).then(response => {
            setPayments(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if(selected) {
            MySwal.fire({
                title: `${selected.worker}'s Payment`,
                html: `
                <p>Confirmation that you sent $${selected.total} USD to ${selected.paypal}</p>
            `,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonColor: '#3085d6',
                cancelButtonText: 'No, cancel!',
                confirmButtonText: 'Yes, change status!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(process.env.REACT_APP_API_URL + '/admin/authorize-payment', {
                        id: selected.id
                    }, {
                        headers: { 
                            Authorization: getAccessToken() 
                        }
                    }).then(response => {
                        MySwal.fire({
                            title: 'Payment Authorized!',
                            icon: 'success'
                        }).then(() => {
                            setSelected(null);
                            window.location.reload();
                        });
                    }).catch(error => {
                        console.error(error);
                        MySwal.fire({
                            title: 'Error!',
                            text: 'There was an error authorizing the payment.',
                            icon: 'error'
                        });
                    });
                } else {
                    setSelected(null);
                }
            });
        }
    }, [selected]);

    return (
        <>
            {table&&<Table header={table.header} details={table.details} data={payments} onClick={(object) => {if(status === 'completed'){return} setSelected(object)}} page={page} setPage={setPage}/>}
            <Loading loading={loading} panel={true}/>
        </>
    );
}