
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Addons, Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE } from '../../../../constants/database_values.js';

import { useParams, useNavigate } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";

export default function ActivePlacementBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/user/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.PLACEMENT_GAMES,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
            const conversionTracked = localStorage.getItem(response.data.order_id);
            if(!conversionTracked){
                window.gtag('event', 'manual_event_PURCHASE', {
                    'send_to': 'G-VDQCZRHSJG',
                    'value': response.data.total_price,
                    'currency': 'USD',
                    'transaction_id': response.data.order_id
                });
                localStorage.setItem(response.data.order_id, true);
            }
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    if(!order) return(
        <>
            <div className='active-order placement-games'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order placement-games'>
                <div className='header'>
                    <h1>Placement Games</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Previous Rank</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.previous_rank}_Emblem.png`)}/>
                            <h3>{order.previous_rank}</h3>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container'>
                            <h2>Number of Games</h2>
                            <h3>{order.num_games}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <button id='pending-accept' onClick={() => {navigate(`/my-orders/boosting/account-information/placement-games/${order.order_id}`)}}>Account Information</button>
                </div>
                <div className='body'>
                    <div className='card'>
                        <h2>Total Price</h2>
                        <h3>${order.total_price.toFixed(2)} USD</h3>
                    </div>
                    <div className='card'>
                        <h2>Addons</h2>
                        <Addons elements={order.addons}/>
                    </div>
                    <div className='card'>
                        <h2>Roles</h2>
                        <Roles elements={order.roles}/>
                    </div>
                    <div className='card'>
                        <h2>Champions</h2>
                        <Champions elements={order.champions}/>
                    </div>
                    <div className='card break-word'>
                        <h2>Notes</h2>
                        <p>{order.notes}</p>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}