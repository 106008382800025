
const { BOOST_TYPE } = require('../constants/database_values');

export const customer = {
    'boosting': {
        'pending': {
            'header': ['Order ID', 'Purchase Date', 'Server', 'Queue Type', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.server, object.queue_type, '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Purchase Date', 'Server', 'Queue Type', 'Progress', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10),
                    object.server, object.queue_type, Math.max(object.progress, 0)  + '%', '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Purchase Date', 'Completed Date', 'Server', 'Queue Type', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10), 
                    object.server, object.queue_type, '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
    },
    'coaching': {
        'pending': {
            'header': ['Order ID', 'Purchase Date', 'Coach Rank', 'Language', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.coach_rank, object.language, '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Purchase Date', 'Coach Rank', 'Language', 'Progress', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10),
                    object.coach_rank, object.language, Math.max(object.progress, 0)  + '%', '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Purchase Date', 'Completed Date', 'Coach Rank', 'Language', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10), 
                    object.coach_rank, object.language, '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
    }
};

export const booster = {
    'boosting': {
        'pending': {
            'header': ['Order ID', 'Creation Date', 'Server', 'Earnings', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.server, '$' + object.earnings.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Started', 'Server', 'Progress', 'Earnings', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.server,
                    object.progress + '%', '$' + object.earnings.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Started', 'Completed', 'Earnings', 'Boost Type', 'Verified', 'Paid'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),  
                    '$' + object.earnings.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type), 
                    object.is_verified ? 'Verified' : 'Pending', (object.is_paid ? 'Paid' : 'Pending')
                ];
            }
        },
    },
    'coaching': {
        'pending': {
            'header': ['Order ID', 'Creation Date', 'Coach Rank', 'Users Rank', 'Language', 'Earnings'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.coach_rank, object.users_rank, object.language, '$' + object.earnings.toFixed(2) + ' USD'
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Creation Date', 'Coach Rank', 'Users Rank', 'Language', 'Progress', 'Earnings'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10),
                    object.coach_rank, object.users_rank, object.language, Math.max(object.progress, 0)  + '%', '$' + object.earnings.toFixed(2) + ' USD'
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Creation Date', 'Completed Date', 'Earnings', 'Verified', 'Paid'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),
                    '$' + object.earnings.toFixed(2) + ' USD', 
                    object.is_verified ? 'Verified' : 'Pending', (object.is_paid ? 'Paid' : 'Pending')
                ];
            }
        },
    },
    'payments': {
        'available': {
            'header': ['Order ID', 'Order Type', 'Finished At', 'Earnings'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.order_type === 'coaching' ? 'Coaching' : BOOST_TYPE.CONVERT(object.order_type), 
                    object.concluded_at.substring(0,10), '$' + object.earnings.toFixed(2) + ' USD'
                ];
            }
        },
        'requested': {
            'header': ['Tracker ID', 'Paypal', 'Amount', 'Requested At'],
            'details': (object) => {
                return [
                    object.id.substring(24), object.paypal, '$' + object.total.toFixed(2) + ' USD', object.created_at.substring(0,10)
                ];
            }
        },
        'paid': {
            'header': ['Tracker ID', 'Paypal', 'Amount', 'Requested At', 'Paid At'],
            'details': (object) => {
                return [
                    object.id.substring(24), object.paypal, '$' + object.total.toFixed(2) + ' USD', object.created_at.substring(0,10), object.concluded_at.substring(0,10)
                ];
            }
        },
    }
};


export const admin = {
    'boosting': {
        'pending': {
            'header': ['Order ID', 'Creation Date', 'Server', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.server, '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Started', 'Server', 'Progress', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.server,
                    object.progress + '%', '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Started', 'Finished', 'Total', 'Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),
                    '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)

                ];
            }
        },
        'cancelled': {
            'header': ['Order ID', 'Started', 'Server', 'Progress', 'Total', 'Boost Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.server,
                    object.progress + '%', '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
        'verify': {
            'header': ['Order ID', 'Started', 'Finished', 'Worker Earnings', 'Net Profit', 'Total', 'Type'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),  
                    '$' + object.worker_earnings.toFixed(2) + ' USD', '$' + object.net_profit.toFixed(2) + ' USD', 
                    '$' + object.total_price.toFixed(2) + ' USD', BOOST_TYPE.CONVERT(object.boost_type)
                ];
            }
        },
    },
    'coaching': {
        'pending': {
            'header': ['Order ID', 'Creation Date', 'Coach Rank', 'Users Rank', 'Language', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), 
                    object.coach_rank, object.users_rank, object.language, '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'active': {
            'header': ['Order ID', 'Creation Date', 'Coach Rank', 'Users Rank', 'Language', 'Progress', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10),
                    object.coach_rank, object.users_rank, object.language, Math.max(object.progress, 0)  + '%', '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'completed': {
            'header': ['Order ID', 'Started', 'Finished', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),
                    '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'cancelled': {
            'header': ['Order ID', 'Creation Date', 'Coach Rank', 'Users Rank', 'Language', 'Progress', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10),
                    object.coach_rank, object.users_rank, object.language, Math.max(object.progress, 0)  + '%', '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        },
        'verify': {
            'header': ['Order ID', 'Started', 'Finished', 'Worker Earnings', 'Net Profit', 'Total'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.created_at.substring(0,10), object.concluded_at.substring(0,10),  
                    '$' + object.worker_earnings.toFixed(2) + ' USD', '$' + object.net_profit.toFixed(2) + ' USD', 
                    '$' + object.total_price.toFixed(2) + ' USD'
                ];
            }
        }
    },
    'users': {
        'all': {
            'header': ['Email', 'Joined', 'Admin', 'Booster', 'Coach'],
            'details': (object) => {
                return [
                    object.email, object.created_at.substring(0,10), 
                    object.is_admin ? 'Yes' : 'No', object.is_booster ? 'Yes' : 'No', object.is_coach ? 'Yes' : 'No'
                ];
            }
        },
        'boosters': {
            'header': ['Email','IGN', 'Server', 'Rank', 'Cut', 'Completed Boosts', 'Boost Earnings'],
            'details': (object) => {
                return [
                    object.email, object.ign, object.server, object.rank, object.cut + '%', object.boosts_completed, '$' + object.booster_earnings.toFixed(2) + ' USD'
                ];
            }
        },
        'coaches': {
            'header': ['Email', 'IGN', 'Server', 'Rank', 'Cut', 'Completed Boosts', 'Coaching Earnings'],
            'details': (object) => {
                return [
                    object.email, object.ign, object.server, object.rank, object.cut + '%', object.coachings_completed, '$' + object.coaching_earnings.toFixed(2) + ' USD'
                ];
            }
        },
    },
    'orders': {
        'customer': {
            'header': ['Order ID', 'Customer', 'Purchased', 'Order Type', 'Progress', 'Total', 'Status'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.username, object.created_at.substring(0,10),
                    object.display_type, Math.max(object.progress, 0)  + '%', '$' + object.total_price.toFixed(2) + ' USD',
                    object.status
                ];
            }
        },
        'worker': {
            'header': ['Order ID', 'Customer', 'Worker', 'Purchased', 'Started', 'Order Type', 'Progress', 'Worker Earnings', 'Total', 'Status'],
            'details': (object) => {
                return [
                    object.order_id.substring(24), object.username, object.worker, object.created_at.substring(0,10), object.started_at.substring(0,10), ,
                    object.display_type, Math.max(object.progress, 0)  + '%', '$' + object.worker_earnings.toFixed(2) + ' USD', '$' + object.total_price.toFixed(2) + ' USD',
                    object.status
                ];
            }
        }
    },
    'payments': {
        'pending': {
            'header': ['Worker', 'Total', 'Paypal', 'Requested At'],
            'details': (object) => {
                return [
                    object.worker, '$' + object.total.toFixed(2) + ' USD', object.paypal, object.created_at.substring(0,10)
                ];
            }
        },
        'completed': {
            'header': ['Worker', 'Total', 'Paypal', 'Requested At', 'Paid At'],
            'details': (object) => {
                return [
                    object.worker, '$' + object.total.toFixed(2) + ' USD', object.paypal, object.created_at.substring(0,10), object.concluded_at.substring(0,10)
                ];
            }
        }
    },
    'coupons': {
        'coupon': {
            'header': ['Name', 'Discount', 'Expiration', 'Single Use', 'Active'],
            'details': (object) => {
                return [
                    object.name, Math.max(object.discount, 0)  + '%', object.expires_at?.substring(0,10)??'Never',
                    object.is_single_use ? 'Yes' : 'No', object.is_active ? 'Yes' : 'No'
                ];
            }
        },
    },
};