
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";
import CouponInformation from "./CouponInformation";

import Generate from "./Generate";

export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type: name } = useParams();
    const [coupons, setCoupons] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    useEffect(() => {
        if(!panel && panel === 'coupons') return;
        setTable(admin.coupons['coupon']);
    }, []);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(status === 'search') {
            setLoading(true);
            axios.get(process.env.REACT_APP_API_URL + '/admin/coupon-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    name: name,
                    page: page
                }
            }).then(response => {
                setCoupons(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
    }, [name]);


    useEffect(() => {
        if(status === 'search') {
            setLoading(true);
            axios.get(process.env.REACT_APP_API_URL + '/admin/coupon-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    name: name,
                    page: page
                }
            }).then(response => {
                setCoupons(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
        if (name || !status || page === undefined || panel !== 'coupons') return;
        if(status === 'generate') {
            setLoading(false);
            return;
        }
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/coupons', {
            headers: { 
                Authorization: getAccessToken() 
            },          
            params: {
                page: page
            }
        }).then(response => {
            setCoupons(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        navigate(`/admin-panel/coupons/all/${selected.name}`);
    }, [selected]);

    useEffect(() => {
        if (!name)  {
            setLoading(false);
            setSelectedView(null);
            setSelected(null);
            return;
        }
        if(status === 'search') return;
        setLoading(false);
        setSelectedView(<CouponInformation />);
    }, [name, status]);

    useEffect(() => {
        if(status === 'generate') {
            setSelectedView(<Generate />);
            setLoading(false);
            return;
        }
    }, [status]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={coupons} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}