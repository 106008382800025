
import React from 'react';
import LandingPage from './LandingPage.js';
import splashart from '../assets/splashart.jpg';

import { Helmet } from 'react-helmet';

import './CSS/Accordion.css';
import './CSS/Home.css';

export default function Home() {

    const helmetTags = require('../constants/helmet-tags.json');

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.not_found.title}</title>
                <meta name="description" content={helmetTags.not_found.description}/>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className='hero' style={{backgroundImage: `url(${splashart})`}}>
                <div className='hero-content'>
                    <div className='hero-content-overlay'>
                        <h1>ERROR 404</h1>
                        <h2>PAGE NOT FOUND</h2>
                        <p>
                            The page you are looking for does not exist. Please check the URL or go back to the home page.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}