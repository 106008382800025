
import React from 'react'

export function SelectorContainer({children, currentValue, setCurrentValue}) {

    return(
        <div className='selector-container'>
            {children.map((child, index) => {
                return React.cloneElement(child, {currentValue, setCurrentValue, key: index});
            })}
        </div>
    );
}

export function SelectorButton({children, setCurrentValue, currentValue, value, disabled}) {

    return(
        <button className={`selector-button ${currentValue==value ? 'active' : 'inactive'} ${disabled ? 'disabled' : ''}`} 
                onClick={() => disabled ? null : setCurrentValue(value)} >{children}</button>
    );
}