
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/AuthContext.js';

import axios from 'axios';

import { Addons, Champions, Roles } from '../../../../components/GeneralContainers.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS, LP } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function PendingDivisionBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/boost/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                order_status: ORDER_STATUS.PENDING
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Order not found.',
            }).then(() => {
                navigate('/boost-panel/boosting/pending');
                setLoading(false);
            });
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function acceptOrder() {
        MySwal.fire({
            title: 'Do you want to accept this order?',
            showCancelButton: true,
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/boost/accept-boost-order', { order_id: order.order_id, boost_type: BOOST_TYPE.DIVISION_BOOSTING }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Order Accepted!',
                        text: 'Good luck.',
                    }).then(() => {
                        navigate('/boost-panel/boosting/active/division-boosting/' + order_id);
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: error.response.data.error??'Make sure you only have two active orders at a time.',
                    })
                    console.error(error);
                });
            }
        })
    }

    if(!order) return(
        <>
            <div className='pending-order division-boost'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='pending-order division-boost'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Division Boosting</h1>
                        <div className='container'>
                            <div className='rank-card'>
                                <h2>Current Rank</h2>
                                <img id='emblem' src={require(`../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                            </div>
                            <div className='rank-card'>
                                <h2>Desired Rank</h2>
                                <img id='emblem' src={require(`../../../../assets/Emblems/${order.desired_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.desired_rank, order.desired_division)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container'>
                                <h2>Current LP</h2>
                                <h3>{LP.DISPLAY(order.current_LP)}</h3>
                            </div>
                            <div className='container'>
                                <h2>LP Gains</h2>
                                <h3>{LP_GAINS.DISPLAY(order.LP_gains)}</h3>
                            </div>
                        </div>
                        <button id='pending-accept' onClick={() => acceptOrder()}>Accept</button>
                    </div>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>Earnings</h2>
                            <h3>${order.earnings.toFixed(2)} USD</h3>
                        </div>
                        <div className='card'>
                            <h2>Addons</h2>
                            <Addons elements={order.addons}/>
                        </div>
                        <div className='card'>
                            <h2>Roles</h2>
                            <Roles elements={order.roles}/>
                        </div>
                        <div className='card'>
                            <h2>Champions</h2>
                            <Champions elements={order.champions}/>
                        </div>
                        <div className='card break-word'>
                            <h2>Notes</h2>
                            <p>{order.notes}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}