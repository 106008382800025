
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { ORDER_STATUS, BOOST_TYPE } from '../../../constants/database_values';
import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";

import PendingDivisionBoost from './BoostDetails/DivisionBoosting/PendingDivisionBoost';
import ActiveDivisionBoost from './BoostDetails/DivisionBoosting/ActiveDivisionBoost';
import VerifyDivisionBoost from './BoostDetails/DivisionBoosting/VerifyDivisionBoost';
import CompletedDivisionBoost from "./BoostDetails/DivisionBoosting/CompletedDivisionBoost";
import CancelledDivisionBoost from "./BoostDetails/DivisionBoosting/CancelledDivisionBoost";

import PendingNetWinBoost from "./BoostDetails/NetWins/PendingNetWinBoost";
import ActiveNetWinBoost from "./BoostDetails/NetWins/ActiveNetWinBoost";
import VerifyNetWinBoost from "./BoostDetails/NetWins/VerifyNetWinBoost";
import CompletedNetWinBoost from "./BoostDetails/NetWins/CompletedNetWinBoost";
import CancelledNetWinBoost from "./BoostDetails/NetWins/CancelledNetWinBoost";

import PendingPlacementBoost from "./BoostDetails/PlacementGames/PendingPlacementBoost";
import ActivePlacementBoost from "./BoostDetails/PlacementGames/ActivePlacementBoost";
import VerifyPlacementBoost from "./BoostDetails/PlacementGames/VerifyPlacementBoost";
import CompletedPlacementBoost from "./BoostDetails/PlacementGames/CompletedPlacementBoost";
import CancelledPlacementBoost from "./BoostDetails/PlacementGames/CancelledPlacementBoost";

import PendingNormalBoost from "./BoostDetails/NormalGames/PendingNormalBoost";
import ActiveNormalBoost from "./BoostDetails/NormalGames/ActiveNormalBoost";
import VerifyNormalBoost from "./BoostDetails/NormalGames/VerifyNormalBoost";
import CompletedNormalBoost from "./BoostDetails/NormalGames/CompletedNormalBoost";
import CancelledNormalBoost from "./BoostDetails/NormalGames/CancelledNormalBoost";

import Generate from "./Generate";

export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type, order_id } = useParams();
    const [orders, setOrders] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    useEffect(() => {
        if(!status) return;
        if(!ORDER_STATUS.IS_VALID(status)) return;
        setTable(admin.boosting[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(status === 'search') {
            setTable(admin.boosting[ORDER_STATUS.PENDING]);
            setLoading(true);
            axios.get(process.env.REACT_APP_API_URL + '/admin/boost-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    order_id: boost_type,
                    page: page
                }
            }).then(response => {
                setOrders(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
        if (order_id || boost_type || !status || page === undefined || panel !== 'boosting') return;
        if(status === 'generate') {
            setLoading(false);
            return;
        }
        setLoading(true);
        if(status === 'verify') {
            axios.get(process.env.REACT_APP_API_URL + '/admin/boost-pending-verification', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    page: page
                }
            }).then(response => {
                setOrders(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
            return;
        }
        if(!ORDER_STATUS.IS_VALID(status)) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-orders', {
            headers: { 
                Authorization: getAccessToken() 
            },          
            params: {
                order_status: status,
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        switch(selected.boost_type) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                navigate(`/admin-panel/boosting/${status === 'search' ? selected.order_status : status}/division-boosting/${selected.order_id}`)
                break;
            case BOOST_TYPE.PLACEMENT_GAMES:
                navigate(`/admin-panel/boosting/${status === 'search' ? selected.order_status : status}/placement-games/${selected.order_id}`)
                break;
            case BOOST_TYPE.NET_WINS:
                navigate(`/admin-panel/boosting/${status === 'search' ? selected.order_status : status}/net-wins/${selected.order_id}`)
                break;
            case BOOST_TYPE.NORMAL_GAMES:
                navigate(`/admin-panel/boosting/${status === 'search' ? selected.order_status : status}/normal-games/${selected.order_id}`)
                break;
            default:
                break;
        }
    }, [selected]);

    useEffect(() => {
        if (!boost_type && !order_id) return;
        if(status === 'search') return;
        setLoading(false);
        switch(boost_type) {
            case 'division-boosting':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingDivisionBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveDivisionBoost />);
                else if(status === ORDER_STATUS.COMPLETED)
                    setSelectedView(<CompletedDivisionBoost />);
                else if(status === ORDER_STATUS.CANCELLED)
                    setSelectedView(<CancelledDivisionBoost />);
                else if(status === ORDER_STATUS.VERIFY)
                    setSelectedView(<VerifyDivisionBoost />);
                break;
            case 'placement-games':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingPlacementBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActivePlacementBoost />);
                else if(status === ORDER_STATUS.COMPLETED)
                    setSelectedView(<CompletedPlacementBoost />);
                else if(status === ORDER_STATUS.CANCELLED)
                    setSelectedView(<CancelledPlacementBoost />);
                else if(status === ORDER_STATUS.VERIFY)
                    setSelectedView(<VerifyPlacementBoost />);
                break;
            case 'net-wins':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingNetWinBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveNetWinBoost />);
                else if(status === ORDER_STATUS.COMPLETED)
                    setSelectedView(<CompletedNetWinBoost />);
                else if(status === ORDER_STATUS.CANCELLED)
                    setSelectedView(<CancelledNetWinBoost />);
                else if(status === ORDER_STATUS.VERIFY)
                    setSelectedView(<VerifyNetWinBoost />);
                break;
            case 'normal-games':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingNormalBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveNormalBoost />);
                else if(status === ORDER_STATUS.COMPLETED)
                    setSelectedView(<CompletedNormalBoost />);
                else if(status === ORDER_STATUS.CANCELLED)
                    setSelectedView(<CancelledNormalBoost />);
                else if(status === ORDER_STATUS.VERIFY)
                    setSelectedView(<VerifyNormalBoost />);
                break;
            default:
                break;
        }
    }, [boost_type, order_id, status]);

    useEffect(() => {
        if(status === 'generate') {
            setSelectedView(<Generate />);
            setLoading(false);
            return;
        }
    }, [status]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={orders} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}