
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from '../../../../../contexts/AuthContext';
import * as jose from 'node-jose';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Loading from '../../../../../components/Loading';

export default function AccountInformation() {

    const { order_id, boost_type } = useParams();
    const { getAccessToken } = useAuth();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)

    const [loading, setLoading] = useState(true);
    const [ign, setIgn] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [tag, setTag] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const [isDuoBoost, setIsDuoBoost] = useState(false);

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/user/account-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
        }).then(response => {
            setIgn(response.data.encrypted_information?.ign);
            setUsername(response.data.encrypted_information?.username);
            setPassword(response.data.encrypted_information?.password);
            setTag(response.data.encrypted_information?.tag);
            setNotes(response.data.notes);
            setStatus(response.data.status);
            setIsDuoBoost(response.data.is_duo_boost);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    const handleSave = async function() { 
        const algorithm = 'RSA-OAEP-256';
        if(!ign || (!isDuoBoost && (!username || !password))) {
            MySwal.fire({
                icon: 'warning',
                title: 'Missing fields!',
                text: 'Please enter your account information.',
            })
            return;
        }
        if(!isDuoBoost && password !== confirmPassword) {
            MySwal.fire({
                icon: 'warning',
                title: 'Passwords do not match!',
                text: 'Please make sure your passwords match.',
            })
            return;
        }
        const unescapedDataToEncrypt = {
            ign: ign,
            username: username,
            password: password, // Password can be empty
            tag: tag, // Tag can be empty
        };
        const dataToEncrypt = JSON.stringify(unescapedDataToEncrypt); // Convert to JSON
        try {
            const publicKeyObject = await jose.JWK.asKey(process.env.REACT_APP_SMURFGG_PK, 'pem');
            const encryptedData = await jose.JWE.createEncrypt({ format: 'compact', alg: algorithm, enc: 'A256GCM'  }, publicKeyObject).update(new TextEncoder('utf-8').encode(dataToEncrypt)).final();
            axios.post(process.env.REACT_APP_API_URL + '/user/account-information/' + order_id, {
                encrypted_information: encryptedData,
                notes: notes
            }, {
                headers: {
                    Authorization: getAccessToken()
                },
            }).then(response => {
                MySwal.fire({
                    icon: 'success',
                    title: 'Account Information Saved!',
                    text: 'Your account information has been encrypted for protection.',
                })
                navigate(`/my-orders/boosting/active/${boost_type}/${order_id}`);
            }).catch(error => {
                console.error(error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Unexpected error occured.',
                })
            });
        } catch (error) {
          console.error(error);
        }
    }
      
    
    
    if(loading) return(
        <>
            <div className='account-information'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <Loading loading={loading} panel={true}/>
            <div className='account-information'>
                <div className='header'>
                    <h1>Account Information</h1>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>In Game Name</h2>
                            <input type='text' value={ign??''} placeholder='In Game Name' onChange={(e) => setIgn(e.target.value)} maxLength={20}/>
                        </div>
                        <div className='card'>
                            <h2>#TAG</h2>
                            <input type='text' value={tag??''} placeholder='#TAG' onChange={(e) => setTag(e.target.value)} maxLength={10}/>
                        </div>
                        {!isDuoBoost &&<div className='card'>
                            <h2>Username</h2>
                            <input type='text' value={username??''} placeholder='Username' onChange={(e) => setUsername(e.target.value)}/>
                        </div>}
                        {!isDuoBoost &&
                        <div className='card'>
                            <h2>Password</h2>
                            <input type='password' value={password??''} placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                        </div>}
                        {!isDuoBoost &&
                        <div className='card'>
                            <h2>Confirm Password</h2>
                            <input type='password' value={confirmPassword??''} placeholder='Password' onChange={(e) => setConfirmPassword(e.target.value)}/>
                        </div>}
                        <div className='card'>
                            <h2>Notes</h2>
                            <input type='text' value={notes??''} placeholder='Notes' onChange={(e) => setNotes(e.target.value)} maxLength={500}/>
                        </div>
                    </div>
                    <div className='container'>
                        <button className='big-btn' onClick={() => {navigate(`/my-orders/boosting/active/${boost_type}/${order_id}`)}}>Back</button>
                        <button className='big-btn' onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    );
}