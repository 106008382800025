
import React from 'react'

import DivisionBoost from './Generate/DivisionBoost.js';
import PlacementBoost from './Generate/PlacementBoost.js';
import WinBoost from './Generate/WinBoost.js';
import NormalGames from './Generate/NormalGames.js';

import { useEffect } from 'react';
import { NavButton, NavContainer } from '../../../components/Navigation.js';

import { useParams, useNavigate } from 'react-router-dom';
import { BOOST_TYPE } from '../../../constants/database_values.js';
import Checkout from '../../../components/Checkout.js';

export default function Generate() {

    const { boost_type, status } = useParams();
    const [view, setView] = React.useState(<></>);
    const [data, setData] = React.useState({});

    const navigate = useNavigate();


    useEffect(() => {
        if(status !== 'generate') {
            return;
        }
        if(!boost_type) {
            navigate('/admin-panel/boosting/generate/division-boosting');
            return;
        }
        switch(BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                setView(<DivisionBoost key={boost_type} setData={setData}/>);
                break;
            case BOOST_TYPE.PLACEMENT_GAMES:
                setView(<PlacementBoost key={boost_type} setData={setData}/>);
                break;
            case BOOST_TYPE.NET_WINS:
                setView(<WinBoost key={boost_type} setData={setData}/>);        
                break;
            case BOOST_TYPE.NORMAL_GAMES:
                setView(<NormalGames key={boost_type} setData={setData}/>);
                break;
            default:
                setView(<></>);
                break;
        }
    }, [boost_type]);


    return(
        <div className='order-now'>
            <div className='order-details'>
                <div className='order-details-content'>
                    <div className='order-details-nav' style={{margin:0}}>
                        <NavContainer panel={BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)} onClick={(select) => navigate(`/admin-panel/boosting/generate/${BOOST_TYPE.CONVERT_TO_URL(select)}`)}>
                            <NavButton value={(BOOST_TYPE.DIVISION_BOOSTING)}>
                                Division Boosting
                            </NavButton>
                            <NavButton value={(BOOST_TYPE.PLACEMENT_GAMES)}>
                                Placement Games
                            </NavButton>
                            <NavButton value={(BOOST_TYPE.NET_WINS)}>
                                Net Wins
                            </NavButton>
                            <NavButton value={(BOOST_TYPE.NORMAL_GAMES)}>
                                Normal Games
                            </NavButton>
                        </NavContainer>
                    </div>
                    {view}
                </div>
            </div>
            <Checkout key={boost_type} panel={BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)} data={data} admin={true}/>
        </div>
    );
}
