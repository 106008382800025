
import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS, LP_GAINS } from '../../../../constants/database_values.js';
import { EMBLEMS } from '../../../../constants/colors.js';
import { TIMEOUT } from '../../../../constants/misc.js';

import DropDown from '../../../../components/DropDown.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';

export default function WinBoost({setData}) {

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald'];

    const [rank, setRank] = useState('Platinum');
    const [division, setDivision] = useState(4);
    const [LPGains, setLPGains] = useState(LP_GAINS.LOW.VALUE);
    const [queueType, setQueueType] = useState('Solo/Duo');
    const [server, setServer] = useState('NA');
    const [numNetWins, setNumNetWins] = useState(4);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState([]);
    const [champions, setChampions] = useState([]);
    const [roles, setRoles] = useState([]);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            rank,
            division,
            LPGains,
            numNetWins,
            queueType,
            server,
            addons,
            champions,
            roles,
            price
        });
    }, [rank, division, LPGains, numNetWins, queueType, server, addons, champions, roles, price]);

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    useEffect(() => {
        if(rank === null || division === null || LPGains === null || numNetWins === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-net-wins-boost', { 
            rank, 
            numNetWins, 
            division, 
            LPGains, 
            addons
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [rank, division, LPGains, addons]);

    useEffect(() => {
        if(rank === null || division === null || LPGains === null || numNetWins === null){
            return;
        }
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-net-wins-boost', { 
                rank, 
                numNetWins, 
                division, 
                LPGains, 
                addons
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [numNetWins]);
    
    return(
        <>
            <div className='placements container'>
                <div className={'rank-selector current'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
                    <h2>Current Rank</h2>
                    <img id='emblem' src={require(`../../../../assets/Emblems/${rank}_Emblem.png`)}/>
                    <DropDown elements={ranks} onChange={setRank} defaultValue={rank}/>
                    <SelectorContainer setCurrentValue={setDivision} currentValue={division}>
                        <SelectorButton value={4}>IV</SelectorButton>
                        <SelectorButton value={3}>III</SelectorButton>
                        <SelectorButton value={2}>II</SelectorButton>
                        <SelectorButton value={1}>I</SelectorButton>
                    </SelectorContainer>
                    <SelectorContainer setCurrentValue={setLPGains} currentValue={LPGains}>
                        <SelectorButton value={LP_GAINS.LOW.VALUE}>{LP_GAINS.LOW.BUTTON_DISPLAY}</SelectorButton>
                        <SelectorButton value={LP_GAINS.MEDIUM.VALUE}>{LP_GAINS.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                        <SelectorButton value={LP_GAINS.HIGH.VALUE}>{LP_GAINS.HIGH.BUTTON_DISPLAY}</SelectorButton>
                    </SelectorContainer>
                    <p>LP Gains</p>
                </div>
                <div className='division-boosting-selectors placements'>
                    <div className='division-boosting-selector'>
                        <h2>Queue Type</h2>
                        <SelectorContainer setCurrentValue={setQueueType} currentValue={queueType}>
                            <SelectorButton value={'Solo/Duo'}>Solo/Duo</SelectorButton>
                            <SelectorButton value={'Flex'}>Flex</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='division-boosting-selector'>
                        <h2>Region</h2>
                        <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                            <SelectorButton value={'NA'}>NA</SelectorButton>
                            <SelectorButton value={'LAN'}>LAN</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='placements-game-number'>
                        <h2>{numNetWins} Net Wins</h2>
                        <Slider 
                            defaultValue={4} 
                            step={1} 
                            min={1} 
                            max={5} 
                            onChange={(event, newValue) => {
                                setNumNetWins(newValue);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='division-boosting-addons'>
                <h2>Addons</h2>
                <div className='ordering-status-buttons'>
                    {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                        if(ADDONS[addon].title === 'Bonus Win'){
                            return;
                        }
                        return(
                            <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                        )}
                    )}
                </div>
                {addons.includes('Specific Roles')&& <RoleSelector selectedRoles={setRoles}/>}
                {addons.includes('Specific Champions')&& <ChampionSelector selectedChampions={setChampions}/>}
            </div>
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}