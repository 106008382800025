
import React, { useEffect } from 'react'

import SignIn from './NavBar/SignIn.js';

import Checkout from '../components/Checkout.js';
import { COACH_TYPE } from '../constants/database_values.js';

import { useParams, useNavigate } from 'react-router-dom';

import { NavButton, NavContainer } from '../components/Navigation.js';

import CopilotCoach from './GetCoaching/CopilotCoach.js';
import VodCoach from './GetCoaching/VodCoach.js';
import VersusCoach from './GetCoaching/VersusCoach.js';
import MixedCoach from './GetCoaching/MixedCoach.js';


export default function GetCoaching() {

    const { coach_type } = useParams();
    const [view, setView] = React.useState(<></>);

    const navigate = useNavigate();

    const [showLogin, setShowLogin] = React.useState(false);
    const [data, setData] = React.useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(!coach_type) return;
        switch(COACH_TYPE.URL_TO_COACH_TYPE(coach_type)) {
            case COACH_TYPE.COPILOT:
                setView(<CopilotCoach key={coach_type} setData={(data) => {
                                    let currData = {...data};
                                    currData.battleHours = 0;
                                    currData.vodHours = 0;
                                    setData(currData);
                                }
                            } previousData={data}/>);
                break;
            case COACH_TYPE.VOD:
                setView(<VodCoach key={coach_type} setData={(data) => {
                                    let currData = {...data};
                                    currData.copilotHours = 0;
                                    currData.battleHours = 0;
                                    setData(currData);
                                }
                            } previousData={data}/>);
                break;
            case COACH_TYPE.VERSUS:
                setView(<VersusCoach key={coach_type} setData={(data) => {
                                    let currData = {...data};
                                    currData.copilotHours = 0;
                                    currData.vodHours = 0;
                                    setData(currData);
                                }
                            } previousData={data}/>);
                break;
            case COACH_TYPE.MIXED:
                setView(<MixedCoach key={coach_type} setData={(data) => {
                                    let currData = {...data};
                                    currData.battleHours = 0;
                                    setData(currData);
                                }
                            } previousData={data}/>);
                break;
            default:
                setView(<></>);
                break;
        }
    }, [coach_type]);

    return(
        <div className='order-now'>
            <div className='background-img' style={{backgroundImage: `url(${require('../assets/splashart.jpg')})`}}/>
            <div className='order-details'>
                <div className='order-details-content'>
                    <div className='order-details-nav'>
                        <NavContainer panel={COACH_TYPE.URL_TO_COACH_TYPE(coach_type)} onClick={(select) => navigate(`/get-coaching/${COACH_TYPE.CONVERT_TO_URL(select)}`)}>
                            <NavButton value={(COACH_TYPE.MIXED)}>
                                Mixed
                            </NavButton>
                            <NavButton value={(COACH_TYPE.COPILOT)}>
                                Copilot
                            </NavButton>
                            <NavButton value={(COACH_TYPE.VOD)}>
                                Vod Review
                            </NavButton>
                            <NavButton value={(COACH_TYPE.VERSUS)}>
                                Versus
                            </NavButton>
                        </NavContainer>
                    </div>
                    {view}
                </div>
            </div>
            <Checkout panel='Coaching' setShowLogin={() => setShowLogin(true)} data={data}/>
            <SignIn open={showLogin} close={() => setShowLogin(false)}/>
        </div>
    );
}
