import React, { useContext, useState, useEffect, useRef } from "react"
import { useCookies } from 'react-cookie';
import supabase from "../supabase.js"
import { useNavigate } from "react-router-dom";
import { io } from 'socket.io-client';
import NotificationSound from '../assets/Sounds/notification.mp3';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {

  const navigate = useNavigate();

  const COOKIES = {
    ACCESS_TOKEN: 'access_token',
    USERNAME: 'username',
    EMAIL: 'email',
    USER_ID: 'user_id',
    PRIVACY_POLICY: 'privacy_policy',
    GET_COOKIE: (name) => {
      return cookies[name];
    }
  }

  const [cookies, setCookie, removeCookie] = useCookies([COOKIES.ACCESS_TOKEN]);
  const [loading, setLoading] = useState(true);
  const [isBooster, setIsBooster] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [acceptedCut, setAcceptedCut] = useState(false);
  const [cut, setCut] = useState(0);
    
  const socketRef = useRef();
  const audioPlayer = useRef();

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      switch (_event) {
        case "INITIAL_SESSION":
          if(session) {
            setCookie(COOKIES.ACCESS_TOKEN, session.access_token, { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.USERNAME, session.user.email.split('@')[0], { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.EMAIL, session.user.email, { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.USER_ID, session.user.id, { path: '/', expires: new Date(session.expires_at*1000) })
            socketRef.current = io(process.env.REACT_APP_API_URL, {
              reconnection: true,
              reconnectionDelay: 1000,
              reconnectionAttempts: 3,
              auth: {
                  token: session.access_token
              }
            });
            initSocket();
            //wait for 3 seconds and if not setloading to false
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          } else {
            setLoading(false);
          }
          break;
        case "SIGNED_IN":
          //USER OPENED THE TAB AGAIN
          break;
        case "SIGNED_OUT":
          socketRef.current?.off(COOKIES.GET_COOKIE(COOKIES.USER_ID) + '-notification');
          removeCookie(COOKIES.USERNAME, { path: '/' });
          removeCookie(COOKIES.ACCESS_TOKEN, { path: '/' });
          removeCookie(COOKIES.EMAIL, { path: '/' });
          removeCookie(COOKIES.USER_ID, { path: '/' });
          socketRef.current?.disconnect();
          setIsBooster(false);
          setIsAdmin(false);
          setIsUser(false);
          setTimeout(() => {
            setLoading(false);
          }, 500);
          break;
        case "TOKEN_REFRESHED":
          if(session) {
            socketRef.current?.disconnect();
            setCookie(COOKIES.ACCESS_TOKEN, session.access_token, { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.USERNAME, session.user.email.split('@')[0], { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.EMAIL, session.user.email, { path: '/', expires: new Date(session.expires_at*1000) })
            setCookie(COOKIES.USER_ID, session.user.id, { path: '/', expires: new Date(session.expires_at*1000) })
            socketRef.current = io(process.env.REACT_APP_API_URL, {
              reconnection: true,
              reconnectionDelay: 1000,
              reconnectionAttempts: 10,
              auth: {
                  token: session.access_token
              }
            });
            initSocket();
          } else {
            setLoading(false);
          }
          break;
        case "PASSWORD_RECOVERY":
          setIsUser(true);
          break;
        default:
          setLoading(false);
          break;
      }
      //console.log(_event);
    })
    return () => {
      subscription.unsubscribe();
      socketRef.current?.disconnect();
    }
  }, [])

  function initSocket() {
    socketRef.current?.on('permissions', (data) => {
      setIsBooster(data.is_booster);
      setIsAdmin(data.is_admin);
      setAcceptedCut(data.accepted_cut);
      setCut(data.cut);
      setIsUser(true);
      setLoading(false);
    });
    socketRef.current?.on('notification', (data) => {
      if(data === 'Booster') {
        audioPlayer.current?.play().catch(() => {
          //
        });
      }
      if(data === 'User') {
        audioPlayer.current?.play().catch(() => {
          //
        });
      }
      //document.dispatchEvent(new Event('notification'));
    });
    socketRef.current?.on('message', (data) => {
      document.dispatchEvent(new CustomEvent('message', { detail: {data, notify: () => {
        audioPlayer.current?.play().catch(() => {
          //
        });
      }}}
      ));
    });
  }

  async function signup(email, password) {
    const currentUrl = window.location.href;
    const { error } = await supabase.auth.signUp({ 
      email, 
      password,
      redirectTo: currentUrl
    })
    if(error) {
      console.log(error);
      return error.message;
    }
  }

  async function login(email, password) {
    const currentUrl = window.location.href;
    const { error } = await supabase.auth.signInWithPassword({ 
      email, 
      password ,
      redirectTo: currentUrl
    })
    if (error) {
      console.log(error)
      return error.message;
    }
  }

  async function googleLogin() {
    //get current website url
    const currentUrl = window.location.href;
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: currentUrl,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    });
    if (error) {
      console.log(error);
      return error.message;
    }
  }

  async function discordLogin() {
    const currentUrl = window.location.href;
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        redirectTo: currentUrl,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    });
    if (error) {
      console.log(error);
      return error.message;
    }
  }

  async function logout() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
    }
    navigate('/');
  }

  async function resetPassword(email) {
    await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: process.env.REACT_APP_CLIENT_URL + '/reset-password',
    })
  }

  async function updatePassword(password) {
    const { error } = await supabase.auth.updateUser({
      password,
    })
    if (error) {
      console.log(error);
      return error.message;
    }
  }

  const value = {
    getUsername: () => COOKIES.GET_COOKIE(COOKIES.USERNAME),
    getAccessToken: () => COOKIES.GET_COOKIE(COOKIES.ACCESS_TOKEN),
    getEmail: () => COOKIES.GET_COOKIE(COOKIES.EMAIL),
    getUserId: () => COOKIES.GET_COOKIE(COOKIES.USER_ID),
    socket: socketRef.current,
    isBooster,
    isAdmin,
    isUser,
    acceptedCut,
    cut,
    login,
    googleLogin,
    discordLogin,
    signup,
    logout,
    resetPassword,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      {!loading && !cookies[COOKIES.PRIVACY_POLICY] &&
      <div className="cookie-consent">
        <p>
        EloFactory.gg uses cookies. See our <a href="/privacy-policy">Privacy Policy</a> for more information.
        </p>
        <button onClick={() => {
          setCookie(COOKIES.PRIVACY_POLICY, 'true', { path: '/', expires: new Date(Date.now() + 31536000000) });
          document.querySelector('.cookie-consent').style.display = 'none';
        }}>Accept</button>
      </div>
      }
      <audio ref={audioPlayer} src={NotificationSound} />
    </AuthContext.Provider>
  )
}

export default AuthContext;