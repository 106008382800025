
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext.js';

import axios from 'axios';

import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup';
import ProgressBar from "@ramonak/react-progress-bar";

import ActiveBody from '../Components/ActiveBody.js';
import BoosterSelector from '../Components/BoosterSelector.js';
import CurrentPrice from '../Components/CurrentPrice.js';
import BoosterEarnings from '../Components/BoosterEarnings.js';
import ChangeStatus from '../Components/ChangeStatus.js';
import AccountInformation from '../Components/AccountInformation.js';

import Slider from '@mui/material/Slider';

export default function ActivePlacementBoost() {

    const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = useState(null);
    const [info, setInfo] = useState(null);
    
    const [update, setUpdate] = useState(false);
    const [currentPrice, setCurrentPrice] = useState(false);
    const [boosterEarnings, setBoosterEarnings] = useState(false);
    const [booster, setBooster] = useState(false);
    const [status, setStatus] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);

    const [numGames, setNumGames] = useState(0);

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.PLACEMENT_GAMES,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            let order = response.data.userData;
            let info = response.data.infoData;
            if(info) {
                setInfo(info);
            }
            setOrder(order);
            setNumGames(order?.games_played??0)
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleUpdate() {
        setUpdate(false);
        setLoading(true);
        if(order.games_played === numGames) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
            return;
        }
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.PLACEMENT_GAMES,
            games_played: numGames
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    useEffect(() => {
        if(!update){
            setNumGames(order?.games_played??0);
            document.getElementById('popup-root').classList.remove('popup-login');
            return;
        }
        
        document.getElementById('popup-root').classList.add('popup-login')
    }, [update]);

    
    if(!order) return(
        <>
            <div className='active-order placement-games'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order placement-games'>
                <div className='header'>
                    <h1>Placement Games</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Previous Rank</h2>
                            <img id='emblem' src={require(`../../../../../assets/Emblems/${order.previous_rank}_Emblem.png`)}/>
                            <h3>{order.previous_rank}</h3>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container'>
                            <h2>Number of Games</h2>
                            <h3>{order.num_games}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setBoosterEarnings(true)}>Booster Earnings</button>
                        <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account</button>
                        <button className='massive-btn' onClick={() => setCurrentPrice(true)}>Current Price</button>
                    </div>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setBooster(true)}>Booster</button>
                        <button className='massive-btn' onClick={() => setStatus(true)}>Status</button>
                        <button className='massive-btn' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                        <button className='massive-btn' onClick={() => setUpdate(true)}>Progress</button>
                    </div>
                    <Popup open={currentPrice} position="right center" closeOnEscape closeOnDocumentClick>
                        <CurrentPrice close={() => setCurrentPrice(false)} 
                            currentPrice={order.current_price} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.PLACEMENT_GAMES}
                        />
                    </Popup>
                    <Popup open={boosterEarnings} position="right center" closeOnEscape closeOnDocumentClick>
                        <BoosterEarnings close={() => setBoosterEarnings(false)} 
                            currentEarnings={info?.booster_earnings} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.PLACEMENT_GAMES}
                        />
                    </Popup>
                    <Popup open={status} position="right center" closeOnEscape closeOnDocumentClick>
                        <ChangeStatus close={() => setStatus(false)} 
                            currentStatus={ORDER_STATUS.ACTIVE} 
                            setLoading={setLoading} 
                            order_id={order.order_id}
                            boost_type={BOOST_TYPE.PLACEMENT_GAMES}
                        />
                    </Popup>
                    <Popup open={booster} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setBooster(false)}>
                        <div className='update-order net-wins'>
                            <BoosterSelector close={() => setBooster(false)} 
                                order_id={order.order_id} 
                                boost_type={BOOST_TYPE.PLACEMENT_GAMES} 
                                setLoading={setLoading}
                            />
                        </div>
                    </Popup>
                    <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                        <AccountInformation order_id={order_id} setLoading={setLoading}/>
                    </Popup>
                    <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setUpdate(false)}>
                        <div className='update-order placement-games'>
                            <h1>Current Games {numGames}</h1>
                            <Slider 
                                defaultValue={order.games_played} 
                                step={1} 
                                min={0} 
                                max={order.num_games} 
                                onChange={(event, newValue) => {
                                    setNumGames(newValue);
                                }}
                            />
                            <div className='rank-updater-btns'>
                                <button className='rank-updater-cancel' onClick={() => setUpdate(false)}>Cancel</button>
                                <button className='rank-updater-update' onClick={() => handleUpdate()}>Update</button>
                            </div>
                        </div>
                    </Popup>
                </div>
                <ActiveBody order={order} info={info}/>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}