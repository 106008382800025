
import React from 'react'
import { NavContainer, NavButton } from '../../components/Navigation.js';
import Status from './Coaching/Status.js';

import { useParams, useNavigate } from 'react-router-dom';

export default function Coaching() {

    const navigate = useNavigate();
    const { status, order_id } = useParams();
    const [view, setView] = React.useState(<></>);
    
    React.useEffect(() => {
        if(!status) {
            return;
        }
        setView(<Status key={Math.random()}/>);
    }, [status, order_id]);

    return(
        <> 
            <NavContainer panel={status} onClick={(status) => navigate(`/boost-panel/coaching/${status}`)}>
                <NavButton value='pending'>Pending</NavButton>
                <NavButton value='active'>Active</NavButton>
                <NavButton value='completed'>Completed</NavButton>
            </NavContainer>   
            {view}
        </>
    );
}
