
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext.js';

import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';

import Loading from "../../components/Loading";

export default function Messages() {
    

    const { getAccessToken, getUsername, socket } = useAuth();

    const { status: order_id, panel } = useParams();
    const navigate = useNavigate();

    const [header, setHeader] = useState(null);
    const [headers, setHeaders] = useState(null);
    const [headersUpdated, setHeadersUpdated] = useState(null);
    const [shownMessages, setShownMessages] = useState([]);
    const [newMessage, setNewMessage] = useState(null);
    const [loadable, setLoadable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cooldown, setCooldown] = useState(false);

    const messageLim = 12;

    //Load order chats

    useEffect(() => {
        const handleMessages = (event) => {
            let data = event.detail.data;
            if(data.sender === 'Admin') return;
            event.detail.notify();
            if(!order_id) return;
            if(data.order_id !== order_id) {
                setHeadersUpdated(data.order_id);
                return;
            }
            setNewMessage(data);
        }
        document.addEventListener('message', handleMessages);
        return () => {
            document.removeEventListener('message', handleMessages);
        }
    }, [order_id]);

    useEffect(() => {
        if(!order_id) return;
        if(panel !== 'messages') return;
        setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + `/admin/messages/${order_id}`, {
            messageCount: 0,
            messageLim: messageLim
        }, {
            headers: { 
                Authorization: getAccessToken() 
            }
        }).then((response) => {
            setShownMessages(response.data.messages);
            if(response.data.cooldown) {
                setCooldown(true);
                setTimeout(() => {
                    setCooldown(false);
                }, 5000);
            }
            if(response.data.messages.length < messageLim) {
                setLoadable(false);
            } else {
                setLoadable(true);
            }
            setLoading(false);
        }).catch((error) => {
            console.log(error);
            setShownMessages([]);
            setLoading(false);
        });
    }, [order_id]);

    useEffect(() => {
        if (!order_id || !headers) return;
        if (order_id === header?.order_id) return;
        const updatedHeaders = headers.map((element) => {
            if (element.order_id === order_id) {
                return { ...element, unread: false };
            }
            return element;
        });
        if(updatedHeaders === headers) return;
        setHeader(updatedHeaders.find(element => element.order_id === order_id));
    }, [order_id, headers]);

    useEffect(() => {
        if(!order_id) return;
        if(!header) {
            setHeader({header: 'Inactive Order', subtitle: order_id});
        }
    }, [header]);
        

    useEffect(() => {
        if(!headersUpdated) return;
        const updatedHeaders = headers.map((element) => {
            if (element.order_id === headersUpdated) {
                return { ...element, unread: true };
            }
            return element;
        });
        setHeadersUpdated(null);
        if(updatedHeaders === headers) return;
        setHeaders(updatedHeaders);
    }, [headersUpdated]);

    //Send message using socket.io and axios

    function sendMessage(e) {
        e.preventDefault();
        let message = document.getElementById('messages-input').value;
        if(!message) return;
        if(cooldown) return;
        setCooldown(true);
        setTimeout(() => {
            setCooldown(false);
        }, 5000);
        document.getElementById('messages-input').value = '';
        let tempShownMessages = [...shownMessages];
        tempShownMessages = tempShownMessages.reverse();
        tempShownMessages.push({ sender: getUsername(), message });
        tempShownMessages = tempShownMessages.reverse();
        setShownMessages(tempShownMessages);
        //Send message using socket.io
        socket?.emit('order-message', { 
            order_id, 
            message, 
            sender: 'Admin'
        });
        //Send message using axios
        axios.post(process.env.REACT_APP_API_URL + `/admin/send-message/${order_id}`, {
            message
        }, {
            headers: {
                Authorization: getAccessToken()
            }
        }).then((response) => { }).catch((error) => {
            console.log(error);
        });
    }

    //Recieve message using socket.io

    useEffect(() => {
        if(!newMessage) return;
        if(!order_id) return;
        let tempShownMessages = [...shownMessages];
        tempShownMessages = tempShownMessages.reverse();
        tempShownMessages.push({ sender: newMessage.sender, message: newMessage.message });
        tempShownMessages = tempShownMessages.reverse();
        setShownMessages(tempShownMessages);
    }, [newMessage]);


    //Load more messages

    function loadMessages() {
        //IMPLEMENT
        axios.post(process.env.REACT_APP_API_URL + `/admin/messages/${order_id}`, {
            messageCount: shownMessages.length,
            messageLim: messageLim
        }, {
            headers: { 
                Authorization: getAccessToken() 
            }
        }).then((response) => {
            let tempShownMessages = [...shownMessages];
            tempShownMessages = tempShownMessages.concat(response.data.messages);
            setShownMessages(tempShownMessages);
            if(response.data.messages.length !== messageLim) {
                setLoadable(false);
            } else {
                setLoadable(true);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/message-headers', {
            headers: { 
                Authorization: getAccessToken() 
            }
        }).then((response) => {
            if(!order_id) {
                navigate(`/admin-panel/messages/${response.data[0].order_id}`)
            }
            setHeaders(response.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    }, []);


    return(
        <>
        <Loading loading={loading} panel={true}/>
        <div className='messages-panel'>  
            {!headers&&!loading&&<h1>No Active Orders</h1>}
            {headers&&<><div className='container-header'>
                <div className='card'>
                    <div className='card-header'>
                        {header&&<div>
                            <h1>{header.header}</h1>
                            <p>{header.subtitle}</p> 
                        </div>}
                    </div>
                </div>
            </div>
            <div className='messages'>
                <div className='messages-header'>
                    {headers.map((element, index) => {
                        return(
                            <div 
                                key={index} 
                                className={`container${order_id === element.order_id ? ' selected' : ''}${element?.unread ? ' unread' : ''}`}
                                onClick={() => {navigate(`/admin-panel/messages/${element.order_id}`)}}
                            >
                                <div className='card'>
                                    <div className='card-header'>
                                        <h1>{element.header}</h1>
                                        <p>{element.subheader}</p>
                                    </div>
                                    {element?.subtitle?<div className='card-body'>
                                        <p>{element.subtitle}</p>
                                    </div> : <></>}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='messages-body'>
                    {order_id&&<><div className='container'>
                        {shownMessages.map((message, index) => {
                            return(
                                <div key={index} className={`card${'Booster' !== message.type ? ' sender' : ' sendee'}`}>
                                    {shownMessages[index+1]?.sender !== message.sender ? 
                                    <div className='card-header'>
                                        <p>{message.sender}</p>
                                    </div>
                                    :
                                    <></>
                                    }
                                    <div className='card-body'>
                                        <p>{message.message}</p>
                                    </div>
                                </div>
                            );
                        })}
                        {loadable&&<button id='load-msg-btn' onClick={loadMessages}>Load More</button>}
                    </div>
                    <form className='input-container' onSubmit={(e) => sendMessage(e)}>
                        <input id='messages-input' type='text' placeholder='Type a message...' autoComplete="off" maxLength={300}/>
                        <button id='messages-button' disabled={cooldown}>Send</button>
                    </form></>}
                </div>
            </div></>}
        </div>
        </>
    );
}