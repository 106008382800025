
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { useEffect } from 'react';

export default function Conclude({close, confirm}) {

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login'); 
            close();
        }
    }, [])


    return(
        <div className={'boost-conclude'}>
            <FontAwesomeIcon icon={faXmark} className='rank-updater-close' onClick={() => close()}/>
            <h1>Conclude Order</h1>
            <p>Are you sure you want to conclude this order?</p>
            <hr className='separator'/>
            <p className='justify'>If your order isn't completed you will not recieve your payment. Check our terms of service for more information.</p>
            <div className='rank-updater-btns'>
                <button className='rank-updater-cancel' onClick={() => close()}>Cancel</button>
                <button className='rank-updater-update' onClick={() => confirm()}>Conclude</button>
            </div>
        </div>
    );
}