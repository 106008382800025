
import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS } from '../../../../constants/database_values.js';
import { EMBLEMS } from '../../../../constants/colors.js';
import { TIMEOUT } from '../../../../constants/misc.js';

import DropDown from '../../../../components/DropDown.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';

export default function PlacementBoost({setData}) {

    const ranks = ['Unranked', 'Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond', 'Master'];

    const [rank, setRank] = useState('Platinum');
    const [queueType, setQueueType] = useState('Solo/Duo');
    const [server, setServer] = useState('NA');
    const [numGames, setNumGames] = useState(4);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState([]);
    const [champions, setChampions] = useState([]);
    const [roles, setRoles] = useState([]);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            rank,
            numGames,
            queueType,
            server,
            addons,
            champions,
            roles,
            price
        });
    }, [rank, numGames, queueType, server, addons, champions, roles, price]);

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    useEffect(() => {
        if(rank === null || numGames === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-placement-boost', { 
            rank,
            numGames,
            addons
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [rank, addons]);

    useEffect(() => {
        if(rank === null || numGames === null){
            return;
        }
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-placement-boost', { 
                rank,
                numGames,
                addons
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [numGames]);

    return(
        <>
            <div className='placements container'>
                <div className={'rank-selector previous'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
                    <h2>Previous Rank</h2>
                    <img id='emblem' src={require(`../../../../assets/Emblems/${rank}_Emblem.png`)}/>
                    <DropDown elements={ranks} onChange={setRank} defaultValue={rank}/>
                </div>
                <div className='division-boosting-selectors placements'>
                    <div className='division-boosting-selector'>
                        <h2>Queue Type</h2>
                        <SelectorContainer setCurrentValue={setQueueType} currentValue={queueType}>
                            <SelectorButton value={'Solo/Duo'}>Solo/Duo</SelectorButton>
                            <SelectorButton value={'Flex'}>Flex</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='division-boosting-selector'>
                        <h2>Region</h2>
                        <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                            <SelectorButton value={'NA'}>NA</SelectorButton>
                            <SelectorButton value={'LAN'}>LAN</SelectorButton>
                        </SelectorContainer>
                    </div>
                </div>
            </div>
            <div className='placements-game-number'>
                <h2>{numGames} Placement Games</h2>
                <Slider 
                    defaultValue={4} 
                    step={1} 
                    min={1} 
                    max={5} 
                    onChange={(event, newValue) => {
                        // You can handle the new value here
                        setNumGames(newValue);
                    }}
                />
            </div>
            <div className='division-boosting-addons'>
                <h2>Addons</h2>
                <div className='ordering-status-buttons'>
                    {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                        if(ADDONS[addon].title === 'Bonus Win'){
                            return;
                        }
                        return(
                            <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                        )}
                    )}
                </div>
                {addons.includes('Specific Roles')&& <RoleSelector selectedRoles={setRoles}/>}
                {addons.includes('Specific Champions')&& <ChampionSelector selectedChampions={setChampions}/>}
            </div>
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}