
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { ORDER_STATUS } from '../../../constants/database_values';
import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";

import PendingCoaching from "./CoachingDetails/PendingCoaching";
import ActiveCoaching from "./CoachingDetails/ActiveCoaching";
import VerifyCoaching from "./CoachingDetails/VerifyCoaching";
import CompletedCoaching from "./CoachingDetails/CompletedCoaching";
import CancelledCoaching from "./CoachingDetails/CancelledCoaching";

import Generate from "./Generate";

export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type: order_id } = useParams();
    const [orders, setOrders] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    useEffect(() => {
        if(!status) return;
        if(!ORDER_STATUS.IS_VALID(status)) return;
        setTable(admin.coaching[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(status === 'search') {
            setLoading(true);
            setTable(admin.coaching[ORDER_STATUS.PENDING]);
            axios.get(process.env.REACT_APP_API_URL + '/admin/coaching-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    order_id: order_id,
                    page: page
                }
            }).then(response => {
                setOrders(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
    }, [order_id]);


    useEffect(() => {
        if(status === 'search') {
            setLoading(true);
            setTable(admin.coaching[ORDER_STATUS.PENDING]);
            axios.get(process.env.REACT_APP_API_URL + '/admin/coaching-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    order_id: order_id,
                    page: page
                }
            }).then(response => {
                setOrders(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        }
        if (order_id || !status || page === undefined || panel !== 'coaching') return;
        if(status === 'generate') {
            setLoading(false);
            return;
        }
        setLoading(true);
        if(status === 'verify') {
            axios.get(process.env.REACT_APP_API_URL + '/admin/coaching-pending-verification', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    page: page
                }
            }).then(response => {
                setOrders(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
            return;
        }
        if(!ORDER_STATUS.IS_VALID(status)) {
            return;
        }
        axios.get(process.env.REACT_APP_API_URL + '/admin/coaching-orders', {
            headers: { 
                Authorization: getAccessToken() 
            },          
            params: {
                order_status: status,
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        navigate(`/admin-panel/coaching/${status === 'search' ? selected.order_status : status}/${selected.order_id}`);
    }, [selected]);

    useEffect(() => {
        if (!order_id) return;
        if(status === 'search') return;
        setLoading(false);
        if(status === ORDER_STATUS.PENDING)
            setSelectedView(<PendingCoaching />);
        else if(status === ORDER_STATUS.ACTIVE)
            setSelectedView(<ActiveCoaching />);
        else if(status === ORDER_STATUS.COMPLETED)
            setSelectedView(<CompletedCoaching />);
        else if(status === ORDER_STATUS.CANCELLED)
            setSelectedView(<CancelledCoaching />);
        else if(status === ORDER_STATUS.VERIFY)
            setSelectedView(<VerifyCoaching />);
    }, [order_id, status]);

    useEffect(() => {
        if(status === 'generate') {
            setSelectedView(<Generate />);
            setLoading(false);
            return;
        }
    }, [status]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={orders} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}