
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/AuthContext.js';

import axios from 'axios';

import Loading from '../../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS, LP } from '../../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import AccountInformation from '../Components/AccountInformation.js';
import RankUpdater from '../Components/RankUpdater.js';

import Popup from 'reactjs-popup';
import VerifyBody from '../Components/VerifyBody.js';

export default function VerifyDivisionBoost() {

    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [info, setInfo] = useState(null);

    const [update, setUpdate] = useState(false);
    const [accountInfo, setAccountInfo] = useState(false);

    const navigate = useNavigate();

    const MySwal = withReactContent(Swal);

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/admin/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                order_status: ORDER_STATUS.COMPLETED
            }
        }).then(response => {
            let order = response.data.userData;
            let info = response.data.infoData;
            if(info) {
                setInfo(info);
            }
            setOrder(order);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division, LP){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function confirmOrder() {
        MySwal.fire({
            title: 'Are you sure the order has been completed?',
            showCancelButton: true,
            confirmButtonText: 'Accept',
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/verify-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.DIVISION_BOOSTING 
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Confirmed!',
                        text: 'Order verified.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }

    function rejectOrder() {
        MySwal.fire({
            title: 'Are you sure the order was incomplete?',
            text: 'Make sure you update the order progress before continuing.',
            showCancelButton: true,
            confirmButtonText: 'Accept',
            html: `
                <input 
                    type="number" 
                    id="customPrice" 
                    placeholder="Enter custom price" 
                />
            `,
        }).then((result) => {
            if (result.isConfirmed) {
                if(order.progress >= 100) {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Order progress must be less than 100% to reject the order.',
                    })
                    return;
                }
                const customPrice = document.getElementById('customPrice').value;
                setLoading(true);
                axios.post(process.env.REACT_APP_API_URL + '/admin/reject-boost-order', { 
                    order_id: order.order_id, 
                    boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                    order_price: customPrice === '' ? null : customPrice  // Pass the custom price to the backend
                }, {
                    headers: { 
                        Authorization: getAccessToken() 
                    }
                }).then(response => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Success!',
                        text: 'Order has been moved back to pending.',
                    }).then(() => {
                        navigate('/admin-panel/boosting/verify');
                        window.location.reload();
                    })
                }).catch(error => {
                    setLoading(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'There was an error verifying the order.',
                    })
                    console.error(error);
                });
            }
        })
    }

    
    function updateOrder(current_rank, current_division, current_LP) {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + '/admin/update-progress-verify', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.DIVISION_BOOSTING,
            current_rank, 
            current_division, 
            current_LP
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    if(!order) {
        return(
            <>
                <div className='pending-order division-boost'/>
                <Loading loading={loading} panel={true}/>
            </>
        );
    }
    
    return(
        <>
            <div className='pending-order division-boost'>
                <div className='wrapper'>
                    <div className='header'>
                        <h1>Division Boosting</h1>
                        <div className='container'>
                            <div className='rank-card'>
                                <h2>Current Rank</h2>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.current_rank, order.current_division)}</h3>
                            </div>
                            <div className='rank-card'>
                                <h2>Desired Rank</h2>
                                <img id='emblem' src={require(`../../../../../assets/Emblems/${order.desired_rank}_Emblem.png`)}/>
                                <h3>{handleRank(order.desired_rank, order.desired_division)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container'>
                                <h2>Current LP</h2>
                                <h3>{LP.DISPLAY(order.current_LP)}</h3>
                            </div>
                            <div className='container'>
                                <h2>LP Gains</h2>
                                <h3>{LP_GAINS.DISPLAY(order.LP_gains)}</h3>
                            </div>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => confirmOrder()}>Confirm</button>
                            <button id='pending-accept' onClick={() => rejectOrder()}>Reject</button>
                        </div>
                        <div className='container'>
                            <button id='pending-accept' onClick={() => navigate(`/admin-panel/messages/${order.order_id}`)}>Chat</button>
                            <button id='pending-accept' onClick={() => setAccountInfo(true)}>Account</button>
                        </div>
                        <button id='pending-accept' onClick={() => setUpdate(true)}>Edit</button>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick>
                            <RankUpdater close={() => setUpdate(false)} update={(current_rank, current_division, current_LP) => updateOrder(current_rank, current_division, current_LP)} 
                                current_rank={order.current_rank} current_division={order.current_division} current_LP={order.current_LP} />
                        </Popup>
                    </div>
                </div>
                <VerifyBody order={order} info={info}/>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}