
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";
import logo from '../../../assets/Logos/Logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputField from "../../../components/InputField.js";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function NewPassword({closeModal}) {
    
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordConf, setPasswordConf] = useState("");

    const { updatePassword } = useAuth();
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login')
        }
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setLoading(true);
            if(password.length === 0 || passwordConf.length === 0){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "No password provided.",
                })
                setLoading(false);
                return;
            }
            if(password !== passwordConf){
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Passwords don't match.",
                })
                setLoading(false);
                return;
            }
            let error = await updatePassword(password);
            if(error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                })
                setLoading(false);
                return;
            }
            MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: "Your password has been changed.",
            }).then(() => {
                setLoading(false);
                navigate('/')
                window.location.reload();
            })
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to change your password.",
            })
            setLoading(false);
        }
    }

    return (
        <>
            <form className="signup-form" onSubmit={(e) => handleSubmit(e)}>
                <FontAwesomeIcon icon={faXmark} className="exit-icon" onClick={() => closeModal()} />
                <img src={logo} alt="logo" className="logo-img" />
                <div className="signup-inputs">
                    <InputField type="password" placeholder="password" icon={faEnvelope} onChange={setPassword}  required />
                    <InputField type="password" placeholder="confirm password" icon={faEnvelope} onChange={setPasswordConf}  required />
                </div>
                <div className="signup-buttons">
                    <button className="standard-btn" disabled={loading} type="submit">Change Password</button>
                </div>
            </form>
        </>
    );
}
