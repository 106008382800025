
import React from 'react'
import { NavContainer, NavButton } from '../../components/Navigation.js';
import Status from './Coupons/Status.js';

import { useParams, useNavigate } from 'react-router-dom';

import Search from './Components/Search.js';

export default function Coupons() {

    const navigate = useNavigate();
    const { status, order_id } = useParams();
    const [view, setView] = React.useState(<></>);
    
    React.useEffect(() => {
        if(!status) {
            return;
        }
        setView(<Status key={Math.random()}/>);
    }, [status, order_id]);

    return(
        <> 
            <div className='nav-bar-search'>
                <div className='search-bar'>
                    <Search 
                        placeholder="Search..." 
                        type="text" 
                        options={['Coupon']}
                    />
                </div>
                <NavContainer panel={status} onClick={(status) => navigate(`/admin-panel/coupons/${status}`)} adminPanel={true}>
                    <NavButton value='all'>All</NavButton>
                    <NavButton value='generate'>Generate</NavButton>
                </NavContainer>   
            </div>
            {view}
        </>
    );
}
