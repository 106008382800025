import Popup from "reactjs-popup";


export default function Loading({loading, panel}) {
    return(
        <Popup open={loading} modal closeOnDocumentClick={false} closeOnEscape={false} lockScroll={true} contentStyle={{borderRadius: '10px', border: 'none', backgroundColor: 'transparent'}}>
            <div className="loading-container">
                <div className={`loading${panel ? ' centered' : ''}`} />
            </div>
        </Popup>
    );
}