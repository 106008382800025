
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { admin } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";
import UserInformation from "./UserInformation";


export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type: user_id } = useParams();
    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);


    useEffect(() => {
        if(!status) return;
        setTable(admin.users[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(status === 'search') {
            setTable(admin.users['all']);
            setLoading(true);
            axios.get(process.env.REACT_APP_API_URL + '/admin/users-search', {
                headers: { 
                    Authorization: getAccessToken() 
                },
                params: {
                    username: user_id,
                    page: page
                }
            }).then(response => {
                setUsers(response.data);
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
            return;
        }
        if(user_id) {
            setLoading(false);
            setSelectedView(<UserInformation/>);
            return;
        }
        if (!status || page === undefined || panel !== 'users') return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/users', {
            headers: { 
                Authorization: getAccessToken() 
            },          
            params: { 
                status: status, 
                page: page 
            }
        }).then(response => {
            setUsers(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        navigate(`/admin-panel/users/all/${selected.user_id}`)
        setSelectedView(<UserInformation/>);
    }, [selected]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={users} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}