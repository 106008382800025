
import React, { useState, useEffect, useRef } from 'react'
import { TIMEOUT } from '../../constants/misc.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';
import DropDown from '../../components/DropDown.js';

import { EMBLEMS } from '../../constants/colors.js';

import { Helmet } from 'react-helmet';

export default function CopilotCoach({setData}) {

    const helmetTags = require('../../constants/helmet-tags.json');

    const previousData = localStorage.getItem('copilot-coach') ? JSON.parse(localStorage.getItem('copilot-coach')) : {};
    const currency = localStorage.getItem('currency') || 'USD';

    const [orderLanguage, setOrderLanguage] = useState(previousData.orderLanguage || 'English');
    const [coachRank, setCoachRank] = useState(previousData.coachRank || 'Master');
    const [usersRank, setUsersRank] = useState(previousData.usersRank || 'Unranked');
    const [server, setServer] = useState(previousData.server || 'NA');
    const [copilotHours, setCopilotHours] = useState(previousData.copilotHours || 0);
    const [previousCopilotHours, setPreviousCopilotHours] = useState(previousData.copilotHours || 0);
    const [price, setPrice] = useState({});
    const [champions, setChampions] = useState(previousData.champions || []);
    const [roles, setRoles] = useState(previousData.roles || []);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            orderLanguage,
            coachRank,
            usersRank,
            copilotHours,
            server,
            champions,
            roles,
            price
        });
        localStorage.setItem('copilot-coach', JSON.stringify({
            orderLanguage,
            coachRank,
            usersRank,
            copilotHours,
            server,
            champions,
            roles
        }));
    }, [orderLanguage, coachRank, usersRank, copilotHours, server, champions, roles, price]);
    
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-coaching', { 
            coachRank,
            copilotHours,
            vodHours: 0,
            battleHours: 0,
            currency
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [coachRank, currency]);

    useEffect(() => {
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-coaching', { 
                coachRank,
                copilotHours,
                vodHours: 0,
                battleHours: 0,
                currency
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [copilotHours, currency]);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.coaching_copilot.title}</title>
                <meta name="description" content={helmetTags.coaching_copilot.description}/>
                <link rel="canonical" href={helmetTags.coaching_copilot.link}/>
            </Helmet>
            <div className='coaching container'>
                <div className='coaching-selectors'>
                    <div className='coaching-selector' style={{border: `1px solid ${EMBLEMS.GET_COLOR(coachRank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(coachRank)} inset`}}>
                        <div className='component-title'>
                            <h2>Coach Rank</h2>
                            <p>required</p>
                        </div>
                        <img id='emblem' src={require(`../../assets/Emblems/${coachRank}_Emblem.png`)} alt={coachRank + ' Rank Icon'} height={240} width={240} title={'League of legends ' + coachRank + ' rank'}/>
                        <DropDown elements={['Master', 'Grandmaster']} onChange={setCoachRank} defaultValue={coachRank}/>
                        <br/>
                    </div>
                    <div className='coaching-selector' style={{border: `1px solid ${EMBLEMS.GET_COLOR(usersRank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(usersRank)} inset`}}>
                        <div className='component-title'>
                            <h2>Your Rank</h2>
                            <p>optional</p>
                        </div>
                        <img id='emblem' src={require(`../../assets/Emblems/${usersRank}_Emblem.png`)} alt={usersRank + ' Rank Icon'} height={240} width={240} title={'League of legends ' + usersRank + ' rank'}/>
                        <DropDown elements={['Unranked', 'Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond', 'Master', 'Grandmaster', 'Challenger']} onChange={setUsersRank} defaultValue={usersRank}/>
                        <br/>
                    </div>
                    <div className='coaching-selector'>
                        <div className='component-title'>
                            <h2>Region</h2>
                        </div>
                        <div className='component-wrapper'>
                            <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                                <SelectorButton value={'BR'}>BR</SelectorButton>
                                <SelectorButton value={'EUNE'}>EUNE</SelectorButton>
                                <SelectorButton value={'EUW'}>EUW</SelectorButton>
                                <SelectorButton value={'LAN'}>LAN</SelectorButton>
                                <SelectorButton value={'LAS'}>LAS</SelectorButton>
                                <SelectorButton value={'NA'}>NA</SelectorButton>
                                <SelectorButton value={'OCE'}>OCE</SelectorButton>
                                <SelectorButton value={'RU'}>RU</SelectorButton>
                                <SelectorButton value={'TR'}>TR</SelectorButton>
                                <SelectorButton value={'JP'}>JP</SelectorButton>
                                <SelectorButton value={'KR'}>KR</SelectorButton>
                                <SelectorButton value={'TH'}>TH</SelectorButton>
                                <SelectorButton value={'VN'}>VN</SelectorButton>
                            </SelectorContainer>
                        </div>
                    </div>
                    <div className='coaching-selector'>
                        <div className='component-title'>
                            <h2>{copilotHours} Co-Pilot Hour{copilotHours === 1 ? '' : 's'}</h2>
                        </div>
                        <div className='component-wrapper'>
                            <Slider 
                                defaultValue={previousCopilotHours} 
                                step={1} 
                                min={0} 
                                max={3} 
                                onChange={(event, newValue) => {
                                    setCopilotHours(newValue);
                                }}
                            />
                        </div>
                    </div>
                    <div className='coaching-selector'>
                        <div className='component-title'>
                            <h2>Language</h2>
                        </div>
                        <div className='component-wrapper'>
                            <SelectorContainer setCurrentValue={setOrderLanguage} currentValue={orderLanguage}>
                                <SelectorButton value={'English'}>English</SelectorButton>
                                <SelectorButton value={'Spanish'}>Spanish</SelectorButton>
                            </SelectorContainer>
                        </div>
                    </div>
                    <div className='coaching-selector'>
                        <RoleSelector selectedRoles={setRoles} noScroll={true} previousRoles={previousData.roles}/>
                    </div>
                    <div className='coaching-selector'>
                        <ChampionSelector selectedChampions={setChampions} noScroll={true} previousChampions={previousData.champions}/>
                    </div>
                </div>
            </div>
        </>
    );
}