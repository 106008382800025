

const EMBLEMS = {
    UNRANKED: '#514e4a',
    IRON: '#503a31',
    BRONZE: '#5f3625',
    SILVER: '#586f82',
    GOLD: '#7d4823',
    PLATINUM: '#156f7b',
    EMERALD: '#095731',
    DIAMOND: '#333576',
    MASTER: '#631758',
    GRANDMASTER: '#a03c0e',
    CHALLENGER: '#418fd4',
    GET_COLOR: (rank) => {
        switch(rank.toUpperCase()) {
            case 'UNRANKED':
                return EMBLEMS.UNRANKED;
            case 'IRON':
                return EMBLEMS.IRON;
            case 'BRONZE':
                return EMBLEMS.BRONZE;
            case 'SILVER':
                return EMBLEMS.SILVER;
            case 'GOLD':
                return EMBLEMS.GOLD;
            case 'PLATINUM':
                return EMBLEMS.PLATINUM;
            case 'EMERALD':
                return EMBLEMS.EMERALD;
            case 'DIAMOND':
                return EMBLEMS.DIAMOND;
            case 'MASTER':
                return EMBLEMS.MASTER;
            case 'GRANDMASTER':
                return EMBLEMS.GRANDMASTER;
            case 'CHALLENGER':
                return EMBLEMS.CHALLENGER;
            default:
                return '#FFFFFF';
        }
    }
};

module.exports = { EMBLEMS };