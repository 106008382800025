
import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS } from '../../../../constants/database_values.js';
import { TIMEOUT } from '../../../../constants/misc.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';

export default function NormalGames({setData}) {

    const [orderType, setOrderType] = useState('Total Games');
    const [server, setServer] = useState('NA');
    const [numGames, setNumGames] = useState(10);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState([]);
    const [champions, setChampions] = useState([]);
    const [roles, setRoles] = useState([]);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            orderType,
            numGames,
            server,
            addons,
            champions,
            roles,
            price
        });
    }, [orderType, numGames, server, addons, champions, roles, price]);

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    
    useEffect(() => {
        if(orderType === null || numGames === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-normal-games-boost', { 
            orderType, 
            numGames, 
            addons
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [orderType, addons]);

    useEffect(() => {
        if(orderType === null || numGames === null){
            return;
        }
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-normal-games-boost', { 
                orderType, 
                numGames, 
                addons
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [numGames]);

    return(
        <>
            <div className='placements container'>
                <div className='division-boosting-selectors placements'>
                    <div className='division-boosting-selector'>
                        <h2>Region</h2>
                        <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                            <SelectorButton value={'NA'}>NA</SelectorButton>
                            <SelectorButton value={'LAN'}>LAN</SelectorButton>
                            <SelectorButton value={'LAS'}>LAS</SelectorButton>
                            <SelectorButton value={'EUW'}>EUW</SelectorButton>
                            <SelectorButton value={'EUNE'}>EUNE</SelectorButton>
                            <SelectorButton value={'OCE'}>OCE</SelectorButton>
                            <SelectorButton value={'BR'}>BR</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='division-boosting-selector'>
                        <h2>Order Type</h2>
                        <SelectorContainer setCurrentValue={setOrderType} currentValue={orderType}>
                            <SelectorButton value={'Total Wins'}>Total Wins</SelectorButton>
                            <SelectorButton value={'Total Games'}>Total Games</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='placements-game-number'>
                        <h2>{numGames} {orderType}</h2>
                        <Slider 
                            defaultValue={10} 
                            step={1} 
                            min={1} 
                            max={25} 
                            onChange={(event, newValue) => {
                                setNumGames(newValue);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='division-boosting-addons'>
                <h2>Addons</h2>
                <div className='ordering-status-buttons'>
                    {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                        if(ADDONS[addon].title === 'Bonus Win'){
                            return;
                        }
                        return(
                            <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                        )}
                    )}
                </div>
                {addons.includes('Specific Roles')&& <RoleSelector selectedRoles={setRoles}/>}
                {addons.includes('Specific Champions')&& <ChampionSelector selectedChampions={setChampions}/>}
            </div>
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}