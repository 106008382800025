
import React from 'react'
import { useState } from 'react';
import RankMapping from './RankMapping.js';

import { useEffect } from 'react';
import { SelectorButton, SelectorContainer } from '../../../../../components/Selector.js';
import { EMBLEMS } from '../../../../../constants/colors.js';
import DropDown from '../../../../../components/DropDown.js';
import { LP_GAINS } from '../../../../../constants/database_values.js';


export default function DesiredSelector({update, minRank, maxDivision}) {
    
    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];

    const [rank, setRank] = useState('Gold');
    const [division, setDivision] = useState(4);
    const [LPGains, setLPGains] = useState(LP_GAINS.LOW.VALUE);
    const [disabledRanks, setDisabledRanks] = useState([]);
    const [disabledDivisions, setDisabledDivisions] = useState([]);

    useEffect(() => {
        update({rank, division, LPGains});
    }, [rank, division, LPGains]);

    function isValidRank(minRank, maxDivision) {
        let rankValue = RankMapping[rank];
        let minRankValue = RankMapping[minRank];
        if(rankValue === minRankValue) {
            if(division >= maxDivision) {
                return false;
            }
        } else if(rankValue < minRankValue) {
            return false;
        }
        return true;
    }

    function setValidRank(minRank, maxDivision) {
        let minRankValue = RankMapping[minRank];
        if(maxDivision === 1) {
            setDivision(4);
            setRank(RankMapping[minRankValue + 1]);
            return;
        }
        setRank(minRank);
        setDivision(maxDivision - 1);
    }

    useEffect(() => {
        if(minRank === undefined || minRank === null) {
            return;
        }
        let minRankValue = RankMapping[minRank];
        let disabledRanks = [];
        for(let i = minRankValue - 1; 0 <= i; i--) {
            disabledRanks.push(RankMapping[i]);
        }
        setDisabledRanks(disabledRanks);
        if(!isValidRank(minRank, maxDivision)) {
            setValidRank(minRank, maxDivision);
        }
    }, [minRank]);

    useEffect(() => {
        let disabledDivisions = [];
        for(let i = 4; i >= maxDivision; i--) {
            disabledDivisions.push(i);
        }
        setDisabledDivisions(disabledDivisions);
        if(!isValidRank(minRank, maxDivision)) {
            setValidRank(minRank, maxDivision);
        }
    }, [maxDivision]);

    useEffect(() => {
        if(!isValidRank(minRank, maxDivision)) {
            setValidRank(minRank, maxDivision);
        }
    }, [rank]);

    return(
        <>
            <div className={'rank-selector desired'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
                <h2>Desired Rank</h2>
                <img id='emblem' src={require(`../../../../../assets/Emblems/${rank}_Emblem.png`)}/>
                <DropDown elements={ranks} onChange={setRank} defaultValue={rank} disabled={[...disabledRanks, maxDivision === 1 ? minRank : null]}/>
                <SelectorContainer setCurrentValue={setDivision} currentValue={division}>
                    <SelectorButton value={4} disabled={minRank === rank && disabledDivisions.includes(4)}>IV</SelectorButton>
                    <SelectorButton value={3} disabled={minRank === rank && disabledDivisions.includes(3)}>III</SelectorButton>
                    <SelectorButton value={2} disabled={minRank === rank && disabledDivisions.includes(2)}>II</SelectorButton>
                    <SelectorButton value={1} disabled={minRank === rank && disabledDivisions.includes(1)}>I</SelectorButton>
                </SelectorContainer>
                <SelectorContainer setCurrentValue={setLPGains} currentValue={LPGains}>
                    <SelectorButton value={LP_GAINS.LOW.VALUE}>{LP_GAINS.LOW.BUTTON_DISPLAY}</SelectorButton>
                    <SelectorButton value={LP_GAINS.MEDIUM.VALUE}>{LP_GAINS.MEDIUM.BUTTON_DISPLAY}</SelectorButton>
                    <SelectorButton value={LP_GAINS.HIGH.VALUE}>{LP_GAINS.HIGH.BUTTON_DISPLAY}</SelectorButton>
                </SelectorContainer>
                <p>LP Gains</p>
            </div>
        </>
    );
}