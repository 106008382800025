import React, { useEffect, useState } from 'react';

export function NavContainer({ children, panel, onClick, adminPanel}) {
  const [currentValue, setCurrentValue] = useState(panel);
  const [isNavContainerTall, setIsNavContainerTall] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const navRef = React.useRef(null);

  const onClickEvent = (value) => {
    setCurrentValue(value);
    if (onClick) {
      onClick(value);
    }
    toggleCollapse();
  };

  const toggleCollapse = () => {
    setIsCollapsed((prevCollapsed) => !prevCollapsed);
  };

  useEffect(() => {
    const checkNavContainerHeight = () => {
        if (navRef.current.clientHeight > 64) {
            // Change the height condition as needed
            setIsNavContainerTall(true);
        } else {
            setIsNavContainerTall(false);
        }
    };
    checkNavContainerHeight();
    window.addEventListener('resize', checkNavContainerHeight);
    return () => {
      window.removeEventListener('resize', checkNavContainerHeight); 
    };
  }, []);

  useEffect(() => {
    if (panel !== currentValue) {
      setCurrentValue(panel);
    }
  }, [panel]);


  if (isNavContainerTall) {
    return (
      <div className={`nav-container tall`} ref={navRef}>
        <button onClick={toggleCollapse}>{children.find((child) => child.props.value === currentValue)?.props.children}</button>
        {isCollapsed ? null : (
          <div className="nav-children">
            {children.map((child, index) => {
              return React.cloneElement(child, { onClickEvent: (value) => {onClickEvent(value)}, currentValue, key: index });
            })}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`nav-container${adminPanel ? ' admin' : ''}`} ref={navRef}>
      {children.map((child, index) => {
        return React.cloneElement(child, { onClickEvent, currentValue, key: index });
      })}
    </div>
  );
}

export function NavButton({ children, onClickEvent, currentValue, value }) {
  const handleClick = () => {
    if (onClickEvent) {
      onClickEvent(value);
    }
  };
  return (
    <button onClick={handleClick} className={`nav-button ${currentValue === value ? 'active' : 'inactive'}`}>
      {children}
    </button>
  );
}
