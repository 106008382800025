
import React, { useState, useEffect } from 'react'

import axios from 'axios';
import ProgressBar from "@ramonak/react-progress-bar";
import { useAuth } from '../../../../contexts/AuthContext.js';
import Popup from 'reactjs-popup';
import RankUpdater from './Components/RankUpdater.js';
import Conclude from './Components/Conclude.js';
import Loading from '../../../../components/Loading.js';
import { ORDER_STATUS, BOOST_TYPE, LP_GAINS, LP } from '../../../../constants/database_values.js';
import { useParams, useNavigate } from 'react-router-dom';
import { Addons, Roles, Champions } from '../../../../components/GeneralContainers.js';

import AccountInformation from './Components/AccountInformation.js';

export default function ActiveDivisionBoost() {

    const [update, setUpdate] = React.useState(false);
    const [conclude, setConclude] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [accountInfo, setAccountInfo] = React.useState(false);

    const [order, setOrder] = useState(null);

    const navigate = useNavigate();

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/boost/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division){
        switch(division){
            case 1:
                return rank + ' I';
            case 2:
                return rank + ' II';
            case 3:
                return rank + ' III';
            case 4:
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    function updateOrder(current_rank, current_division, current_LP) {
        setLoading(true);
        axios.put(process.env.REACT_APP_API_URL + '/boost/update-progress', { 
            order_id: order.order_id,
            boost_type: BOOST_TYPE.DIVISION_BOOSTING,
            current_rank, 
            current_division, 
            current_LP
        }, {
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            setLoading(false);
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        })
    }

    function concludeOrder() {
        axios.put(process.env.REACT_APP_API_URL + `/boost/conclude-boost-order/${BOOST_TYPE.DIVISION_BOOSTING}/${order.order_id}`, {  }, { 
            headers: { 
                Authorization: getAccessToken()
            }
        }).then((res) => {
            navigate('/boost-panel/boosting/completed');
            window.location.reload();
        }).catch((error) => {
            console.error(error);
            alert('Error concluding order');
        })
    }

    if(!order) return(
        <>
            <div className='active-order division-boost'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order division-boost'>
                <div className='header'>
                    <h1>Division Boost</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Start</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.initial_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.initial_rank, order.initial_division, order.initial_LP)}</h3>
                            <h3>{LP.DISPLAY(order.initial_LP)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Current</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.current_rank, order.current_division, order.current_LP)}</h3>
                            <h3>{LP.DISPLAY(order.current_LP)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Objective</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.desired_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.desired_rank, order.desired_division, order.desired_LP)}</h3>
                            <h3>{LP_GAINS.DISPLAY(order.LP_gains)} (Gains)</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <div className='buttons container'>
                        <button className='massive-btn' onClick={() => setUpdate(true)}>Update Order</button>
                        <button className='massive-btn' onClick={() => setAccountInfo(true)}>Account Information</button>
                        <button className='massive-btn' onClick={() => setConclude(true)}>Conclude Order</button>
                        <Popup open={conclude} position="right center" closeOnEscape closeOnDocumentClick>
                            <Conclude close={() => setConclude(false)} confirm={() => concludeOrder()}/>
                        </Popup>
                        <Popup open={accountInfo} position="right center" closeOnEscape closeOnDocumentClick onClose={() => setAccountInfo(false)}>
                            <AccountInformation order_id={order_id} setLoading={setLoading}/>
                        </Popup>
                        <Popup open={update} position="right center" closeOnEscape closeOnDocumentClick>
                            <RankUpdater close={() => setUpdate(false)} update={(current_rank, current_division, current_LP) => updateOrder(current_rank, current_division, current_LP)} 
                                current_rank={order.current_rank} current_division={order.current_division} current_LP={order.current_LP} />
                        </Popup>
                    </div>
                </div>
                <div className='body'>
                    <div className='card'>
                        <h2>Earnings</h2>
                        <h3>${order.earnings.toFixed(2)} USD</h3>
                    </div>
                    <div className='card'>
                        <h2>Addons</h2>
                        <Addons elements={order.addons}/>
                    </div>
                    <div className='card'>
                        <h2>Roles</h2>
                        <Roles elements={order.roles}/>
                    </div>
                    <div className='card'>
                        <h2>Champions</h2>
                        <Champions elements={order.champions}/>
                    </div>
                    <div className='card break-word'>
                        <h2>Notes</h2>
                        <p>{order.notes}</p>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}