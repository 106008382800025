
import React, { useState, useEffect } from "react";
import Popup from 'reactjs-popup';
import Login from "./SignIn/Login.js";
import CreateAccount from "./SignIn/CreateAccount.js";
import ResetPassword from "./SignIn/ResetPassword.js";

export default function SignIn({ open, close }) {
    
    const [modal, setModal] = useState(''); // login, signup, reset

    const MODAL = {
        LOGIN: 'login',
        SIGNUP: 'signup',
        RESET: 'reset'
    }

    useEffect(() => {
        if(open) {
            setModal(MODAL.LOGIN);
        } else {
            setModal('');
            close();
        }
    }, [open]);

    useEffect(() => {
        if(modal === '') {
            close();
            return;
        }
    }, [modal]);

    function handleClose(currentModal) {
        if(currentModal === modal) {
            setModal('');
        }
    }

    return (
        <>
            <Popup open={open && modal === MODAL.LOGIN} closeOnEscape closeOnDocumentClick onClose={() => handleClose(MODAL.LOGIN)} modal nested>
                <Login key={MODAL.LOGIN} closeModal={() => setModal('')} changeModal={(modal) => setModal(modal)}/>
            </Popup>
            <Popup open={open && modal === MODAL.SIGNUP} closeOnEscape closeOnDocumentClick onClose={() => handleClose(MODAL.SIGNUP)} modal nested>
                <CreateAccount key={MODAL.SIGNUP} closeModal={() => setModal('')} changeModal={(modal) => setModal(modal)}/>
            </Popup>
            <Popup open={open && modal === MODAL.RESET} closeOnEscape closeOnDocumentClick onClose={() => handleClose(MODAL.RESET)} modal nested>
                <ResetPassword key={MODAL.RESET} closeModal={() => setModal('')} changeModal={(modal) => setModal(modal)}/>
            </Popup>
        </>
    );
}
