
import React from 'react'

import ProgressBar from "@ramonak/react-progress-bar";

import { BOOST_TYPE, ORDER_STATUS, LP } from '../../../../constants/database_values';
import { useEffect } from 'react';

import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from '../../../../contexts/AuthContext';

import Loading from '../../../../components/Loading';
import { Addons, Champions, Roles } from '../../../../components/GeneralContainers';

export default function ActiveDivisionBoost() {

    const { order_id } = useParams();
    const { getAccessToken } = useAuth();

    const [loading, setLoading] = React.useState(true);
    const [order, setOrder] = React.useState(null);

    const navigate = useNavigate();


    useEffect(() => {
        if(!order_id) return;
        axios.get(process.env.REACT_APP_API_URL + '/user/boost-order-information/' + order_id, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                boost_type: BOOST_TYPE.DIVISION_BOOSTING,
                order_status: ORDER_STATUS.ACTIVE
            }
        }).then(response => {
            setOrder(response.data);
            setLoading(false);
            const conversionTracked = localStorage.getItem(response.data.order_id);
            if(!conversionTracked){
                window.gtag('event', 'manual_event_PURCHASE', {
                    'send_to': 'G-VDQCZRHSJG',
                    'value': response.data.total_price,
                    'currency': 'USD',
                    'transaction_id': response.data.order_id
                });
                localStorage.setItem(response.data.order_id, true);
            }
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [order_id]);

    function handleRank(rank, division, LP){
        switch(division.toString()){
            case '1':
                return rank + ' I';
            case '2':
                return rank + ' II';
            case '3':
                return rank + ' III';
            case '4':
                return rank + ' IV';
            default:
                return 'Error';
        }
    }

    if(!order) return(
        <>
            <div className='active-order division-boost'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <div className='active-order division-boost'>
                <div className='header'>
                    <h1>Division Boost</h1>
                    <div className='container'>
                        <div className='rank-card'>
                            <h2>Start</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.initial_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.initial_rank, order.initial_division, order.initial_LP)}</h3>
                            <h3>{LP.DISPLAY(order.initial_LP)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Current</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.current_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.current_rank, order.current_division, order.current_LP)}</h3>
                            <h3>{LP.DISPLAY(order.current_LP)}</h3>
                        </div>
                        <div className='rank-card'>
                            <h2>Objective</h2>
                            <img id='emblem' src={require(`../../../../assets/Emblems/${order.desired_rank}_Emblem.png`)}/>
                            <h3>{handleRank(order.desired_rank, order.desired_division, order.desired_LP)}</h3>
                        </div>
                    </div>
                    <ProgressBar completed={Math.max(Math.min(order.progress, 100), 0)} className='progress-bar' bgColor={'var(--main)'} height='100%'/>
                    <button id='pending-accept' onClick={() => {navigate(`/my-orders/boosting/account-information/division-boosting/${order.order_id}`)}}>Account Information</button>
                </div>
                <div className='body'>
                    <div className='card'>
                        <h2>Total Price</h2>
                        <h3>${order.total_price.toFixed(2)} USD</h3>
                    </div>
                    <div className='card'>
                        <h2>Addons</h2>
                        <Addons elements={order.addons}/>
                    </div>
                    <div className='card'>
                        <h2>Roles</h2>
                        <Roles elements={order.roles}/>
                    </div>
                    <div className='card'>
                        <h2>Champions</h2>
                        <Champions elements={order.champions}/>
                    </div>
                    <div className='card break-word'>
                        <h2>Notes</h2>
                        <p>{order.notes}</p>
                    </div>
                </div>
            </div>
            <Loading loading={loading} panel={true}/>
        </>
    );
}