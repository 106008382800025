
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";

import logo from '../../../assets/Logos/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faKey, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputField from "../../../components/InputField.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function CreateAccount({closeModal, changeModal}) {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConf, setPasswordConf] = useState("");
    const [modalChange, setModalChange] = useState("signup");

    const { signup } = useAuth();
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login')
        }
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        if(modalChange !== "signup") {
            return;
        }
        try {
            setLoading(true);
            if(password.length === 0 || passwordConf.length === 0 || email.length === 0){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "Please fill out all the fields.",
                })
                setLoading(false);
                return;
            }
            if(password !== passwordConf){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "Passwords do not match.",
                })
                setLoading(false);
                return;
            }
            if(password.length < 6){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "Password must be at least 6 characters long.",
                })
                setLoading(false);
                return;
            }
            if(!email.includes('@') || !email.includes('.')){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "Invalid email.",
                })
                setLoading(false);
                return;
            }
            const { value: formValues } = await Swal.fire({
                title: "Terms & Conditions",
                html: `
                    <div className='terms-of-service' style="max-height: 400px; overflow-y: auto;">
                        <p style="text-align: left">Last updated: July 24, 2024</p>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                1.0 FOREWORD
                            </h2>
                            <p>
                            These terms and conditions lay out the guidelines by which the general use of ELOFACTORY GG (henceforth, the “Website”) is regulated.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG manages and operates this Website, by which Boosting and Coaching services (as defined later on) for multiplayer videogames such as League of Legends (henceforth, “LOL”), Valorant, Apex Legends, TFT, and others (collectively referred to as the “Videogames”) are offered to the public.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG is not the entity responsible for providing the Boosting and Coaching services, but rather offers the necessary online services to enable Users to connect with Coaches (as definer later on).
                            </p>
                            <br />
                            <p>
                            Boosting and Coaching Services offered through ELOFACTORY GG are offered to Users regardless of their geographical location, provided the laws of their respective country allow such services.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                2.0 DEFINITIONS
                            </h2>
                            <ul>
                                <li>
                                    <p>
                                        <strong>User</strong>: Any person that signs up to ELOFACTORY GG and purchases any of the Services offered on the Website.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Coach</strong>: Any person related to ELOFACTORY GG that provides Boosting and/or Coaching Services to the User.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Boosting</strong>: Service that consists in the intervention of a Coach to increase the level and/or rank of a User’s account for any of the Videogames offered on the Website to a given level and/or rank, as requested by the User.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Coaching</strong>: Service that consists in guidance, preparation, teaching, and/or counseling offered by a Coach directly to a User, in order to improve the User’s performance and in-game experience for LOL or any of the Videogames offered on the Website, as requested by the User.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>ELOFACTORY GG Account</strong>: Refers to the account created by the User when signing up to ELOFACTORY GG through the Website, in order to purchase any of the Services that are offered.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Summoner Name</strong>: Refers to the visible name and individual gaming profile that is used by the User for LOL or any of the Videogames.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Username</strong>: Refers to the username used to access an individual LOL account or an account for any of the Videogames, which may differ from Summoner Name.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Services</strong>: Refers to any of the services offered on the Website, be it Coaching, Boosting, or Addons.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <strong>Addons (additional services)</strong>: Refers to any of the following, available as complements to the Boosting Service:
                                        <ul>
                                            <li>
                                                <p>
                                                    i) <strong>Duo</strong>: User plays simultaneously with a Coach.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    ii) <strong>VPN</strong>: Coach uses a VPN to match User’s geographical location by IP Address.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    iii) <strong>Livestream</strong>: Via Discord.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    iv) <strong>Specific Roles</strong>: Coach uses the roles during gameplay requested by User. In certain cases, the Coach will be required to use a different role, due to insufficient players in a match or Videogame.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    v) <strong>Bonus Win</strong>: Once the objective requested by User is fulfilled, Coach plays an additional match to ensure that User is out of risk of decreasing level.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    vi) <strong>Specific Champions</strong>: Coach plays with the champion(s) requested by User. There are cases in which it will not be possible to use a specific champion, due to (i) the unavailability of the champion because it has already been chosen by another player in a match, (ii) the champion has been banned by the team members and/or opponents, and (iii) the champion has been deactivated due to Videogame malfunction.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    vii) <strong>Appear Offline</strong>: Coach employs apps such as “Deceive” to avoid appearing online in the Videogame.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    viii) <strong>Solo Play</strong>: Coach plays the required matches completely solo, without the intervention of the User or another Coach.
                                                </p>
                                            </li>
                                            <li>
                                                <p>
                                                    ix) <strong>Priority</strong>: For an additional price, the search for a Coach is widened and with it the possibility of an offer being accepted.
                                                </p>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                3.0 ENFORCEMENT OF TERMS AND CONDITIONS
                            </h2>
                            <p>
                            Users that set up a ELOFACTORY GG account and/or purchase any of the Services offered on the Website shall be bound to these Terms and Conditions.
                            </p>
                            <br />
                            <p>
                            In case that regional and/or national laws forbid a User to purchase and use the Services offered on the Website, the User must refrain from purchasing said Services.                    </p>
                            <br />
                            <p>
                            ELOFACTORY GG reserves the right to amend and modify these current Terms and Conditions, which shall then be published and notified to the Users via the Website. Hence, it is the Users’ responsibility to know and review the Terms and Conditions.
                            </p>
                            <br />
                            <p>
                            The User accepts these Terms and Conditions upon the purchase of any of the Services offered by ELOFACTORY GG and/or upon creation of the User’s ELOFACTORY GG Account.
                            </p>
                            <br />
                            <p>
                            Communication between Users and ELOFACTORY GG will take place primarily through “Discord”, through ELOFACTORY GG’s support server. Users may also contact ELOFACTORY GG through the email address provided by the User when setting up the ELOFACTORY GG Account. Coaches may additionally opt to establish communication with the User through digital platforms such as “Twitch” and/or “Discord”, for the provision of Services.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                4.0 CAPACITY 
                            </h2>
                            <p>
                            Users who purchase any of the Services offered on this Website declare that these Terms and Conditions have been read and understood and therefore agree to be bound by them.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                5.0 USER REGISTRATION AND SIGN UP
                            </h2>
                            <p>
                            In order to access and pay for the Services, User must have an active ELOFACTORY GG Account. To open a ELOFACTORY GG Account, User must provide the requested information truthfully and accurately. User will have the right to update the information when needed. The information that will be requested from User includes but is not limited to:                    </p>
                            <ul>
                                <li>
                                    <p>
                                        i) User’s Name and Last Name
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        ii) ELOFACTORY GG Account’s Username
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        iii) Email Address
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        iv) Password to access Videogame (only for Boosting)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        v) Summoner Name (LOL) and/or identifying name for different Videogame
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        vi) Date of Birth (optional, for statistical purposes)
                                    </p>
                                </li>
                            </ul>
                            <p>
                            Failure to submit the required information truthfully will be considered breach to the present Terms and Conditions and, therefore, the User’s ELOFACTORY GG Account may be subject to suspension and/or immediate cancellation of the Services that were purchased.
                            </p>
                            <br />
                            <p>
                            Submission of the required information shall be deemed as User’s consent for ELOFACTORY GG to disclose the essential data (depending on the Service that was purchased) exclusively to the Coaches that offer Coaching and Boosting Services. ELOFACTORY GG will never disclose or share the User’s information to any unauthorized third party. For more information, please refer to our Privacy Notice.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                6.0 INTELLECTUAL PROPERTY
                            </h2>
                            <p>
                            ELOFACTORY GG is not affiliated with Riot Games.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG warrants that it does not own any interest in Riot Games and, therefore, any and all intellectual property and copyright aspects pertaining to LOL are wholly property of Riot Games.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG’s brand is protected under the laws of Intellectual Property of the United Mexican States (Mexico). The misuse or unauthorized use of ELOFACTORY GG’s brand and image may be punishable by law. 
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                7.0 PAYMENTS ON THE WEBSITE
                            </h2>
                            <p>
                            All payments for the purchase of Services offered on ELOFACTORY GG shall be made through the platform known as “Stripe”. ELOFACTORY GG may carry out changes to the payment method to include the option of a digital payment system such as PayPal or others. ELOFACTORY GG will never request bank details from the Users.
                            </p>
                            <br />
                            <p>
                            We reserve the right to cancel an order at any given moment for reasons such as mistakes in the order, inaccurate information, or in case the Service has been withdrawn. In such cases, User will not be charged and, if the case may be, a refund will be applied on the amount paid.
                            </p>
                            <br />
                            <p>
                            To access the payment site for regular Boosting Services, User must submit name and last name, ELOFACTORY GG Account, Username, Password, and the name visible to other players in LOL (Summoner Name). User’s password for ELOFACTORY GG Account and/or LOL account shall not be required for Coaching Services and Boosting with the Duo Addon.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                8.0 CANCELLATIONS AND REFUNDS
                            </h2>
                            <p>
                            User will have the option to cancel the Services and, in some cases, obtain a refund. A 10% (ten per cent) discount on the total amount paid by the User is applied to all refunds. The cancellation of the purchased Services must be requested by the User as follows:                    </p>
                            <ul>
                                <li>
                                    <p>
                                        i) Boosting: In order to request the cancellation and refund of Boosting Services, User must start the cancellation process before a Coach has been assigned to the order. Once a Coach has been assigned, the Boosting Service will be considered as initiated and effective. In such case, User may be able to cancel the Service but will not have the right to claim a refund.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        ii) Coaching: In order to request the cancellation of Coaching Services or, if applicable, refund for dissatisfaction with the Service provided, User must request cancellation and request a refund by sending an email to support@elofactory.gg, detailing the issue. ELOFACTORY GG’s support department will assess the User’s request and inform the User if the request is valid and, as the case may be, if a partial or total refund is applicable, which shall depend on the moment at which User requests the refund. In case the request is approved, refund shall be paid to User within 30 (thirty) days counting from receipt of the request. 
                                    </p>
                                </li>
                            </ul>
                            <p>
                            Any and all complaints or nonconformities must be submitted by the User to the email support@elofactory.gg, in which a detailed account of the issue must be provided. ELOFACTORY GG’s support department will assess the complaint or nonconformity and reply to the User within a reasonable timeframe. Failure to receive an immediate response means that the request is being assessed and resolved.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                9.0 JURISDICTION
                            </h2>
                            <p>
                            In case of controversy or dispute, the applicable jurisdiction shall be that of the competent courts of the United Mexican States (Mexico). ELOFACTORY GG and its affiliates are entities of Mexican nationality and, as such, are bound by Mexican Laws regarding the provision of services, copyright, dispute resolution, and obligations to protect the User’s personal information.
                            </p>
                            <br />
                            <p>
                            By accepting these Terms and Services, User accepts the aforementioned jurisdiction and waives any claim or right to any other jurisdiction that may correspond by virtue of User’s address, present or future and/or nationality.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                10.0 EXTENT OF RESPONSIBILITY
                            </h2>
                            <p>
                            ELOFACTORY GG shall bear no responsibility for the suspension or cancellation of the Users’ Accounts in any of the Videogames offered on the Website or for any type of penalty that may be imposed on the Users due to the evasion or breach of Riot Games’ or any other Videogame developer’s policies.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG does not and has not entered into a contractual relationship with Riot Games and/or any Videogame developer company.
                            </p>
                            <br />
                            <p>
                            ELOFACTORY GG shall bear no responsibility in case the emails sent to the User’s registered email addresses are classified as spam or junk by the User’s email server.
                            </p>
                        </div>
                        <br />
                        <div className='terms-of-service-container' style="text-align: left">
                            <h2 style="font-size: 1.25rem">
                                11.0 STARTING DATE AND EFFECT OF TERMS AND CONDITIONS
                            </h2>
                            <p>
                            These Terms and Conditions shall be applicable for all Users upon the completion of a purchase on the Website and shall enter into force from the moment said purchase is confirmed.
                            </p>
                        </div>
                    </div>
                    <br>
                    <input type="checkbox" id="accept-checkbox" required>
                    <label for="accept-checkbox">I agree with the terms and conditions</label>
                    `,
                    focusConfirm: false,
                    /*
                    <br>
                    <input type="checkbox" id="newsletter-checkbox">
                    <label for="newsletter-checkbox">Subscribe to our newsletter</label>
                    */
                    preConfirm: () => {
                        const acceptCheckbox = document.getElementById('accept-checkbox');
                        const newsletterCheckbox = document.getElementById('newsletter-checkbox');
                        return {
                            accept: acceptCheckbox?.checked,
                            subscribeNewsletter: newsletterCheckbox?.checked,
                        };
                    },
                    confirmButtonText: "Continue",
                    inputValidator: (formValues) => {
                        if (!formValues.accept) {
                            return 'You need to agree with the Terms and Conditions';
                        }
                    },
                });

            if (!formValues.accept) {
                Swal.fire({
                    icon: "warning",
                    text: "You have to agree with the Terms and Conditions",
                });
                setLoading(false);
                return;
            }
            let error = await signup(email, password)
            if(error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                })
                setLoading(false);
                return;
            }
            await MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: "Please verify your email to continue.",
            })
            window.location.reload();
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to sign up.",
            })
        }
        setLoading(false);
    }

    useEffect(() => {
        if(modalChange !== "signup") {
            changeModal(modalChange);
        }
    }, [modalChange]);

    return (
        <>
            <form className="signup-form" onSubmit={(e) => handleSubmit(e)}>
                <FontAwesomeIcon icon={faXmark} className="exit-icon" onClick={() => closeModal()} />
                <img src={logo} alt="logo" className="logo-img" />
                <div className="signup-inputs">
                    <InputField type="email" placeholder="email" icon={faEnvelope} onChange={setEmail}  required />
                    <InputField type="password" placeholder="password" icon={faKey} onChange={setPassword}  required />
                    <InputField type="password" placeholder="confirm password" icon={faKey} onChange={setPasswordConf}  required />
                </div>
                <div className="signup-buttons">
                    <button className="standard-btn" disabled={loading} type="submit">Register</button>
                </div>
                <div className="signup-footer">
                    <button onClick={() => setModalChange('login')} className="link-hover">Log In</button>
                    <button onClick={() => setModalChange('reset')} className="link-hover">Reset Password</button>
                </div>
            </form>
        </>
    );
}