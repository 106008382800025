
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import DropDown from '../../../../../components/DropDown';
import { SelectorContainer, SelectorButton } from '../../../../../components/Selector';
import InputField from '../../../../../components/InputField';

export default function RankUpdater({current_rank, current_division, current_LP, update, close}) {

    const [rank, setRank] = React.useState(current_rank);
    const [division, setDivision] = React.useState(current_division);
    const [LP, setLP] = React.useState(current_LP);

    const ranks = ['Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond'];


    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login');
            close();
        }
    }, [])

    function closeAndSave() {
        if(LP > 100 || LP < 0) {
            alert('LP must be between 0 and 100');
            return;
        }
        update(rank, division, LP);
        close();
    }

    return(
        <div className={'rank-updater'}>
            <FontAwesomeIcon icon={faXmark} className='rank-updater-close' onClick={() => close()}/>
            <h1>Current Rank</h1>
            <img id='emblem' src={require(`../../../../../assets/Emblems/${rank}_Emblem.png`)} className='rank-updater-emblem'/>
            <DropDown elements={ranks} defaultValue={rank} onChange={setRank}/>
            <SelectorContainer currentValue={division} setCurrentValue={(value) => setDivision(value)}>
                <SelectorButton value={4}>IV</SelectorButton>
                <SelectorButton value={3}>III</SelectorButton>
                <SelectorButton value={2}>II</SelectorButton>
                <SelectorButton value={1}>I</SelectorButton>
            </SelectorContainer>
            <div className='rank-updater-lp'>
                <InputField type='number' key={1} placeholder='LP' onChange={setLP} value={LP} max={100} min={0}/>
            </div>
            <div className='rank-updater-btns'>
                <button className='rank-updater-cancel' onClick={() => close()}>Cancel</button>
                <button className='rank-updater-update' onClick={() => closeAndSave()}>Update</button>
            </div>
        </div>
    );
}