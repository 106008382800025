
import React from 'react'

import DivisionBoost from './GetBoosting/DivisionBoost.js';
import PlacementBoost from './GetBoosting/PlacementBoost.js';
import WinBoost from './GetBoosting/WinBoost.js';
import NormalGames from './GetBoosting/NormalGames.js';

import { useEffect } from 'react';
import { NavButton, NavContainer } from '../components/Navigation.js';
import SignIn from './NavBar/SignIn.js';

import { useParams, useNavigate } from 'react-router-dom';
import { BOOST_TYPE } from '../constants/database_values.js';
import Checkout from '../components/Checkout.js';

export default function GetBoosting() {

    const { boost_type } = useParams();
    const navigate = useNavigate();
    const [view, setView] = React.useState(<></>);
    const [showLogin, setShowLogin] = React.useState(false);
    const [data, setData] = React.useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); 

    useEffect(() => {
        if(!boost_type) return;
        switch(BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                setView(<DivisionBoost key={boost_type} setData={setData}/>);
                break;
            case BOOST_TYPE.PLACEMENT_GAMES:
                setView(<PlacementBoost key={boost_type} setData={setData}/>);
                break;
            case BOOST_TYPE.NET_WINS:
                setView(<WinBoost key={boost_type} setData={setData}/>);
                break;
            case BOOST_TYPE.NORMAL_GAMES:
                setView(<NormalGames key={boost_type} setData={setData}/>);
                break;
            default:
                setView(<></>);
                break;
        }
    }, [boost_type]);


    return(
        <>
            <div className='order-now'>
                <div className='background-img' style={{backgroundImage: `url(${require('../assets/splashart.jpg')})`}}/>
                <div className='order-details'>
                    <div className='order-details-content'>
                        <div className='order-details-nav'>
                            <NavContainer panel={BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)} onClick={(select) => navigate(`/get-boosting/${BOOST_TYPE.CONVERT_TO_URL(select)}`)}>
                                <NavButton value={(BOOST_TYPE.DIVISION_BOOSTING)}>
                                    Division Boosting
                                </NavButton>
                                <NavButton value={(BOOST_TYPE.PLACEMENT_GAMES)}>
                                    Placement Games
                                </NavButton>
                                <NavButton value={(BOOST_TYPE.NET_WINS)}>
                                    Net Wins
                                </NavButton>
                                <NavButton value={(BOOST_TYPE.NORMAL_GAMES)}>
                                    Normal Games
                                </NavButton>
                            </NavContainer>
                        </div>
                        {view}
                    </div>
                </div>
                <Checkout key={boost_type} panel={BOOST_TYPE.URL_TO_BOOST_TYPE(boost_type)} setShowLogin={() => setShowLogin(true)} data={data}/>
                <SignIn open={showLogin} close={() => setShowLogin(false)}/>
            </div>
        </>
    );
}
