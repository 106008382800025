
import React, { useEffect } from 'react';
import './CSS/Hero.css';
import { useNavigate } from 'react-router-dom';

export default function Hero({backgroundImg}) {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div className='hero' style={{backgroundImage: `url(${backgroundImg})`}}>
            <div className='hero-content'>
                <div className='hero-content-overlay'>
                    <h1>
                        <span className="main-title">LEAGUE OF LEGENDS</span>
                        <br/>
                        <span className="sub-title">BOOSTING</span>
                    </h1>
                    <br/>
                    <p>
                        Elevate your League of Legends journey with our premium boosting services, guaranteed to help you reach new heights in rank.
                    </p>
                </div>
                <div className='hero-content-buttons'>
                    <button className='hero-button' onClick={() => navigate('get-boosting/division-boosting')}>Get Boosting</button>
                    <button className='hero-button' onClick={() => navigate('get-coaching/mixed')}>Get Coaching</button>
                </div>
            </div>
        </div>
    );
}