
import { useEffect, useState } from "react";

import axios from 'axios';
import { useAuth } from "../../../contexts/AuthContext";

import { useNavigate, useParams } from "react-router-dom";

import { ORDER_STATUS, BOOST_TYPE } from '../../../constants/database_values';
import { booster } from '../../../components/tables';
import Table from '../../../components/Table';
import Loading from "../../../components/Loading";

import PendingDivisionBoost from './BoostDetails/PendingDivisionBoost';
import ActiveDivisionBoost from './BoostDetails/ActiveDivisionBoost';
import PendingPlacementBoost from "./BoostDetails/PendingPlacementBoost";
import PendingNetWinBoost from "./BoostDetails/PendingNetWinBoost";
import PendingNormalBoost from "./BoostDetails/PendingNormalBoost";
import ActivePlacementBoost from "./BoostDetails/ActivePlacementBoost";
import ActiveNetWinBoost from "./BoostDetails/ActiveNetWinBoost";
import ActiveNormalBoost from "./BoostDetails/ActiveNormalBoost";


export default function Status() {

    const navigate = useNavigate();
    const { panel, status, boost_type, order_id } = useParams();
    const [orders, setOrders] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState(null);

    useEffect(() => {
        if(!status) return;
        if(!ORDER_STATUS.IS_VALID(status)) return;
        setTable(booster.boosting[status]);
    }, [status]);

    const { getAccessToken } = useAuth();

    useEffect(() => {
        if (order_id || boost_type || !status || page === undefined || panel !== 'boosting') return;
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/boost/boost-orders', {
            headers: { 
                Authorization: getAccessToken() 
            },
            params: {
                order_status: status,
                page: page
            }
        }).then(response => {
            setOrders(response.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [page]);

    useEffect(() => {
        if (!selected) return;
        if(status === ORDER_STATUS.COMPLETED) return;
        switch(selected.boost_type) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                navigate(`/boost-panel/boosting/${status}/division-boosting/${selected.order_id}`)
                break;
            case BOOST_TYPE.PLACEMENT_GAMES:
                navigate(`/boost-panel/boosting/${status}/placement-games/${selected.order_id}`)
                break;
            case BOOST_TYPE.NET_WINS:
                navigate(`/boost-panel/boosting/${status}/net-wins/${selected.order_id}`)
                break;
            case BOOST_TYPE.NORMAL_GAMES:
                navigate(`/boost-panel/boosting/${status}/normal-games/${selected.order_id}`)
                break;
            default:
                break;
        }
    }, [selected]);

    useEffect(() => {
        if (!boost_type && !order_id) return;
        setLoading(false);
        switch(boost_type) {
            case 'division-boosting':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingDivisionBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveDivisionBoost />);
                break;
            case 'placement-games':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingPlacementBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActivePlacementBoost />);
                break;
            case 'net-wins':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingNetWinBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveNetWinBoost />);
                break;
            case 'normal-games':
                if(status === ORDER_STATUS.PENDING)
                    setSelectedView(<PendingNormalBoost />);
                else if(status === ORDER_STATUS.ACTIVE)
                    setSelectedView(<ActiveNormalBoost />);
                break;
            default:
                break;
        }
    }, [boost_type, order_id, status]);

    return (
        <>
            {!selectedView&&table&&<Table header={table.header} details={table.details} data={orders} onClick={(object) => setSelected(object)} page={page} setPage={setPage}/>}
            {selectedView&&selectedView}
            <Loading loading={loading} panel={true}/>
        </>
    );
}