
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";
import logo from '../../../assets/Logos/Logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faXmark } from '@fortawesome/free-solid-svg-icons'
import InputField from "../../../components/InputField.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function ResetPassword({closeModal, changeModal}) {
    
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailConf, setEmailConf] = useState("");
    const [modalChange, setModalChange] = useState("reset");

    const { resetPassword } = useAuth();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login')
        }
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        if(modalChange !== "reset") {
            return;
        }
        try {
            setLoading(true);
            if(email.length === 0 || emailConf.length === 0){
                MySwal.fire({
                    icon: 'warning',
                    title: 'Warning!',
                    text: "No email provided.",
                })
                setLoading(false);
                return;
            }
            if(email !== emailConf){
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: "Emails don't match.",
                })
                setLoading(false);
                return;
            }
            let error = await resetPassword(email);
            if(error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                })
                setLoading(false);
                return;
            }
            MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: "Check your email for further instructions.",
            })
            setLoading(false);
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to reset password.",
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(modalChange !== "reset") {
            changeModal(modalChange);
        }
    }, [modalChange]);

    return (
        <>
            <form className="signup-form" onSubmit={(e) => handleSubmit(e)}>
                <FontAwesomeIcon icon={faXmark} className="exit-icon" onClick={() => closeModal()} />
                <img src={logo} alt="logo" className="logo-img" />
                <div className="signup-inputs">
                    <InputField type="email" placeholder="email" icon={faEnvelope} onChange={setEmail}  required />
                    <InputField type="email" placeholder="confirm email" icon={faEnvelope} onChange={setEmailConf}  required />
                </div>
                <div className="signup-buttons">
                    <button className="standard-btn" disabled={loading} type="submit">Reset Password</button>
                </div>
                <div className="signup-footer">
                    <button onClick={() => setModalChange('login')} className="link-hover">Log In</button>
                    <button onClick={() => setModalChange('signup')} className="link-hover">Create Account</button>
                </div>
            </form>
        </>
    );
}
