
import React, { useState } from 'react';

import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../contexts/AuthContext.js';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Loading from '../../../components/Loading.js';
import DropDown from '../../../components/DropDown.js';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function CouponGenerate() {

    const { getAccessToken } = useAuth();
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)

    const [loading, setLoading] = useState(false);
    
    const [isSingleUse, setIsSingleUse] = useState('No');
    const [isActive, setIsActive] = useState('Yes');
    const [discount, setDiscount] = useState(0);
    const [expiresAt, setExpiresAt] = useState(null);
    const [name, setName] = useState('');

    const handleGenerate = async function() { 
        setLoading(true);
        if(!name) {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Name is required.',
            });
            setLoading(false);
            return;
        }
        if(!discount) {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Discount is required.',
            });
            setLoading(false);
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/admin/generate-coupon', {
            is_single_use: isSingleUse === 'Yes' ? true : false,
            is_active: isActive === 'Yes' ? true : false,
            discount: discount,
            expires_at: expiresAt,
            name: name
        }, {
            headers: {
                Authorization: getAccessToken()
            },
        }).then(response => {
            setLoading(false);
            MySwal.fire({
                icon: 'success',
                title: 'Coupon Saved!',
                text: 'Coupon has been created.',
            }).then(() => {
                navigate(`/admin-panel/coupons/all`);
                window.location.reload();
            });
        }).catch(error => {
            console.error(error);
            setLoading(false);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Unexpected error occured.',
            })
        });
    }
    
    if(loading) return(
        <>
            <div className='account-information'/>
            <Loading loading={loading} panel={true}/>
        </>
    );

    return(
        <>
            <Loading loading={loading} panel={true}/>
            <div className='account-information'>
                <div className='header'>
                    <div className='container'>
                        <div className='card'>
                            <h1>Coupon Name</h1>
                            <input type='text' value={name} placeholder='Name' onChange={(e) => setName(e.target.value)}/>
                        </div>
                    </div>
                </div>
                <div className='body'>
                    <div className='container'>
                        <div className='card'>
                            <h2>Expires At [{expiresAt?.substring(0,10)??'Never'}]</h2>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker onChange={(e) => {setExpiresAt(e.format())}}/>
                            </LocalizationProvider>
                        </div>
                        <div className='card'>
                            <h2>Discount</h2>
                            <input type='number' value={discount??0} placeholder='Discount' onChange={(e) => setDiscount(e.target.value)} max={99}/>
                        </div>
                        <div className='card'>
                            <h2>Is Active</h2>
                            <DropDown elements={isActive ? ['Yes', 'No'] : ['No', 'Yes']} onChange={setIsActive} defaultValue={isActive}/>
                        </div>
                        <div className='card'>
                            <h2>Is Single Use</h2>
                            <DropDown elements={isSingleUse ? ['Yes', 'No'] : ['No', 'Yes']} onChange={setIsSingleUse} defaultValue={isSingleUse}/>
                        </div>
                    </div>
                    <div className='container'>
                        <button className='big-btn' onClick={() => {navigate(`/admin-panel/coupons/all`)}}>Back</button>
                        <button className='big-btn' onClick={() => handleGenerate()}>Generate</button>
                    </div>
                </div>
            </div>
        </>
    );
}