
import React from 'react'
import DropDown from '../../../components/DropDown';

import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

export default function Search({placeholder, value, options, type}) {


        const { panel } = useParams();
        const [focus, setFocus] = React.useState(false);
        const [currentValue, setCurrentValue] = React.useState(value ? value : '');
        const [currentOption, setCurrentOption] = React.useState(options ? options[0] : '');
        const [submit, setSubmit] = React.useState(false);

        const navigate = useNavigate();
    
        React.useEffect(() => {
            if(value && value !== currentValue){
                setCurrentValue(value);
            }
        }, [value]);
    
        React.useEffect(() => {
            if(!submit) return;
            if(currentOption === 'Order ID') {
                navigate(`/admin-panel/${panel}/search/${currentValue}`);
                setSubmit(false);
            } else if(currentOption === 'Customer') {
                navigate(`/admin-panel/users/search/${currentValue}`);
                setSubmit(false);
            } else if(currentOption === 'Coupon') {
                navigate(`/admin-panel/coupons/search/${currentValue}`);
                setSubmit(false);
            }
        }, [submit]);
    
        return(
            <div className={`search-field${focus ? ' focus' : ''}`}>
                <DropDown elements={options} onChange={(value) => setCurrentOption(value)} defaultValue={currentOption}/>
                <form onSubmit={(e) => {e.preventDefault(); setSubmit(true);}}>
                    <input 
                        type={type} 
                        placeholder={placeholder} 
                        onChange={(value) => setCurrentValue(value.target.value)} 
                        value={currentValue} 
                        onFocus={() => setFocus(true)} 
                        onBlur={() => setFocus(false)}
                    />
                </form>
                <button id={'search-field-button'} onClick={() => setSubmit(true)}>Search</button>
            </div>
        );  
}