import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext.js";
import logo from '../../../assets/Logos/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faKey, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faGoogle } from '@fortawesome/free-brands-svg-icons'
import InputField from "../../../components/InputField.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function Login({closeModal, changeModal}) {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [modalChange, setModalChange] = useState("login");

    const { login, googleLogin, discordLogin } = useAuth();
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login')
        }
    }, [])

    async function handleSubmit(e) {
        e.preventDefault();
        if(modalChange !== "login") {
            return;
        }
        try {
            setLoading(true);
            let error = await login(email, password)
            console.log(error)
            setLoading(false);
            if(error) {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                })
            } else {
                window.location.reload();
            }
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to log in.",
            })
            setLoading(false);
        }
    }

    function handleGoogleLogin() {
        try {
            setLoading(true);
            googleLogin();
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to log in.",
            })
            setLoading(false);
        }
    }

    function handleDiscordLogin() {
        try {
            setLoading(true);
            discordLogin();
        } catch (error) {
            console.log(error);
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: "Failed to log in.",
            })
            setLoading(false);
        }
    }

    useEffect(() => {
        if(modalChange !== "login") {
            changeModal(modalChange);
        }
    }, [modalChange]);

    return (
        <>
            <form className="signup-form" onSubmit={(e) => handleSubmit(e)}>
                <FontAwesomeIcon icon={faXmark} className="exit-icon" onClick={() => closeModal()}/>
                <img src={logo} alt="logo" className="logo-img" />
                <div className="signup-inputs">
                    <InputField type="email" placeholder="email" icon={faEnvelope} onChange={setEmail}  required />
                    <InputField type="password" placeholder="password" icon={faKey} onChange={setPassword}  required />
                </div>
                <div className="signup-buttons">
                    <button className="standard-btn" disabled={loading} type="submit">Login</button>
                    <div className="auth-login">
                        <div className="auth-provider" onClick={() => handleGoogleLogin()}>
                            <FontAwesomeIcon icon={faGoogle}/>
                        </div>
                        <div className="auth-provider" onClick={() => handleDiscordLogin()}>
                            <FontAwesomeIcon icon={faDiscord}/>
                        </div>
                    </div>
                </div>
                <div className="signup-footer">
                    <button onClick={() => setModalChange('signup')} className="link-hover">Create Account</button>
                    <button onClick={() => setModalChange('reset')} className="link-hover">Reset Password</button>
                </div>
            </form>
        </>
    );
}