
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage, faRightToBracket, faRocket, faBook } from '@fortawesome/free-solid-svg-icons'

import Messages from './OrdersPanel/Messages.js';
import Boosting from './OrdersPanel/Boosting.js';
import Coaching from './OrdersPanel/Coaching.js';

import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import './CSS/Panel.css';

export default function OrderPanel() {

    const helmetTags = require('../constants/helmet-tags.json');

    const { panel } = useParams(); 
    const [view, setView] = useState(<Boosting/>);

    const navigate = useNavigate();
 
    useEffect(() => {
        window.scrollTo(0, 0);
        document.getElementById('navbar').classList.add('panel-main-navbar');
        const handleNotification = () => {
            console.log('notification');
        }
        document.addEventListener('notification', handleNotification);
        return () => {
            document.getElementById('navbar').classList.remove('panel-main-navbar');
            document.removeEventListener('notification', handleNotification);
        }
    }, []);

    useEffect(() => {
        if(!panel) return;
        switch(panel) {
            case "boosting":
                setView(<Boosting/>);
                break;
            case "coaching":
                setView(<Coaching/>);
                break;
            case "messages":
                setView(<Messages/>);
                break;
            case "home":
                navigate('/');
                break;
            }
    }, [panel]);

    return(
        <div className='panel'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.orders_panel.title}</title>
                <meta name="description" content={helmetTags.orders_panel.description}/>
                <meta name='robots' content="noindex, nofollow"/>
                <link rel="canonical" href={helmetTags.orders_panel.link}/>
            </Helmet>
            <div className='panel-navbar'>
                <div onClick={() => navigate('/my-orders/boosting/active')} className={`panel-navbar-icon-text${panel === 'boosting' ? '-active' : ''}`}>
                    <FontAwesomeIcon icon={faRocket} className='panel-navbar-icon'/>
                    <p className='panel-navbar-text'>Boosting</p>
                </div>
                <div onClick={() => navigate('/my-orders/coaching/active')} className={`panel-navbar-icon-text${panel === 'coaching' ? '-active' : ''}`}>
                    <FontAwesomeIcon icon={faBook} className='panel-navbar-icon'/>
                    <p className='panel-navbar-text'>Coaching</p>
                </div>
                <div onClick={() => navigate('/my-orders/messages')} className={`panel-navbar-icon-text${panel === 'messages' ? '-active' : ''}${' unread'}`}>
                    <FontAwesomeIcon icon={faMessage} className='panel-navbar-icon'/>
                    <p className='panel-navbar-text'>Messages</p>
                </div>
                <div onClick={() => navigate('/')} className={`panel-navbar-icon-text${panel === 'home' ? '-active' : ''}`}>
                    <FontAwesomeIcon icon={faRightToBracket} className='panel-navbar-icon'/>
                    <p className='panel-navbar-text'>Home</p>
                </div>
            </div>
            <div className='panel-container'>
                {view}
            </div>
        </div>
    );
}