
// Import dependencies
import React, {useEffect, useState} from 'react';

export default function Table({header, details, data, onClick, page, setPage, select, selected}) {

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
    
    const orderToggle = (order) => {
        if(selectedOrdersIds.includes(order.order_id)) {
            setSelectedOrdersIds(selectedOrdersIds.filter((id) => id !== order.order_id));
            setSelectedOrders(selectedOrders.filter((o) => o.order_id !== order.order_id));
        } else {
            setSelectedOrdersIds([...selectedOrdersIds, order.order_id]);
            setSelectedOrders([...selectedOrders, order]);
        }
    }

    useEffect(() => {
        if(select) {
            selected(selectedOrders);
        }
    }, [selectedOrders]);

    useEffect(() => {
        if(select) {
            setSelectedOrders([]);
            setSelectedOrdersIds([]);
        }
    }, [data]);

    return(
        <div className='table-container'>
            <table className='table'>
                <thead>
                    <tr className='table-container'>
                        {header && header.map((element) => {
                        return (<th key={element} className='table-header-element'>
                            <p>{element}</p>
                        </th>);
                        })}
                    </tr>  
                </thead>
                <tbody className='table-body'>
                    {header&&details&&data&&data.length > 0 && data.map((object, index) => {
                        return(<Row key={index} object={object} details={details} viewObject={(object) => select ? (orderToggle(object)) : (onClick ? onClick(object) : null)} select={select&&selectedOrders.includes(object)}/>);
                    })}
                </tbody>
            </table>
            <PageSelector page={page??0} setPage={setPage}/>
        </div>
    );
}

function Row({object, details, viewObject, select}) {
    return(
        <tr onClick={() => viewObject(object)} className={`table-container${select  === true ? ' selected' : ''}`}>
            {details(object) && details(object).map((element, index) => {
                return <td key={index} className='table-element'>
                    <p>{element}</p>
                </td>
            })}
        </tr>  
    );
}

function PageSelector({page, setPage}) {

    const [pageInput, setPageInput] = useState(page);
   
    const handleBack = () => {
        if(page - 1 < 1) return;
        setPage(page - 1);
        setPageInput(page - 1);
    }

    const handleNext = () => {
        setPage(page + 1);
        setPageInput(page + 1);
    }

    return(
        <div className='page-selector'>
            <button onClick={handleBack} className='page-selector-button'>Back</button>
            <div className='page-selector-text'>
                <input type='number' className='page-selector-input' value={pageInput} onChange={(e) => setPageInput(e.target.value)}/>
                <button onClick={() => setPage(pageInput)} className='page-selector-button'>Go</button>
            </div>
            <button onClick={handleNext} className='page-selector-button'>Next</button>
        </div>
    );
}