
import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS } from '../../constants/database_values.js';
import { EMBLEMS } from '../../constants/colors.js';
import { TIMEOUT } from '../../constants/misc.js';

import DropDown from '../../components/DropDown.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';

import { Helmet } from 'react-helmet';

export default function PlacementBoost({setData}) {

    const helmetTags = require('../../constants/helmet-tags.json');
 
    const ranks = ['Unranked', 'Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond', 'Master'];
    const servers = ['NA', 'LAN'];

    const previousData = JSON.parse(localStorage.getItem('placement-games')) || {};

    const currency = localStorage.getItem('currency') || 'USD';

    const [rank, setRank] = useState(previousData.rank || 'Platinum');
    const [queueType, setQueueType] = useState(previousData.queueType || 'Solo/Duo');
    const [server, setServer] = useState((previousData.server && servers.includes(previousData.server)) ? previousData.server : 'NA');
    const [numGames, setNumGames] = useState(previousData.numGames || 1);
    const [previousNumGames, setPreviousNumGames] = useState(previousData.numGames || 1);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState(previousData.addons || []);
    const [champions, setChampions] = useState(previousData.champions || []);
    const [roles, setRoles] = useState(previousData.roles || []);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            rank,
            numGames,
            queueType,
            server,
            addons,
            champions,
            roles,
            price
        });
        localStorage.setItem('placement-games', JSON.stringify({
            rank,
            numGames,
            queueType,
            server,
            addons,
            champions,
            roles
        }));
    }, [rank, numGames, queueType, server, addons, champions, roles, price]);

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    useEffect(() => {
        if(rank === null || numGames === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-placement-boost', { 
            rank,
            numGames,
            addons,
            currency
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [rank, addons, currency]);

    useEffect(() => {
        if(rank === null || numGames === null){
            return;
        }
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-placement-boost', { 
                rank,
                numGames,
                addons,
                currency
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [numGames, currency]);

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetTags.placement_games.title}</title>
                <meta name="description" content={helmetTags.placement_games.description}/>
                <link rel="canonical" href={helmetTags.placement_games.link}/>
            </Helmet>
            <div className='placements container'>
                <div className={'rank-selector previous'} style={{border: `1px solid ${EMBLEMS.GET_COLOR(rank)}`, boxShadow: `0 0 15px ${EMBLEMS.GET_COLOR(rank)} inset`}}>
                    <div className='component-title'>
                        <h2>Previous Rank</h2>
                    </div>
                    <div className='component-wrapper'>
                        <img id='emblem' src={require(`../../assets/Emblems/${rank}_Emblem.png`)} alt={rank + ' Rank Icon'} height={240} width={240} title={'League of legends ' + rank + ' rank'}/>
                        <DropDown elements={ranks} onChange={setRank} defaultValue={rank}/>
                    </div>
                </div>
                <div className='division-boosting-selectors placements'>
                    <div className='division-boosting-selector'>
                        <div className='component-title'>
                            <h2>Queue Type</h2>
                        </div>
                        <div className='component-wrapper'>
                            <SelectorContainer setCurrentValue={setQueueType} currentValue={queueType}>
                                <SelectorButton value={'Solo/Duo'}>Solo/Duo</SelectorButton>
                                <SelectorButton value={'Flex'}>Flex</SelectorButton>
                            </SelectorContainer>
                        </div>
                    </div>
                    <div className='division-boosting-selector'>
                        <div className='component-title'>
                            <h2>Region</h2>
                        </div>
                        <div className='component-wrapper'>
                            <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                                {servers.map((server) => {
                                    return(
                                        <SelectorButton key={server} value={server}>{server}</SelectorButton>
                                    );
                                })}
                            </SelectorContainer>
                        </div>
                    </div>
                </div>
            </div>
            <div className='placements-game-number'>
                <div className='component-title'>
                    <h2>{numGames} Placement Games</h2>
                </div>
                <div className='component-wrapper'>
                    <Slider 
                        defaultValue={previousNumGames} 
                        step={1} 
                        min={1} 
                        max={5} 
                        onChange={(event, newValue) => {
                            // You can handle the new value here
                            setNumGames(newValue);
                        }}
                    />
                </div>
            </div>
            <div className='division-boosting-addons'>
                <div className='component-title'>
                    <h2>Addons</h2>
                </div>
                <div className='component-wrapper'>
                    <div className='ordering-status-buttons'>
                        {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                            if(ADDONS[addon].title === 'Bonus Win'){
                                return;
                            }
                            return(
                                <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                            )}
                        )}
                    </div>
                </div>
            </div>
            {addons.includes('Specific Roles')&& <div className='division-boosting-addons' style={{alignSelf:'stretch'}}>
                <RoleSelector selectedRoles={setRoles} previousRoles={previousData.roles} noScroll={previousData.roles !== null && previousData.roles?.length > 0}/>
            </div>}
            {addons.includes('Specific Champions')&& <div className='division-boosting-addons'>
               <ChampionSelector selectedChampions={setChampions} previousChampions={previousData.champions} noScroll={previousData.champions !== null && previousData.champions?.length > 0}/>
            </div>}
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}