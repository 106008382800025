
import React from 'react'
import { NavContainer, NavButton } from '../../components/Navigation.js';

import Status from './Payments/Status.js';

import { useParams, useNavigate } from 'react-router-dom';

export default function Payments() {

    const navigate = useNavigate();
    const { status, boost_type, order_id } = useParams();
    const [view, setView] = React.useState(<></>);
    
    React.useEffect(() => {
        if(!status) {
            return;
        }
        setView(<Status key={Math.random()}/>);
    }, [status, order_id, boost_type]);

    return(
        <> 
            <NavContainer panel={status} onClick={(status) => navigate(`/admin-panel/payments/${status}`)}>
                <NavButton value='pending'>Pending</NavButton>
                <NavButton value='completed'>Completed</NavButton>
            </NavContainer>   
            {view}
        </>
    );
}