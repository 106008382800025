import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../contexts/AuthContext.js';

export default function CoachSelector({ order_id, close, setLoading }) {

    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState(null);
    const [previous, setPrevious] = useState(null); 
    const [filteredCoaches, setFilteredCoaches] = useState([]);
    const [coachesData, setCoachesData] = useState([]);

    const { getAccessToken } = useAuth();


    useEffect(() => {
        document.getElementById('popup-root').classList.add('popup-login')
        setLoading(true);
        axios.get(process.env.REACT_APP_API_URL + '/admin/coach-list', {
            headers: {
                Authorization: getAccessToken()
            }
        }).then(response => {
            setCoachesData(response.data);
            setFilteredCoaches(response.data);
            axios.get(process.env.REACT_APP_API_URL + '/admin/current-coach/' + order_id, {
                headers: {
                    Authorization: getAccessToken()
                }
            }).then(response => {
                setSelected(response.data);
                setPrevious(response.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                console.error(error);
            });
        }).catch(error => {
            setLoading(false);
            console.error(error);
        });
        return () => {
            document.getElementById('popup-root').classList.remove('popup-login'); 
        }
    }, [])

    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = coachesData.filter((coach) =>
            coach.username.toLowerCase().includes(term)
        );
        setFilteredCoaches(filtered);
    };

    function save() {
        setLoading(true);
        if(selected === previous){
            setLoading(false);
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/admin/change-coach', {
            order_id: order_id,
            coach_id: selected.user_id
        }, {
            headers: {
                Authorization: getAccessToken()
            }
        }).then(response => {
            setLoading(false);
            window.location.reload();
        }).catch(error => {
            setLoading(false);
            console.error(error);
        });
    }

    return (
        <div className='champions-selector-container'>
            <h2>Change Coach</h2>
            <div className='champions-search'>
                <input
                    type='text'
                    placeholder='Search for a coach...'
                    value={searchTerm}
                    onChange={handleSearch}
                />
                <button>Search</button>
            </div>
            <div className='champion-selector'>
                {filteredCoaches.map((coach) => {
                    return (
                        <p
                            key={coach.user_id}
                            className={`${selected?.username === coach.username ? 'selected' : ''}`}
                            onClick={() => setSelected(coach)}
                        >
                            {coach.username}
                        </p>
                    );
                })}
                {filteredCoaches.length === 0 && <h3>No coaches found</h3>}
            </div>
            <div className='rank-updater-btns'>
                <button className='rank-updater-cancel' onClick={() => {close()}}>Cancel</button>
                <button className='rank-updater-update' onClick={() => {save()}}>Save</button>
            </div>
        </div>
    );
}
