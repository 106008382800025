
const { faUser, faUsers, faUserGroup, faUserClock, faShieldHalved, faWifi, faLocationCrosshairs, faUserShield, faEyeSlash } = require('@fortawesome/free-solid-svg-icons');

const BOOST_TYPE = {
    DIVISION_BOOSTING: 'division_boosting',
    PLACEMENT_GAMES: 'placement_games',
    NET_WINS: 'net_wins',
    NORMAL_GAMES: 'normal_games',
    IS_VALID: (boost_type) => {
        return Object.values(BOOST_TYPE).includes(boost_type);
    },
    CONVERT_TO_URL: (boost_type) => {
        switch(boost_type) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                return 'division-boosting';
            case BOOST_TYPE.PLACEMENT_GAMES:
                return 'placement-games';
            case BOOST_TYPE.NET_WINS:
                return 'net-wins';
            case BOOST_TYPE.NORMAL_GAMES:
                return 'normal-games';
            default:
                return '';
        }
    },
    URL_TO_BOOST_TYPE: (url) => {
        switch(url) {
            case 'division-boosting':
                return BOOST_TYPE.DIVISION_BOOSTING;
            case 'placement-games':
                return BOOST_TYPE.PLACEMENT_GAMES;
            case 'net-wins':
                return BOOST_TYPE.NET_WINS;
            case 'normal-games':
                return BOOST_TYPE.NORMAL_GAMES;
            default:
                return '';
        }
    },
    VERIFY_BOOST_TYPE: (boost_type) => {
        switch(boost_type) {
            case 'division-boosting':
                return 'division-boosting';
            case 'placement-games':
                return 'placement-games';
            case 'net-wins':
                return 'net-wins';
            case 'normal-games':
                return 'normal-games';
            default:
                return '';
        }
    },
    CONVERT: (boost_type) => {
        switch(boost_type) {
            case BOOST_TYPE.DIVISION_BOOSTING:
                return 'Division Boosting';
            case BOOST_TYPE.PLACEMENT_GAMES:
                return 'Placement Games';
            case BOOST_TYPE.NET_WINS:
                return 'Net Wins';
            case BOOST_TYPE.NORMAL_GAMES:
                return 'Normal Games';
        }
    }
};


const COACH_TYPE = {
    COPILOT: 'copilot',
    VOD: 'vod-review',
    VERSUS: 'versus',
    MIXED: 'mixed',
    IS_VALID: (coach_type) => {
        return Object.values(COACH_TYPE).includes(coach_type);
    },
    CONVERT_TO_URL: (coach_type) => {
        switch(coach_type) {
            case COACH_TYPE.COPILOT:
                return 'copilot';
            case COACH_TYPE.VOD:
                return 'vod-review';
            case COACH_TYPE.VERSUS:
                return 'versus';
            case COACH_TYPE.MIXED:
                return 'mixed';
            default:
                return '';
        }
    },
    URL_TO_COACH_TYPE: (url) => {
        switch(url) {
            case 'copilot':
                return COACH_TYPE.COPILOT;
            case 'vod-review':
                return COACH_TYPE.VOD;
            case 'versus':
                return COACH_TYPE.VERSUS;
            case 'mixed':
                return COACH_TYPE.MIXED;
            default:
                return '';
        }
    },
    VERIFY_COACH_TYPE: (coach_type) => {
        switch(coach_type) {
            case 'copilot':
                return 'copilot';
            case 'vod-review':
                return 'vod-review';
            case 'versus':
                return 'versus';
            case 'mixed':
                return 'mixed';
            default:
                return '';
        }
    },
    CONVERT: (coach_type) => {
        switch(coach_type) {
            case COACH_TYPE.COPILOT:
                return 'Copilot';
            case COACH_TYPE.VOD:
                return 'Vod Review';
            case COACH_TYPE.VERSUS:
                return 'Versus';
            case COACH_TYPE.MIXED:
                return 'Mixed';
        }
    }
};


const ORDER_STATUS = {
    PENDING: 'pending',
    ACTIVE: 'active',
    COMPLETED: 'completed',
    VERIFY: 'verify',
    CANCELLED: 'cancelled',
    IS_VALID: (order_status) => {
        return Object.values(ORDER_STATUS).includes(order_status);
    }
};

const ADDONS = {
    DUO: {
        title: 'Duo',
        label: '(+60%)',
        percent: 0.6,
        icon: faUserGroup
    },
    VPN: {
        title: 'VPN',
        label: 'FREE',
        percent: 0,
        icon: faShieldHalved
    },
    PRIORITY: {
        title: 'Priority',
        label: '(+15%)',
        percent: 0.15,
        icon: faUserClock
    },
    STREAMING: {
        title: 'Streaming',
        label: '(+15%)',
        percent: 0.15,
        icon: faWifi
    },
    SOLO_ONLY: {
        title: 'Solo Only',
        label: '(+25%)',
        percent: 0.25,
        icon: faUser
    },
    SPECIFIC_ROLES: {
        title: 'Specific Roles',
        label: '(+15%)',
        percent: 0.15,
        icon: faLocationCrosshairs
    },
    APPEAR_OFFLINE: {
        title: 'Appear Offline',
        label: 'FREE',
        percent: 0,
        icon: faEyeSlash
    },
    BONUS_WIN: {
        title: 'Bonus Win',
        label: 'RANK BASED',
        percent: 0,
        icon: faUserShield
    },
    SPECIFIC_CHAMPIONS: {
        title: 'Specific Champions',
        label: '(+20%)',
        percent: 0.2,
        icon: faUsers
    },
    IS_VALID: (addon) => {
        return Object.values(ADDONS).includes(addon);
    },
    GET_LABEL: (title) => {
        switch(title) {
            case ADDONS.DUO.title:
                return ADDONS.DUO.label;
            case ADDONS.VPN.title:
                return ADDONS.VPN.label;
            case ADDONS.PRIORITY.title:
                return ADDONS.PRIORITY.label;
            case ADDONS.STREAMING.title:
                return ADDONS.STREAMING.label;
            case ADDONS.SOLO_ONLY.title:
                return ADDONS.SOLO_ONLY.label;
            case ADDONS.SPECIFIC_ROLES.title:
                return ADDONS.SPECIFIC_ROLES.label;
            case ADDONS.APPEAR_OFFLINE.title:
                return ADDONS.APPEAR_OFFLINE.label;
            case ADDONS.BONUS_WIN.title:
                return ADDONS.BONUS_WIN.label;
            case ADDONS.SPECIFIC_CHAMPIONS.title:
                return ADDONS.SPECIFIC_CHAMPIONS.label;
            default:
                return 'Error';
        }
    }, 
    GET_PERCENT: (title) => {
        switch(title) {
            case ADDONS.DUO.title:
                return ADDONS.DUO.percent;
            case ADDONS.VPN.title:
                return ADDONS.VPN.percent;
            case ADDONS.PRIORITY.title:
                return ADDONS.PRIORITY.percent;
            case ADDONS.STREAMING.title:
                return ADDONS.STREAMING.percent;
            case ADDONS.SOLO_ONLY.title:
                return ADDONS.SOLO_ONLY.percent;
            case ADDONS.SPECIFIC_ROLES.title:
                return ADDONS.SPECIFIC_ROLES.percent;
            case ADDONS.APPEAR_OFFLINE.title:
                return ADDONS.APPEAR_OFFLINE.percent;
            case ADDONS.BONUS_WIN.title:
                return ADDONS.BONUS_WIN.percent;
            case ADDONS.SPECIFIC_CHAMPIONS.title:
                return ADDONS.SPECIFIC_CHAMPIONS.percent;
            default:
                return 0;
        }
    }
};

const LP_GAINS = {
    LOW: {
        VALUE: 10, 
        BUTTON_DISPLAY: '10-22',
        DISPLAY: '10-22 LP'
    },
    MEDIUM: {
        VALUE: 23, 
        BUTTON_DISPLAY: '23-29',
        DISPLAY: '23-29 LP'
    },
    HIGH: {
        VALUE: 30, 
        BUTTON_DISPLAY: '30+',
        DISPLAY: '30+ LP'
    },
    DISPLAY: (e) => {
        if(e === null) return LP_GAINS.LOW.DISPLAY;
        if(e === undefined) return LP_GAINS.LOW.DISPLAY;
        if(typeof e !== 'number') return LP_GAINS.LOW.DISPLAY;
        if(e < LP_GAINS.MEDIUM.VALUE) return LP_GAINS.LOW.DISPLAY;
        if(e < LP_GAINS.HIGH.VALUE) return LP_GAINS.MEDIUM.DISPLAY;
        if(e >= LP_GAINS.HIGH.VALUE) return LP_GAINS.HIGH.DISPLAY;
        return LP_GAINS.LOW.DISPLAY;
    }
};

const LP = {
    LOW: {
        VALUE: 0, 
        BUTTON_DISPLAY: '0-33',
        DISPLAY: '0-33 LP'
    },
    MEDIUM: {
        VALUE: 34, 
        BUTTON_DISPLAY: '34-66',
        DISPLAY: '34-66 LP'
    },
    HIGH: {
        VALUE: 67, 
        BUTTON_DISPLAY: '67-100',
        DISPLAY: '67-100 LP'
    },
    DISPLAY: (e) => {
        if(e === null) return LP.LOW.DISPLAY;
        if(e === undefined) return LP.LOW.DISPLAY;
        if(typeof e !== 'number') return LP.LOW.DISPLAY;
        if(e < LP.MEDIUM.VALUE) return LP.LOW.DISPLAY;
        if(e < LP.HIGH.VALUE) return LP.MEDIUM.DISPLAY;
        if(e >= LP.HIGH.VALUE) return LP.HIGH.DISPLAY;
        return LP.LOW.DISPLAY;
    }
};


module.exports = { BOOST_TYPE, ORDER_STATUS, ADDONS, LP_GAINS, LP, COACH_TYPE };