
import React, { useState, useEffect, useRef } from 'react'
import { TIMEOUT } from '../../../../constants/misc.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../../../components/GeneralContainers.js';

import Slider from '@mui/material/Slider';
import DropDown from '../../../../components/DropDown.js';

export default function Coaching({setData}) {

    const [orderLanguage, setOrderLanguage] = useState('English');
    const [coachRank, setCoachRank] = useState('Master');
    const [usersRank, setUsersRank] = useState('Gold');
    const [server, setServer] = useState('NA');
    const [copilotHours, setCopilotHours] = useState(0);
    const [vodHours, setVodHours] = useState(0);
    const [battleHours, setBattleHours] = useState(0);
    const [price, setPrice] = useState({});
    const [champions, setChampions] = useState([]);
    const [roles, setRoles] = useState([]);

    const timeout = useRef(null);

    useEffect(() => {
        setData({
            orderLanguage,
            coachRank,
            usersRank,
            copilotHours,
            vodHours,
            battleHours,
            server,
            champions,
            roles,
            price
        });
    }, [orderLanguage, coachRank, usersRank, copilotHours, vodHours, battleHours, server, champions, roles, price]);
    
    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-coaching', { 
            coachRank,
            copilotHours,
            vodHours,
            battleHours,
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [coachRank]);

    useEffect(() => {
        if(timeout.current){
            clearTimeout(timeout.current);
        }
        setPrice(0);
        timeout.current = setTimeout(() => {
            axios.post(process.env.REACT_APP_API_URL + '/price-calculation-coaching', { 
                coachRank,
                copilotHours,
                vodHours,
                battleHours,
            }).then((response) => {
                setPrice(response.data);
            }).catch((error) => {
                setPrice(0);
                console.log(error);
            });
        }, TIMEOUT.slider);
    }, [vodHours, copilotHours, battleHours]);

    return(
        <>
            <div className='coaching container'>
                <div className='coaching-selectors'>
                    <div className='coaching-selector'>
                        <h2>Coach Rank</h2>
                        <img id='emblem' src={require(`../../../../assets/Emblems/${coachRank}_Emblem.png`)}/>
                        <SelectorContainer setCurrentValue={setCoachRank} currentValue={coachRank}>
                            <SelectorButton value={'Master'}>Master+</SelectorButton>
                            <SelectorButton value={'Grandmaster'}>Grandmaster+</SelectorButton>
                            <SelectorButton value={'Challenger'}>Challenger</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='coaching-selector'>
                        <div style={{textAlign:'center'}}>
                            <h2>Your Rank</h2>
                            <p>optional</p>
                        </div>
                        <img id='emblem' src={require(`../../../../assets/Emblems/${usersRank}_Emblem.png`)}/>
                        <DropDown elements={['Unranked', 'Iron', 'Bronze', 'Silver', 'Gold', 'Platinum', 'Emerald', 'Diamond', 'Master', 'Grandmaster', 'Challenger']} onChange={setUsersRank} defaultValue={usersRank}/>
                    </div>
                    <div className='coaching-selector'>
                        <h2>Region</h2>
                        <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                            <SelectorButton value={'BR'}>BR</SelectorButton>
                            <SelectorButton value={'EUNE'}>EUNE</SelectorButton>
                            <SelectorButton value={'EUW'}>EUW</SelectorButton>
                            <SelectorButton value={'LAN'}>LAN</SelectorButton>
                            <SelectorButton value={'LAS'}>LAS</SelectorButton>
                            <SelectorButton value={'NA'}>NA</SelectorButton>
                            <SelectorButton value={'OCE'}>OCE</SelectorButton>
                            <SelectorButton value={'RU'}>RU</SelectorButton>
                            <SelectorButton value={'TR'}>TR</SelectorButton>
                            <SelectorButton value={'JP'}>JP</SelectorButton>
                            <SelectorButton value={'KR'}>KR</SelectorButton>
                            <SelectorButton value={'TH'}>TH</SelectorButton>
                            <SelectorButton value={'VN'}>VN</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='coaching-selector'>
                        <h2>{copilotHours} Co-Pilot Hour{copilotHours === 1 ? '' : 's'}</h2>
                        <Slider 
                            defaultValue={0} 
                            step={1} 
                            min={0} 
                            max={3} 
                            onChange={(event, newValue) => {
                                setCopilotHours(newValue);
                            }}
                        />
                    </div>
                    <div className='coaching-selector'>
                        <h2>{vodHours} Vod Review Hour{vodHours === 1 ? '' : 's'}</h2>
                        <Slider 
                            defaultValue={0} 
                            step={1} 
                            min={0} 
                            max={3} 
                            onChange={(event, newValue) => {
                                setVodHours(newValue);
                            }}
                        />
                    </div>
                    <div className='coaching-selector'>
                        <h2>{battleHours} 1v1 Hour{battleHours === 1 ? '' : 's'}</h2>
                        <Slider 
                            defaultValue={0} 
                            step={1} 
                            min={0} 
                            max={3} 
                            onChange={(event, newValue) => {
                                setBattleHours(newValue);
                            }}
                        />
                    </div>
                    <div className='coaching-selector'>
                        <h2>Language</h2>
                        <SelectorContainer setCurrentValue={setOrderLanguage} currentValue={orderLanguage}>
                            <SelectorButton value={'English'}>English</SelectorButton>
                            <SelectorButton value={'Spanish'}>Spanish</SelectorButton>
                        </SelectorContainer>
                    </div>
                    <div className='coaching-selector'>
                        <RoleSelector selectedRoles={setRoles} noScroll={true}/>
                    </div>
                    <div className='coaching-selector'>
                        <ChampionSelector selectedChampions={setChampions} noScroll={true}/>
                    </div>
                </div>
            </div>
        </>
    );
}