
import React, { useState, useEffect } from 'react'

import DesiredSelector from './DivisionBoost/DesiredSelector.js';
import CurrentSelector from './DivisionBoost/CurrentSelector.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDONS } from '../../../../constants/database_values.js';

import axios from 'axios';
import { SelectorButton, SelectorContainer } from '../../../../components/Selector.js';
import { ChampionSelector, RoleSelector } from '../../../../components/GeneralContainers.js';

export default function DivisionBoost({setData}) {

    const [rank, setRank] = useState(null);
    const [division, setDivision] = useState(null);
    const [LP, setLP] = useState(null);
    const [queueType, setQueueType] = useState('Solo/Duo');
    const [server, setServer] = useState('NA');
    const [desiredRank, setDesiredRank] = useState(null);
    const [desiredDivision, setDesiredDivision] = useState(null);
    const [LPGains, setLPGains] = useState(null);
    const [price, setPrice] = useState({});
    const [addons, setAddons] = useState([]);
    const [champions, setChampions] = useState([]);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        setData({
            rank,
            division,
            LP,
            queueType,
            server,
            desiredRank,
            desiredDivision,
            LPGains,
            addons,
            champions,
            roles,
            price
        });
    }, [rank, division, LP, queueType, server, desiredRank, desiredDivision, LPGains, addons, champions, roles, price]);

    function updateCurrent({rank, division, LP}) {
        setRank(rank);
        setDivision(division);
        setLP(LP);
    }

    function updateDesired({rank, division, LPGains}) {
        setDesiredRank(rank);
        setDesiredDivision(division);
        setLPGains(LPGains);
    }

    function handleAddon(addon) {
        if(addons.includes(addon)){
            setAddons((current) => current.filter((element) => element !== addon));
            return;
        }
        if(addons.includes('Duo') && addon === 'Solo Only'){
            setAddons(addons.filter((element) => element !== 'Duo'));
        } else if(addons.includes('Solo Only') && addon === 'Duo'){
            setAddons(addons.filter((element) => element !== 'Solo Only'));
        }
        setAddons((current) => [...current, addon]);
    }

    useEffect(() => {
        if(rank === null || division === null || LP === null || desiredRank === null || desiredDivision === null || LPGains === null){
            return;
        }
        axios.post(process.env.REACT_APP_API_URL + '/price-calculation-division-boost', { 
            rank,
            division,
            LP,
            desiredRank,
            desiredDivision,
            LPGains,
            addons
        }).then((response) => {
            setPrice(response.data);
        }).catch((error) => {
            setPrice(0);
            console.log(error);
        });
    }, [rank, division, LP, desiredRank, desiredDivision, LPGains, addons]);

    return(
        <>
            <div className='division-boosting-ranks'>
                <CurrentSelector key={'Current'} update={updateCurrent} />
                <DesiredSelector key={'Desired'} update={updateDesired} minRank={rank} maxDivision={division} />
            </div>
            <div className='division-boosting-selectors'>
                <div className='division-boosting-selector'>
                    <h2>Queue Type</h2>
                    <SelectorContainer setCurrentValue={setQueueType} currentValue={queueType}>
                        <SelectorButton value={'Solo/Duo'}>Solo/Duo</SelectorButton>
                        <SelectorButton value={'Flex'}>Flex</SelectorButton>
                    </SelectorContainer>
                </div>
                <div className='division-boosting-selector'>
                    <h2>Region</h2>
                    <SelectorContainer setCurrentValue={setServer} currentValue={server}>
                        <SelectorButton value={'NA'}>NA</SelectorButton>
                        <SelectorButton value={'LAN'}>LAN</SelectorButton>
                    </SelectorContainer>
                </div>
            </div>
            <div className='division-boosting-addons'>
                <h2>Addons</h2>
                <div className='ordering-status-buttons'>
                    {(Object.keys(ADDONS).filter((key) => typeof ADDONS[key] === 'object')).map((addon) => {
                        return(
                            <Addon key={ADDONS[addon].title} title={ADDONS[addon].title} icon={ADDONS[addon].icon} label={ADDONS[addon].label} addons={addons} handleAddon={handleAddon}/>
                        )}
                    )}
                </div>
                {addons.includes('Specific Roles')&& <RoleSelector selectedRoles={setRoles}/>}
                {addons.includes('Specific Champions')&& <ChampionSelector selectedChampions={setChampions}/>}
            </div>
        </>
    );
}

function Addon({title, icon, label, addons, handleAddon}) {
    return(
        <div className='ordering-status-switch' onClick={() => {handleAddon(title)}}>
            <div className='ordering-status-icons-container'>
                <FontAwesomeIcon icon={icon} className='ordering-status-icon'/>
                <button>{title}</button>
                <p>{label}</p>
            </div>
            <div className='switch-container'>
                <div className={`checkout-addon${addons.includes(title) ? '-on' : '-off'}`}>{addons.includes(title) ? 'On' : 'Off'}</div>
            </div>
        </div>
    );
}